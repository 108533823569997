import { getUserRolFamily } from 'api/user/user.api'
import { QuestionView } from 'pages/questionView/questionView.component'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ContentHeader } from 'shared/components/contentHeader/contentHeader.component'
import useCheckToken from 'shared/hooks/useCheckToken.hook'

const roleFamilyData = {
  roleName: '',
  familyName: '',
}

export const UserRole = () => {
  const [t] = useTranslation(['threeSixty', 'global'])

  const [roleFamily, setRoleFamily] = useState(roleFamilyData)
  const { id, userName } = useCheckToken()

  useEffect(() => {
    if (id) {
      getUserRolFamily(id)
        .then(resp => {
          const { status, data } = resp

          if (status) {
            setRoleFamily({
              familyName: data.familyName,
              roleName: data.rolName,
            })
          }
        })
        .catch((error) => console.log('error', error))
    }
  }, [id])

  return (
    <>
      <ContentHeader
        title={t('role-family-user.header')}
        breadCrumb={{ home: t('global:sidebar.home'), page: t('global:sidebar.role-family-assignment') }}
      />

      <section className='content rounded-0'>
        <div className='container-fluid'>
          <div className='callout callout-primary'>
            <h5>{t('role-family-user.description-title')}</h5>
            <p>{t('role-family-user.description-body')}</p>
          </div>
        </div>
      </section>

      {roleFamily.familyName ? (
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-12'>
              <div className='card card-primary card-outline'>
                {/* Card Header */}
                <div className='card-header border-0'>
                  <div className='d-flex justify-content-between align-items-center'>
                    <h3 className='card-title'>{t('role-family-user.user-info.title')}</h3>
                  </div>
                </div>
                <div className='card-body'>
                  <div>
                    <p>{t('role-family-user.user-info.user-name')}: {userName}</p>
                    <p>{t('role-family-user.user-info.family')}: {roleFamily.familyName}</p>
                    <p>{t('role-family-user.user-info.role')}: {roleFamily.roleName}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <QuestionView userRole />
      )}
    </>
  )
}
