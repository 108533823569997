import * as Yup from 'yup'
import { t } from 'i18next'

const validationRegisterSchema = Yup.object().shape({
  username: Yup.string()
    .required(t('login:yup.username.required'))
    .min(4, t('login:yup.username.min'))
    .max(20, t('login:yup.username.max')),
  email: Yup.string()
    .required(t('login:yup.email.required'))
    .email(t('login:yup.email.email')),
  password: Yup.string()
    .required(t('login:yup.password.required'))
    .min(6, t('login:yup.password.min'))
    .matches(/[a-z]/, t('login:yup.password.lowercase'))
    .matches(/[A-Z]/, t('login:yup.password.uppercase'))
    .matches(/[0-9]/, t('login:yup.password.number'))
    .matches(/[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/, t('login:yup.password.special-char')),
  confirmPassword: Yup.string()
    .required(t('login:yup.confirm-password.required'))
    .oneOf([Yup.ref('password'), null], t('login:yup.confirm-password.not-match')),
  acceptTerms: Yup.bool().oneOf([true], t('login:yup.accept-terms')),
})

const validationRegisterGuestUserSchema = Yup.object().shape({
  userName: Yup.string()
    .required(t('login:yup.username.required'))
    .min(4, t('login:yup.username.min'))
    .max(20, t('login:yup.username.max')),
  password: Yup.string()
    .required(t('login:yup.password.required'))
    .min(6, t('login:yup.password.min'))
    .matches(/[a-z]/, t('login:yup.password.lowercase'))
    .matches(/[A-Z]/, t('login:yup.password.uppercase'))
    .matches(/[0-9]/, t('login:yup.password.number'))
    .matches(/[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/, t('login:yup.password.special-char')),
  confirmPassword: Yup.string()
    .required(t('login:yup.confirm-password.required'))
    .oneOf([Yup.ref('password'), null], t('login:yup.confirm-password.not-match')),
  acceptTerms: Yup.bool().oneOf([true], t('login:yup.accept-terms')),
})

const validationLoginSchema = Yup.object().shape({
  username: Yup.string()
    .required(t('login:yup.username.required'))
    .min(4, t('login:yup.username.min'))
    .max(20, t('login:yup.username.max')),
  password: Yup.string()
    .required(t('login:yup.password.required'))
    .min(6, t('login:yup.password.min'))
    .max(40, t('login:yup.password.max')),
})

export const validationForgorPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .required(t('login:yup.email.required'))
    .email(t('login:yup.email.email')),
})

export const recoveryPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .required(t('login:yup.password.required'))
    .min(6, t('login:yup.password.min'))
    .matches(/[a-z]/, t('login:yup.password.lowercase'))
    .matches(/[A-Z]/, t('login:yup.password.uppercase'))
    .matches(/[0-9]/, t('login:yup.password.number')),
    // .matches(/[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/, t('login:yup.password.special-char')),
  confirmPassword: Yup.string()
    .required(t('login:yup.confirm-password.required'))
    .oneOf([Yup.ref('password'), null], t('login:yup.confirm-password.not-match')),
})

export { validationRegisterSchema, validationLoginSchema, validationRegisterGuestUserSchema }
