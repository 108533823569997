import { Suspense } from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import CircularProgress from '@mui/material/CircularProgress'
import { ReactQueryDevtools } from 'react-query/devtools'
import { ToastContainer } from 'react-toastify'
import RoutesApp from './Routes'
import 'react-toastify/dist/ReactToastify.css'

// const StarterContent = lazy(() => import('pages/starterContent/starterContent.component'))
// const Letter = lazy(() => import('pages/letter/letter.component'))
const queryClient = new QueryClient()

const App = () => (
  <Suspense
    fallback={
      <div style={{ marginLeft: '50%' }}>
        <CircularProgress />
      </div>
    }
  >
    <ToastContainer />
    <QueryClientProvider client={queryClient}>
      <RoutesApp />
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </Suspense>
)

export default App
