import { ApiURL, services, subServices } from 'services/api.service'
import { PaginationRequest, ResponsePagination } from 'ts/interfaces/request.interfaces'
import { setPaginationToEndpoint } from 'shared/helpers/pagination'
import { useApply, useFetch, usePost, useUpdate } from 'api/reactQuery'
import { ThreeSixtyEditForm } from 'ts/interfaces/app.interfaces'
import { ResetRole, Role, UserRole } from 'api/role/role.interface'
import { api } from '../api'

const { URL_API } = ApiURL
const { THREE_SIXTY } = services
const { GET_ROLE_LIST, GET_ROLE, CREATE_ROLE, EDIT_ROLE, ENABLE_ROLE, GET_ROLE_ACTIVE_LIST, ADD_ROL_FAMILY_USER, RESET_ROL_FAMILY_USER } = subServices

export const useCreateRole = () => usePost<Role, Role>(`${URL_API}/${THREE_SIXTY}/${CREATE_ROLE}`, { id: 1 })

export const useGetRoleById = (id: string) => useFetch<Role>(`${URL_API}/${THREE_SIXTY}/${GET_ROLE}?id=${id}`, { id })

export const useGetRolesList = (pagination?: PaginationRequest) =>
  useFetch<ResponsePagination>(setPaginationToEndpoint(`${URL_API}/${THREE_SIXTY}/${GET_ROLE_LIST}`, pagination), pagination)

export const useGetRolesActiveList = () => useFetch<Role[]>(`${URL_API}/${THREE_SIXTY}/${GET_ROLE_ACTIVE_LIST}`)

export const useUpdateRole = () => useUpdate<ThreeSixtyEditForm, ThreeSixtyEditForm>(`${URL_API}/${THREE_SIXTY}/${EDIT_ROLE}`, { id: 1 })

export const usePatchRole = () => useApply<Role, Role>(`${URL_API}/${THREE_SIXTY}/${ENABLE_ROLE}`, { id: 1 })

export const useUpdateUserFamilyRole = () => usePost<UserRole, UserRole>(`${URL_API}/${THREE_SIXTY}/${ADD_ROL_FAMILY_USER}`, { id: 1 })

export const getRoleActiveList = (scopeId?: string) =>
  api.get<Role[]>(`${URL_API}/${THREE_SIXTY}/${GET_ROLE_ACTIVE_LIST}${scopeId ? `?RolScopeId=${scopeId}` : ''}`, { id: 1 })

export const resetRoleFamily = (userId: string) => api.patch<ResetRole>(`${URL_API}/${THREE_SIXTY}/${RESET_ROL_FAMILY_USER}`, { userId })
