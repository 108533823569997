import { ApiURL, services, subServices } from 'services/api.service'
import { usePost } from 'api/reactQuery'
import { api } from 'api/api'
import { GuestUser, RegisterGuestUser } from './account.interface'

const { URL_API } = ApiURL
const { ACCOUNT } = services
const { CREATE_GUEST_USER, GET_GUEST_USER, REGISTER_GUEST_USER_AS_COMMON } = subServices

export const useCreateGuestUser = () => usePost<GuestUser, GuestUser>(`${URL_API}/${ACCOUNT}/${CREATE_GUEST_USER}`, { id: 1 })

export const getGuestUser = (userId: string) => api.get<GuestUser>(`${URL_API}/${ACCOUNT}/${GET_GUEST_USER}?Id=${userId}`, { userId })

export const useRegisterGuestUserAsCommon = () =>
  usePost<RegisterGuestUser, RegisterGuestUser>(`${URL_API}/${ACCOUNT}/${REGISTER_GUEST_USER_AS_COMMON}`, { id: 1 })
