import { Button, CircularProgress } from '@mui/material'
import { GridColDef } from '@mui/x-data-grid'
import DataTable from 'shared/components/dataTable/dataTable.component'
import PlayIcon from '@mui/icons-material/PlayArrowRounded'
import DeleteIcon from '@mui/icons-material/Delete'
import { useGetUserQuestionnaireList } from 'api/questionnaire/questionnaire.api'
import { getClassAsPercentage, getLabelNotification } from 'shared/helpers'
import useCheckToken from 'shared/hooks/useCheckToken.hook'
import { useState } from 'react'
import { PaginationRequest } from 'ts/interfaces/request.interfaces'

export const AssignQuestionnaireList = ({ seasonSelected }) => {
  const { role, id } = useCheckToken()
  console.log('role--', role);
  const { data: userQuestionnaireList, isLoading } = useGetUserQuestionnaireList(seasonSelected, id, role)

  const columns: GridColDef[] = [
    {
      field: 'evaluador',
      headerName: 'Evaluador',
      type: 'string',
      flex: 1,
      renderCell: (params) => params.row.fromUser.userName,
    }, {
      field: 'evaluado',
      headerName: 'Evaluado',
      type: 'string',
      flex: 1,
      renderCell: (params) => params.row.toUser.userName,
    }, {
      field: 'relacion',
      headerName: 'Relacion',
      type: 'string',
      flex: 1,
      renderCell: (params) => `${params.row.evaluatorCategory.description}`,
    }, {
      field: 'inicioFin',
      headerName: 'Programacion',
      type: 'string',
      flex: 1,
      renderCell: (params) =>
        `${params.row.season.name} (${new Date(params.row.season.startDate).toLocaleDateString()}-${new Date(
          params.row.season.endDate,
        ).toLocaleDateString()})`,
    }, {
      field: 'questionnaire',
      headerName: 'Cuestionario',
      type: 'string',
      flex: 1,
      renderCell: (params) => <span>{`${params.row.questionnaire.name} (${params.row.questionnaire.family.name}-${params.row.questionnaire.rol.name})`}</span>,
    }, {
      field: 'percentage',
      headerName: 'Avance %',
      type: 'string',
      width: 100,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => (
        <span className={`badge badge-${getClassAsPercentage(params.row.percentage)}`} title={`${params.row.percentage}%`}>
          {getLabelNotification(Math.round(params.row.percentage))}
        </span>
      ),
    }, {
      field: 'runMail',
      headerName: 'Enviar Invitacion',
      width: 100,
      sortable: false,
      disableColumnMenu: true,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        const letterScheduleId: string = params.row.id

        return (
          <Button>
            <PlayIcon />
          </Button>
        )
      },
    }, {
      field: 'delete',
      headerName: 'Delete',
      width: 100,
      sortable: false,
      disableColumnMenu: true,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        const letterScheduleId: string = params.row.id

        return (
          <Button>
            <DeleteIcon />
          </Button>
        )
      },
    },
  ]

  if (isLoading) return <CircularProgress />

  return (
    <div className='card-body'>
      <DataTable
        data={userQuestionnaireList ?? []}
        columns={columns}
        getRowId={(row) => row.id}
        // pageSize={pagination.pageSize}
        // pageNumber={pagination.pageNumber}
        totalRows={userQuestionnaireList.length}
        // sortBy={pagination.sortBy}
        // sortOrder={pagination.sortOrder}
        // serverMode
        // onPaginationChangeHandler={onPaginationChangeHandler}
      />
    </div>
  )
}
