import PropTypes from 'prop-types'
import { getValueByObjectKey } from 'shared/helpers'

interface InputComponent {
  type: string
  name: string
  reg: any
  errors: any
  icon?: string
  id?: string
  className?: string
  placeholder?: string
  min?: number | null
  max?: number | null
  autoComplete?: string
  style?: Record<string, string>
  props?: Record<string, string>
  defaultValue?: string | number | any
}

const Input = ({
  type = 'text',
  name,
  reg = null,
  errors,
  icon,
  id,
  className = 'form-control rounded-0',
  placeholder = name,
  min = null,
  max = null,
  autoComplete = 'off',
  style,
  props,
  defaultValue,
}: InputComponent) => {
  const showIcon = () => (
    <div className='input-group-append'>
      <div className='input-group-text'>
        <span className={`fas ${icon}`}></span>
      </div>
    </div>
  )

  return (
    <>
      <input
        type={type}
        {...reg}
        className={`${className} ${getValueByObjectKey(errors, name) ? 'is-invalid' : ''}`}
        placeholder={placeholder}
        id={id}
        min={min}
        max={max}
        autoComplete={autoComplete}
        style={{ ...style }}
        {...props}
        defaultValue={defaultValue}
      />
      {icon && showIcon()}
      <div className='invalid-feedback'>{getValueByObjectKey(errors, name)?.message}</div>
    </>
  )
}

Input.propTypes = {
  type: PropTypes.string,
  reg: PropTypes.any,
  name: PropTypes.string,
  errors: PropTypes.object,
  icon: PropTypes.string,
  id: PropTypes.string,
  className: PropTypes.string,
}

export default Input
