import { useApply, useFetch, usePost, useUpdate } from "api/reactQuery"
import { ApiURL, services, subServices } from "services/api.service"
import { EnableDisableRequest, PaginationRequest, ResponsePagination } from "ts/interfaces/request.interfaces"
import { MailDistributionResponse } from "api/mailDistribution/mailDistribution.interface"
import { EditMailDistributionForm, NewMailDistributionForm } from "ts/interfaces/app.interfaces"

const { URL_API } = ApiURL
const { LETTER } = services
const {
  GET_MAIL_DISTRIBUTION,
  GET_MAIL_DISTRIBUTION_LIST,
  CREATE_MAIL_DISTRIBUTION,
  EDIT_MAIL_DISTRIBUTION,
  ENABLE_MAIL_DISTRIBUTION,
} = subServices

export const useGetMailDistributionList = (pagination?: PaginationRequest) =>
  useFetch<ResponsePagination>(`${URL_API}/${LETTER}/${GET_MAIL_DISTRIBUTION_LIST}`, pagination)

export const useGetMailDistributionById = (id: string) =>
  useFetch<MailDistributionResponse>(`${URL_API}/${LETTER}/${GET_MAIL_DISTRIBUTION}`, { id })

export const useCreateMailDistribution = () =>
  usePost<NewMailDistributionForm, NewMailDistributionForm>(`${URL_API}/${LETTER}/${CREATE_MAIL_DISTRIBUTION}`)

export const useEditMailDistribution = () =>
  useUpdate<EditMailDistributionForm, EditMailDistributionForm>(`${URL_API}/${LETTER}/${EDIT_MAIL_DISTRIBUTION}`)

export const useEnableMailDistribution = () =>
  useApply<EnableDisableRequest, EnableDisableRequest>(`${URL_API}/${LETTER}/${ENABLE_MAIL_DISTRIBUTION}`)
