import { useNavigate } from 'react-router-dom'
import Cookies from 'js-cookie'
import { useTranslation } from 'react-i18next'

const Logout = () => {
  const [t] = useTranslation('global')
  const navigate = useNavigate()

  const logoutHandler = () => {
    try {
      Cookies.remove('token')
      localStorage.clear()
    } catch (error) {
      console.error('error', error)
    } finally {
      navigate('/login')
    }
  }

  return (
    <a className="nav-link" role="button" tabIndex={0} onClick={() => logoutHandler()}>
      {t('header.logout')}
    </a>
  )
}

Logout.propTypes = {}

export default Logout
