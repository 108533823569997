import * as Yup from 'yup'
import { t } from 'i18next'

export const validationNewFamilySchema = Yup.object().shape({
  name: Yup.string()
    .required(t('threeSixty:yup.family.name.required'))
    .min(1, t('threeSixty:yup.family.name.min'))
    .max(100, t('threeSixty:yup.family.name.max')),
  description: Yup.string()
    .required(t('threeSixty:yup.family.description.required'))
    .min(1, t('threeSixty:yup.family.description.min'))
    .max(500, t('threeSixty:yup.family.description.max')),
})
