import { SyntheticEvent, useEffect, useState } from 'react'
import { SelectOptionI } from 'ts/interfaces/app.interfaces'

import { FormControl, Box, Autocomplete } from '@mui/material'
import './selectAutocomplete.style.scss'

const getDefaultSelection = (): SelectOptionI => {
  return { label: '', value: '', disabled: true }
}

interface SelectAutocompleteComponent {
  id?: string
  name: string
  elements: SelectOptionI[]
  setFieldValue: (value: string) => any
  errors: any
  errorIcon?: string
  placeholder?: string
  defaultSelectedOption?: SelectOptionI
  onSelectionChange?: (value: string) => void
}

const SelectAutocomplete = ({
  id,
  name,
  elements,
  setFieldValue,
  errors,
  errorIcon,
  placeholder = name,
  defaultSelectedOption,
  onSelectionChange,
}: SelectAutocompleteComponent) => {
  // States:
  const [selectedOption, setSelectedOption] = useState<SelectOptionI>(defaultSelectedOption ?? getDefaultSelection())

  // Effects
  useEffect(() => {
    if (defaultSelectedOption) {
      setSelectedOption(defaultSelectedOption)
      setFieldValue(defaultSelectedOption.value)
    }
  }, [defaultSelectedOption, setFieldValue])

  useEffect(() => {
    if (onSelectionChange) {
      onSelectionChange(selectedOption.value)
    }
  }, [onSelectionChange, selectedOption])

  // Handlers
  const onChangeHandler = (_: SyntheticEvent, newValue: SelectOptionI | any) => {
    if (newValue) {
      console.log('newValue', newValue)
      setSelectedOption(newValue)
      setFieldValue(newValue.value)
    }
  }

  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl className='input-group mb-3'>
        <Autocomplete
          id={`${id}-autocomplete`}
          key={`${id}-autocomplete`}
          options={[getDefaultSelection(), ...elements]}
          // value={selectedOption}
          isOptionEqualToValue={(opt, val) => opt.value === val.value}
          renderInput={(params) => {
            return (
              <div ref={params.InputProps.ref} className='form-control rounded-0'>
                <input
                  style={{ border: 'none', width: '100%' }}
                  className='form-control rounded-0'
                  type='text'
                  placeholder={placeholder}
                  {...params.inputProps}
                />
              </div>
            )
          }}
          onChange={(event, newInputValue) => {
            console.log('event', event, 'newInputValue', newInputValue)
            onChangeHandler(event, newInputValue)
          }}
          getOptionDisabled={(opt) => opt.disabled ?? false}
        />
        {errors && errors[name] && (
          <div className='d-block invalid-feedback'>
            <span className={`fas ${errorIcon ?? 'fa-exclamation-circle'}`}></span>
            {` ${errors[name]?.message}`}
          </div>
        )}
      </FormControl>
    </Box>
  )
}

export default SelectAutocomplete
