// import { AnyAction, applyMiddleware, Dispatch, Middleware } from 'redux'
import { configureStore } from '@reduxjs/toolkit'
import { routerMiddleware } from 'connected-react-router'
import { createBrowserHistory } from 'history'

import rootReducer from 'redux/root-reducer'

export const history = createBrowserHistory()

// const middlewares = [thunk, apiMiddleware]

// let compose = null
// if (process.env.NODE_ENV === 'development') {
// const composeEnhancers = composeWithDevTools({ trace: true })
// compose = composeEnhancers(applyMiddleware(...middlewares))
// } else {
// compose = composeWithDevTools(applyMiddleware(...middlewares))
// }
const preloadedState = {}
export const store = configureStore({
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(routerMiddleware(history)),
  reducer: rootReducer(history),
  preloadedState,
})

// export const useAppDispatch = () => useDispatch<any, any>() //

export default { store }
