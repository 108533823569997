import * as Yup from 'yup'
import { t } from 'i18next'

export const validationCreateUserAdminSchema = Yup.object().shape({
  userName: Yup.string()
    .required(t('users:yup.username.required'))
    .min(6, t('users:yup.username.min'))
    .max(20, t('users:yup.username.max'))
    .matches(/^[0-9a-zA-Z_-]+$/, t('users:yup.username.matches')),
  firstName: Yup.string().required(t('users:yup.first-name.required')).min(1, t('users:yup.first-name.min')).max(100, t('users:yup.first-name.max')),
  lastName: Yup.string().required(t('users:yup.last-name.required')).min(1, t('users:yup.last-name.min')).max(100, t('users:yup.last-name.max')),
  email: Yup.string().required(t('users:yup.email.required')).email(t('users:yup.email.email')),
  password: Yup.string()
    .required(t('users:yup.password.required'))
    .min(6, t('users:yup.password.min'))
    .max(40, t('users:yup.password.max'))
    .matches(/[^a-zA-Z0-9_]/g, 'Password needs an special character like _-@#$%&'),
  confirmPassword: Yup.string()
    .required(t('users:yup.confirm-password.required'))
    .oneOf([Yup.ref('password'), null], t('users:yup.confirm-password.one-of')),
  roles: Yup.array().nullable().required(t('users:yup.roles.required')).min(1, t('users:yup.roles.min')),
  tenantId: Yup.string().required(t('users:yup.tenant.required')),
})
