import { ApiURL, services, subServices } from 'services/api.service'
import { PaginationRequest, ResponsePagination } from 'ts/interfaces/request.interfaces'
import { setPaginationToEndpoint } from 'shared/helpers/pagination'
import { fetcher, useApply, useFetch, usePost, useUpdate } from 'api/reactQuery'
import { ClusterFactor, Factor } from './factor.interface'

const { URL_API } = ApiURL
const { THREE_SIXTY } = services
const { GET_FACTOR_LIST, GET_FACTOR, CREATE_FACTOR, EDIT_FACTOR, ENABLE_FACTOR, GET_FACTOR_ACTIVE_LIST } = subServices

export const useCreateFactor = () => usePost<Factor, Factor>(`${URL_API}/${THREE_SIXTY}/${CREATE_FACTOR}`, { id: 1 })

export const useGetFactorById = (id: string) => useFetch<Factor>(`${URL_API}/${THREE_SIXTY}/${GET_FACTOR}?id=${id}`, { id })

export const useGetFactorList = (pagination?: PaginationRequest) =>
  useFetch<ResponsePagination>(setPaginationToEndpoint(`${URL_API}/${THREE_SIXTY}/${GET_FACTOR_LIST}`, pagination), pagination)

export const useGetFactorActiveList = () => useFetch<ClusterFactor[]>(`${URL_API}/${THREE_SIXTY}/${GET_FACTOR_ACTIVE_LIST}`, { id: 1 })

export const useUpdateFactor = () => useUpdate<Factor, Factor>(`${URL_API}/${THREE_SIXTY}/${EDIT_FACTOR}`, { id: 1 })

export const usePatchFactor = () => useApply<Factor, Factor>(`${URL_API}/${THREE_SIXTY}/${ENABLE_FACTOR}`, { id: 1 })

export const getFactorActiveList = (pagination?: PaginationRequest) => {
  return fetcher<Factor[]>({ queryKey: [`${URL_API}/${THREE_SIXTY}/${GET_FACTOR_LIST}`, pagination] })
}
