import { LetterScheduleResponse, SendLetterRequest } from "api/letterSchedule/letterSchedule.interface"
import axios from 'axios'
import { useApply, useFetch, usePost, useUpdate } from "api/reactQuery"
import { ApiURL, services, subServices } from "services/api.service"
import { ActiveListRecord, CreateLetterScheduleRequest, EditLetterScheduleRequest, EnableDisableRequest, PaginationRequest, ResponsePagination } from "ts/interfaces/request.interfaces"

const { URL_API } = ApiURL
const { LETTER } = services
const {
  GET_LETTER_SCHEDULE,
  GET_LETTER_SCHEDULE_LIST,
  CREATE_LETTER_SCHEDULE,
  EDIT_LETTER_SCHEDULE,
  DELETE_LETTER_SCHEDULE,
  ENABLE_LETTER_SCHEDULE,
  GET_LETTER_ACTIVE_LIST,
  GET_DISTRIBUTION_ACTIVE_LIST,
  GET_SCHEDULE_ACTIVE_LIST,
  SEND_LETTER,
} = subServices

export const useGetLetterScheduleList = (pagination?: PaginationRequest) =>
  useFetch<ResponsePagination>(`${URL_API}/${LETTER}/${GET_LETTER_SCHEDULE_LIST}`, pagination)

export const useGetLetterScheduleById = (id: string) =>
  useFetch<LetterScheduleResponse>(`${URL_API}/${LETTER}/${GET_LETTER_SCHEDULE}`, { id })

export const useCreateLetterSchedule = () =>
  usePost<CreateLetterScheduleRequest, CreateLetterScheduleRequest>(`${URL_API}/${LETTER}/${CREATE_LETTER_SCHEDULE}`)

export const useEditLetterSchedule = () =>
  useUpdate<EditLetterScheduleRequest, EditLetterScheduleRequest>(`${URL_API}/${LETTER}/${EDIT_LETTER_SCHEDULE}`)

export const useDeleteLetterSchedule = (id: string) =>
  axios.delete(`${URL_API}/${LETTER}/${DELETE_LETTER_SCHEDULE}?id=${id}`)

export const useSendLetter = () =>
  usePost<SendLetterRequest, SendLetterRequest>(`${URL_API}/${LETTER}/${SEND_LETTER}`)

export const useEnableLetterSchedule = () =>
  useApply<EnableDisableRequest, EnableDisableRequest>(`${URL_API}/${LETTER}/${ENABLE_LETTER_SCHEDULE}`)

export const useGetLetterActiveList = () =>
  useFetch<ActiveListRecord[]>(`${URL_API}/${LETTER}/${GET_LETTER_ACTIVE_LIST}`)

export const useGetMailDistributionActiveList = () =>
  useFetch<ActiveListRecord[]>(`${URL_API}/${LETTER}/${GET_DISTRIBUTION_ACTIVE_LIST}`)

export const useGetScheduleActiveList = () =>
  useFetch<ActiveListRecord[]>(`${URL_API}/${LETTER}/${GET_SCHEDULE_ACTIVE_LIST}`)
