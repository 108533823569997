import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useCreateMailDistribution } from 'api/mailDistribution/mailDistribution.api'
import { Link, useNavigate } from 'react-router-dom'
import generateStringId, { getPosition } from 'shared/helpers'
import { yupResolver } from '@hookform/resolvers/yup'
import { validationNewDistributionListSchema } from 'pages/distributionList/newDistributionList.validation'
import { MailDistributionForm, NewMailDistributionForm } from 'ts/interfaces/app.interfaces'

import readXlsxFile from 'read-excel-file'
import { ContentHeader } from 'shared/components/contentHeader/contentHeader.component'
import Input from 'shared/components/input/input.component'
import UploadFile from 'shared/components/uploadFile/uploadFile.component'
import { pushNotification } from 'shared/helpers/notifications/notifications'

const createNewMailOption = (name = '', email = ''): MailDistributionForm => {
  return { id: generateStringId(), name, email, emailType: 'main' }
}

const NewDistributionList = () => {
  const [t] = useTranslation(['letters', 'global'])

  const { mutateAsync, status } = useCreateMailDistribution()
  const navigate = useNavigate()

  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
    getValues,
  } = useForm<NewMailDistributionForm>({
    resolver: yupResolver(validationNewDistributionListSchema),
  })
  const mailDistributionName = watch('name', t('letters:mail-distribution.creation-default-name') as string)

  console.log('errors', errors)
  console.log('getVAlues', getValues())

  // States:
  const [mailDistributionList, setMailDistributionList] = useState<MailDistributionForm[]>([])
  const [isActiveImportDL, setIsActiveImportDL] = useState(false)
  const [importFile, setImportFile] = useState<File>()

  // Handlers:
  const addOptionHandler = () => {
    const extraOption = createNewMailOption()
    setMailDistributionList([...mailDistributionList, extraOption])
  }

  const removeOptionHandler = (distribution: MailDistributionForm) => {
    const positionDelete = getPosition(mailDistributionList, distribution, 'id')
    const copyDistributionList = mailDistributionList
    copyDistributionList.splice(positionDelete, 1)
    setMailDistributionList([...copyDistributionList])
  }

  const onSubmit: SubmitHandler<NewMailDistributionForm> = async (data) => {
    try {
      await mutateAsync(data)

      if (['success', 'idle'].includes(status)) {
        reset({})
        navigate('/distributionList')
      }
    } catch (error) {
      pushNotification((error?.response?.data?.error?.code as string) ?? t('letters:messages.error'))
    }
  }

  // Render Distribution List Options:
  const renderDistributionList = (distribution: MailDistributionForm, index: number) => {
    const { id, name, email } = distribution

    return (
      <div key={id} className='row'>
        <div className='col-md-4'>
          <div className='form-group'>
            <label htmlFor={`distributionList.${index}.name`}>{t('letters:mail-distribution.dist-list.name')}</label>
            <Input
              type='text'
              name={`distributionList.${index}.name`}
              reg={register(`mailDistributionDetails.${index}.name`)}
              errors={errors}
              placeholder={t('letters:mail-distribution.dist-list.name-placeholder')}
              defaultValue={name}
            />
          </div>
        </div>

        <div className='col-md-4'>
          <div className='form-group'>
            <label htmlFor={`distributionList.${index}.email`}>{t('letters:mail-distribution.dist-list.email')}</label>
            <Input
              type='text'
              name={`distributionList.${index}.email`}
              reg={register(`mailDistributionDetails.${index}.email`)}
              errors={errors}
              placeholder={t('letters:mail-distribution.dist-list.email-placeholder')}
              defaultValue={email}
            />
          </div>
        </div>

        <div className='input-group-prepend'>
          <span
            onClick={() => removeOptionHandler(distribution)}
            className='input-group-text rounded-0'
            style={{ border: 'none', backgroundColor: 'white', cursor: 'pointer' }}
            title={t('letters:mail-distribution.dist-list.delete-option')}
          >
            {/* <i className='fa fa-times-circle'></i> */}
            <i className='fa fa-times' style={{ fontSize: 24 }} />
          </span>
        </div>
      </div>
    )
  }

  const getSaveNameEmail = ([name, email]: string[]) => {
    const newUserMail = createNewMailOption(name, email)
    setMailDistributionList((previousState) => [...previousState, newUserMail])
  }

  useEffect(() => {
    try {
      importFile &&
        readXlsxFile(importFile).then((rows) => {
          rows.forEach((row, i) => {
            if (i !== 0) {
              getSaveNameEmail(row as string[])
            }
          })
        })
    } catch (error) {
      console.log('error', error)
    } finally {
      setIsActiveImportDL(false)
    }
  }, [importFile])

  return (
    <>
      <ContentHeader
        title={t('letters:mail-distribution.creation-header')}
        breadCrumb={{ home: t('global:sidebar.home'), page: t('global:sidebar.mail-distributions') }}
      />

      <section className='content rounded-0'>
        <div className='container-fluid'>
          <div className='callout callout-primary'>
            <h5>{t('letters:mail-distribution.creation-description-title')}</h5>
            <p>{t('letters:mail-distribution.creation-description-body')}</p>
          </div>
        </div>
      </section>

      <section className='content rounded-0'>
        <div className='card card-primary card-outline'>
          <div className='card-header border-0'>
            <div className='d-flex justify-content-between'>
              <h3 className='card-title'>
                <strong>{mailDistributionName}</strong>
              </h3>
              <Link to='/distributionList'>{t('global:cruds.back-button')}</Link>
            </div>
          </div>

          <div className='card-body'>
            <form id='new-mail-distribution-form' className='row' onSubmit={handleSubmit(onSubmit)}>
              <div className='col-md-12'>
                <div className='row'>
                  <div className='col'>
                    <div className='form-group'>
                      <label htmlFor='mailDistributionName'>{t('letters:mail-distribution.table-headers.mail-distribution')}</label>
                      <Input
                        type='text'
                        name='mailDistributionName'
                        reg={register('name')}
                        errors={errors}
                        placeholder={t('letters:mail-distribution.table-headers.mail-distribution')}
                      />
                    </div>
                  </div>
                </div>

                <div className='row'>
                  <div className='col-md-12'>
                    <h4>{t('letters:mail-distribution.table-headers.reply-to')}</h4>
                  </div>

                  <div className='col-md-4'>
                    <div className='form-group'>
                      <label htmlFor='replyTo.name'>{t('letters:mail-distribution.dist-list.name')}</label>
                      <Input
                        type='text'
                        name='replyTo.name'
                        reg={register('replyTo.name')}
                        errors={errors}
                        placeholder={t('letters:mail-distribution.dist-list.name-placeholder')}
                      />
                    </div>
                  </div>

                  <div className='col-md-4'>
                    <div className='form-group'>
                      <label htmlFor='replyTo.email'>{t('letters:mail-distribution.dist-list.email')}</label>
                      <Input
                        type='text'
                        name='replyTo.email'
                        reg={register('replyTo.email')}
                        errors={errors}
                        placeholder={t('letters:mail-distribution.dist-list.email-placeholder')}
                      />
                    </div>
                  </div>
                </div>

                <div className='row'>
                  <div className='col-md-12'>
                    <h4>{t('letters:mail-distribution.table-headers.distribution-list')}</h4>
                  </div>

                  <div className='col-md-12'>
                    {mailDistributionList && mailDistributionList.map((distribution, index) => renderDistributionList(distribution, index))}
                  </div>
                </div>

                <div className='row'>
                  <div className='col'>
                    <span className='text-primary add-option' onClick={addOptionHandler}>
                      {` ${t('letters:mail-distribution.dist-list.add-option')} `}
                    </span>
                    /
                    <span className='text-primary add-option' onClick={() => setIsActiveImportDL(!isActiveImportDL)}>
                      {` ${t('letters:mail-distribution.dist-list.import-file')} `}
                    </span>
                  </div>
                </div>
                {isActiveImportDL && (
                  <div className='row'>
                    <div className='col'>
                      <UploadFile fileTypes={['XLS', 'XLSX']} onHandleFile={setImportFile} file={importFile} />
                    </div>
                  </div>
                )}

                <div className='row'>
                  <div className='offset-md-9 col-md-3'>
                    <div className='form-group'>
                      {/* <div className="col-3"> */}
                      <button type='submit' className='btn btn-success btn-block'>
                        {t('global:cruds.save-button')}
                      </button>
                      {/* </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  )
}

export default NewDistributionList
