import { Register, RecoveryPassword, ForgotPassword, Login, StarterContent, Users } from 'pages/index'
import { Routes, Route } from 'react-router-dom'
import { LinkedInCallback } from 'react-linkedin-login-oauth2'

import ProtectedRoute from 'shared/components/protectedRoute/protectedRoute.compoenent'
import Letter from 'pages/letter/letter.component'
import NewLetter from 'pages/letter/newLetter.component'
import Profile from 'pages/profile/profile.component'
// import Questionnaire from 'pages/_questionnaire/questionnaire.component'
// import NewQuestionnaire from 'pages/_questionnaire/newQuestionnaire.component'
import NewSchedule from 'pages/schedule/newSchedule.component'
import Schedule from 'pages/schedule/schedule.component'
import NewDistributionList from 'pages/distributionList/newDistributionList.component'
import DistributionList from 'pages/distributionList/distributionList.component'
import EditLetter from 'pages/letter/editLetter.component'
import EditSchedule from 'pages/schedule/editSchedule.component'
import LetterComposition from 'pages/letterComposition/letterComposition.component'
import NewLetterComposition from 'pages/letterComposition/newLetterComposition.component'
import EditDistributionList from 'pages/distributionList/editDistributionList.component'
import EditLetterComposition from 'pages/letterComposition/editLetterComposition.component'
import Subscriptions from 'pages/subscriptions/subscriptions.component'
import Products from 'pages/products/products.component'
import NewLevel from 'pages/threeSixty/level/newLevel.component'
import Level from 'pages/threeSixty/level/level.component'
import NewFactor from 'pages/threeSixty/factor/newFactor.component'
import Factor from 'pages/threeSixty/factor/factor.component'
import ConfigureQuestionnaire from 'pages/configureQuestionnaire/configureQuestionnaire.component'
import NewConfigurationQuestionnaire from 'pages/configureQuestionnaire/newConfigureQuestionnaire.component'
import Plans from 'pages/plans/plans.component'
import Family from 'pages/threeSixty/family/family.component'
import ClusterType from 'pages/threeSixty/clusterType/clusterType.component'
import NewClusterType from 'pages/threeSixty/clusterType/newClusterType.component'
import NewFamily from 'pages/threeSixty/family/newFamily.component'
import Role from 'pages/threeSixty/role/role.component'
import NewGuest from 'pages/threeSixty/role/newGuest.component'
import EditFamily from 'pages/threeSixty/family/editFamily.component'
import EditClusterType from 'pages/threeSixty/clusterType/editClusterType.component'
import EditCluster from 'pages/threeSixty/cluster/editCluster.component'
import Cluster from 'pages/threeSixty/cluster/cluster.component'
import NewCluster from 'pages/threeSixty/cluster/newCluster.component'
import { ConfigureThreeSixty } from 'pages/threeSixty/configureQuiz/configureThreeSixty'
import Questions from 'pages/threeSixty/questions/questions.component'
import { NewQuestion } from 'pages/threeSixty/questions/newQuestion.component'
import { QuestionView } from 'pages/questionView/questionView.component'
import { Dashboard } from 'pages/dashboard/dashboard.component'
import CategoryEvaluator from 'pages/categoryEvaluator/categoryEvaluator'
import CategoryEvaluatorForm from 'pages/categoryEvaluator/categoryEvaluatorForm'
import Tenant from 'pages/tenant/tenant.component'
import NewTenant from 'pages/tenant/newTenant.component'
import { NewQuestionnaire } from 'pages/questionnaire/newQuestionnaire.component'
import Questionnaire from 'pages/questionnaire/questionnaire.component'
import { NewAssignQuestionnaireComponent } from 'pages/assignQuestionnaire/newAssignQuestionnaire.component'
import { UserRole } from 'pages/userRole/userRole.component'
import { UserForm } from 'pages/users/userForm'
import { AssignQuestionnaireComponent } from 'pages/assignQuestionnaire/assignQuestionnaire.component'
import { AssignQuestionnaireUser } from 'pages/assignQuestionnaire/AssignQuestionnaireUser'
import RegisterGuestUser from 'pages/login/registerGuestUser.component'
import EditTenant from 'pages/tenant/editTenant.component'
import WebPage from 'pages/webPage/webPage'

const RoutesApp = () => {
  return (
    <Routes>
      <Route path='/login' element={<Login />} />
      <Route path='register' element={<Register />} />
      <Route path='confirmresetpassword' element={<Login />} />
      <Route path='guestUser/:token' element={<RegisterGuestUser />} />
      <Route element={<ProtectedRoute />}>
        <Route path='confirmresetpassword/:userId/:token' element={<RecoveryPassword />} />
      </Route>
      <Route path='forgotPassword' element={<ForgotPassword />} />
      <Route path='callback' element={<LinkedInCallback />} />
      <Route path='page' element={<WebPage />} />
      <Route element={<ProtectedRoute />}>
        <Route path='/'>
          <Route index element={<Dashboard />} />
          <Route path='dashboard' element={<Dashboard />} />
          <Route path='guests'>
            <Route path='new' element={<NewGuest />} />
            <Route path='edit/:guestId' element={<NewGuest />} />
          </Route>
          <Route path='profile' element={<Profile />} />
          <Route path='letter'>
            <Route path='new' element={<NewLetter />} />
            <Route path='edit/:letterId' element={<EditLetter />} />
            <Route index element={<Letter />} />
          </Route>
          <Route path='schedule'>
            <Route path='new' element={<NewSchedule />} />
            <Route path='edit/:scheduleId' element={<EditSchedule />} />
            <Route index element={<Schedule />} />
          </Route>
          <Route path='distributionList'>
            <Route path='new' element={<NewDistributionList />} />
            <Route path='edit/:distributionId' element={<EditDistributionList />} />
            <Route index element={<DistributionList />} />
          </Route>
          <Route path='letterComposition'>
            <Route path='new' element={<NewLetterComposition />} />
            <Route path='edit/:letterScheduleId' element={<EditLetterComposition />} />
            <Route index element={<LetterComposition />} />
          </Route>
          <Route path='cluster'>
            <Route path='new' element={<NewCluster />} />
            <Route path='edit/:clusterId' element={<EditCluster />} />
            <Route index element={<Cluster />} />
          </Route>
          <Route path='clusterType'>
            <Route path='new' element={<NewClusterType />} />
            <Route path='edit/:clusterTypeId' element={<EditClusterType />} />
            <Route index element={<ClusterType />} />
          </Route>
          <Route path='family'>
            <Route path='new' element={<NewFamily />} />
            <Route path='edit/:familyId' element={<EditFamily />} />
            <Route index element={<Family />} />
          </Route>
          <Route path='role'>
            <Route path='new' element={<NewGuest />} />
            <Route path='edit/:roleId' element={<NewGuest />} />
            <Route index element={<Role />} />
          </Route>
          <Route path='level'>
            <Route path='new' element={<NewLevel />} />
            <Route path='edit/:levelId' element={<NewLevel />} />
            <Route path='questions/:levelId' element={<Questions />} />
            <Route path='questions/new/:levelId' element={<NewQuestion />} />
            <Route path='questions/edit/:levelId/:questionId' element={<NewQuestion />} />
            <Route index element={<Level />} />
          </Route>
          <Route path='factor'>
            <Route path='new' element={<NewFactor />} />
            <Route path='edit/:factorId' element={<NewFactor />} />
            <Route index element={<Factor />} />
          </Route>
          <Route path='configurationThreeSixty'>
            <Route index element={<ConfigureThreeSixty />} />
          </Route>
          <Route path='configurationQuestionnaire'>
            <Route path='new' element={<NewConfigurationQuestionnaire />} />
            <Route path='edit/:letterScheduleId' element={<EditLetterComposition />} />
            <Route index element={<ConfigureQuestionnaire />} />
          </Route>
          <Route path='subscriptions' element={<Subscriptions />} />
          <Route path='products' element={<Products />} />
          <Route path='plans' element={<Plans />} />
          <Route path='users'>
            <Route index element={<Users />} />
            <Route path='new' element={<UserForm />} />
            <Route path='role' element={<UserRole />} />
          </Route>
          <Route path='questionnaire'>
            <Route path='new' element={<NewQuestionnaire />} />
            <Route index element={<Questionnaire />} />
          </Route>
          <Route path='questionView/:questionnaireId/:assignationId'>
            <Route index element={<QuestionView />} />
          </Route>
          <Route path='categoryEvaluator'>
            <Route path='new' element={<CategoryEvaluatorForm />} />
            <Route path='edit/:categoryEvaluatorId' element={<CategoryEvaluatorForm />} />
            <Route index element={<CategoryEvaluator />} />
          </Route>
          <Route path='tenant'>
            <Route path='new' element={<NewTenant />} />
            <Route path='edit/:tenantId' element={<EditTenant />} />
            <Route index element={<Tenant />} />
          </Route>
          <Route path='customQuestions'>
            <Route path='new' element={<NewQuestionnaire />} />
            <Route index element={<Questionnaire />} />
          </Route>
          <Route path='questionnaires'>
            <Route path='new' element={<NewQuestionnaire />} />
            <Route index element={<Questionnaire />} />
          </Route>
          <Route path='assignQuestionnaire'>
            <Route path='new' element={<NewAssignQuestionnaireComponent />} />
            <Route path='user' element={<AssignQuestionnaireUser />} />
            <Route index element={<AssignQuestionnaireComponent />} />
          </Route>
        </Route>
      </Route>
      <Route path='*' element='Not Found' />
    </Routes>
  )
}

export default RoutesApp
