import PropTypes from 'prop-types'
import React from 'react'
// import logo from 'assets/img/logoHRXpert.png'

type Props = {
  title?: string,
  children: React.ReactNode;
};

const LoginWrapper: React.FC<Props> = ({ title, children }) => {
  return (
    <div className="hold-transition login-page">
      <div className="login-box">
        {/* <!-- /.login-logo --> */}
        <div className="card card-outline card-primary">
          <div className="card-header text-center">
            <img src={require('assets/img/logoHRXpert.png')} alt="HRXpert Logo" />
          </div>
          <div className="card-body">
            <p className="login-box-msg">{title}</p>
            {children}
          </div>
          {/* <!-- /.card-body --> */}
        </div>
        {/* <!-- /.card --> */}
      </div>
    </div>
  )
}

LoginWrapper.propTypes = {
  title: PropTypes.string,
}

export default LoginWrapper
