import { memo } from 'react'
import { Outlet } from 'react-router-dom'
import { Footer } from 'shared/components/footer/footer.component'
import { Navbar } from 'shared/components/navbar/navbar.component'
import { Sidebar } from 'shared/components/sidebar/sidebar.component'

export const Layout = () => {
  return (
    <>
      <Navbar />
      <Sidebar />
      <div className='content-wrapper'>
        <div className='content'>
          <Outlet />
        </div>
      </div>
      <Footer />
    </>
  )
}

export default memo(Layout)
