import { useEffect, useState } from 'react'
import { useCreateUser } from 'api/user/user.api'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import { ContentHeader } from 'shared/components/contentHeader/contentHeader.component'
import Input from 'shared/components/input/input.component'
import MultiSelect from 'shared/components/selectInput/multiSelect.component'
import { CreateUserAdminForm, SelectOptionI } from 'ts/interfaces/app.interfaces'
import { pushNotification } from 'shared/helpers/notifications/notifications'
import { validationCreateUserAdminSchema } from 'pages/users/createUser.validation'
import { yupResolver } from '@hookform/resolvers/yup'
import { useGetTenantActiveList } from 'api/tenant/tenant.api'
import SelectInput from 'shared/components/selectInput/selectInput.component'

export const UserForm = () => {
  const [t, i18n] = useTranslation(['users', 'global'])

  const { mutateAsync: createUserAsync, status: creationStatus } = useCreateUser()
  // Get Active Tenants
  const { data: activeTenants, status: activeTenantsStatus } = useGetTenantActiveList()

  const navigate = useNavigate()

  // States
  const [tenantOptions, setTenantOptions] = useState<SelectOptionI[]>([])

  // Effects
  useEffect(() => {
    if (activeTenants) {
      setTenantOptions(
        activeTenants.map((tenant) => {
          return { value: tenant.id, label: tenant.name }
        }),
      )
    }
  }, [activeTenants, activeTenantsStatus])

  const roleOptions: SelectOptionI[] = [
    { value: 'Admin', label: t('roles.admin') },
    { value: 'User', label: t('roles.user') },
    { value: 'PremiumUser', label: t('roles.premium-user') },
  ]

  const methods = useForm<CreateUserAdminForm>({
    resolver: yupResolver(validationCreateUserAdminSchema),
  })

  const userName = methods.watch('userName')

  const onSubmit: SubmitHandler<CreateUserAdminForm> = async data => {
    try {
      await createUserAsync({
        userName: data.userName,
        password: data.password,
        email: data.email,
        language: i18n.language,
        firstName: data.firstName,
        lastName: data.lastName,
        tenantId: data.tenantId,
        roles: data.roles,
      })

      if (['idle', 'success'].includes(creationStatus)) {
        methods.reset({
          userName: '',
          firstName: '',
          lastName: '',
          password: '',
          email: '',
          confirmPassword: '',
          roles: null,
          tenantId: null,
        })
        pushNotification(t('messages.user-created'), 'success')
        navigate('/users')
      }
    } catch (error) {
      pushNotification((error?.response?.data[0]?.description as string) ?? t('messages.error'), 'error')
    }
  }

  return (
    <>
      <ContentHeader
        title={t('users:creation-title')}
        breadCrumb={{ home: t('global:sidebar.home'), page: t('global:sidebar.users') }} />

      <section className='content rounded-0'>
        <div className='container-fluid'>
          <div className='callout callout-primary'>
            <h5>Creacion de Usuarios</h5>
            <p>{t('letters:letter.creation-description-body')}</p>
          </div>
        </div>
      </section>

      <section className='content rounded-0'>
        <div className='container-fluid'>
          <div className='card card-primary card-outline'>
            <div className='card-header border-0'>
              <div className='d-flex justify-content-between'>
                <h3 className='card-title'>
                  <strong>{userName}</strong>
                </h3>
                <Link to='/users'>{t('global:cruds.back-button')}</Link>
              </div>
            </div>

            <div className='card-body'>
              <form id='new-letter-form' className='row' onSubmit={methods.handleSubmit(onSubmit)}>
                <div className='col-md-12'>
                  <div className='row'>
                    <div className='col-sm-4'>
                      <div className='form-group'>
                        <label htmlFor='userName'>{t('users:form.username')}</label>
                        <Input
                          type='text'
                          name='letterName'
                          reg={methods.register('userName')}
                          errors={methods?.formState?.errors}
                          placeholder={t('users:form.username')}
                        />
                      </div>
                    </div>

                    <div className='col-sm-4'>
                      <div className='form-group'>
                        <label htmlFor='subject'>{t('users:form.first-name')}</label>
                        <Input
                          type='text'
                          name='firstName'
                          reg={methods.register('firstName')}
                          errors={methods?.formState?.errors}
                          placeholder={t('users:form.first-name')}
                        />
                      </div>
                    </div>

                    <div className='col-sm-4'>
                      <div className='form-group'>
                        <label htmlFor='subject'>{t('users:form.last-name')}</label>
                        <Input
                          type='text'
                          name='lastName'
                          reg={methods.register('lastName')}
                          errors={methods?.formState?.errors}
                          placeholder={t('users:form.last-name')}
                        />
                      </div>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-sm-4'>
                      <div className='form-group'>
                        <label htmlFor='email'>{t('users:form.email')}</label>
                        <Input
                          type='email'
                          name='email'
                          reg={methods.register('email')}
                          errors={methods?.formState?.errors}
                          placeholder={t('users:form.email')}
                        />
                      </div>
                    </div>

                    <div className='col-sm-4'>
                      <div className='form-group'>
                        <label htmlFor='password'>{t('users:form.password')}</label>
                        <Input
                          type='password'
                          name='password'
                          reg={methods.register('password')}
                          errors={methods?.formState?.errors}
                          placeholder='******'
                        />
                      </div>
                    </div>

                    <div className='col-sm-4'>
                      <div className='form-group'>
                        <label htmlFor='confirmPassword'>{t('users:form.confirm-password')}</label>
                        <Input
                          type='password'
                          name='confirmPassword'
                          reg={methods.register('confirmPassword')}
                          errors={methods?.formState?.errors}
                          placeholder='******'
                        />
                      </div>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-sm-4'>
                      <div className='form-group'>
                        <label htmlFor='role'>{t('users:form.select-user-roles')}</label>
                        <MultiSelect
                          id='modal-select-roles'
                          name='roles'
                          elements={roleOptions}
                          reg={methods.register('roles')}
                          errors={methods?.formState?.errors}
                          placeholder={t('users:form.select-user-roles')}
                        />
                      </div>
                    </div>
                    {/* Select Tenant */}
                    <div className='col-sm-4'>
                      <div className='form-group'>
                        <label htmlFor='role'>{t('users:form.select-tenant')}</label>
                        <SelectInput
                          id='modal-select-tenant'
                          name='tenantId'
                          reg={methods.register('tenantId')}
                          elements={tenantOptions}
                          errors={methods?.formState?.errors}
                          placeholder={t('users:form.select-tenant')}
                        />
                      </div>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='offset-md-9 col-md-3'>
                      <div className='form-group'>
                        {/* <div className="col-3"> */}
                        <button type='submit' className='btn btn-success btn-block'>
                          {t('global:cruds.save-button')}
                        </button>
                        {/* </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
