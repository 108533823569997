import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useEditMailDistribution, useGetMailDistributionById } from 'api/mailDistribution/mailDistribution.api'
import { Link, useNavigate, useParams } from 'react-router-dom'
import generateStringId, { getPosition } from 'shared/helpers'
import { yupResolver } from '@hookform/resolvers/yup'
import { EditMailDistributionForm, MailDistributionForm } from 'ts/interfaces/app.interfaces'
import { MailDistributionDetail } from 'api/mailDistribution/mailDistribution.interface'
import { validationEditDistributionListSchema } from 'pages/distributionList/editDistributionList.validation'

import { ContentHeader } from 'shared/components/contentHeader/contentHeader.component'
import Input from 'shared/components/input/input.component'
import { CircularProgress } from '@mui/material'
import { pushNotification } from 'shared/helpers/notifications/notifications'

const createNewMailOption = (name = '', email = ''): MailDistributionForm => {
  return { id: generateStringId(), name, email, emailType: 'main' }
}
const getMailDistributionList = (distList?: MailDistributionDetail[]): MailDistributionForm[] => {
  return distList?.map(d => createNewMailOption(d.name, d.email)) ?? []
}

const EditDistributionList = () => {
  const [t] = useTranslation(['letters', 'global'])
  // Navigation:
  const navigate = useNavigate()
  const navigateToDistributionList = () => navigate('/distributionList')
  // Get Params:
  const { distributionId } = useParams()

  if (!distributionId) navigateToDistributionList()

  const { isLoading, data } = useGetMailDistributionById(distributionId)
  const { mutateAsync, status } = useEditMailDistribution()

  // States:
  const [mailDistributionList, setMailDistributionList] = useState<MailDistributionForm[]>([
    createNewMailOption(),
  ])

  // Yup:
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<EditMailDistributionForm>({
    defaultValues: useMemo(() => {
      return {
        id: data?.id,
        name: data?.name,
        replyTo: data?.replyTo,
        mailDistributionDetails: getMailDistributionList(
          data?.mailDistributionDetails,
        ),
      }
    }, [data]),
    resolver: yupResolver(validationEditDistributionListSchema),
  })

  // Effects:
  useEffect(() => {
    if (data) {
      const distList = getMailDistributionList(
        data.mailDistributionDetails,
      )
      // Reset:
      reset({
        id: data?.id,
        name: data?.name,
        replyTo: data.replyTo,
        mailDistributionDetails: distList,
      })
      // Set list:
      setMailDistributionList(distList)
    }
  }, [data, reset])

  // Handlers:
  const addOptionHandler = () => {
    const extraOption = createNewMailOption()
    setMailDistributionList([...mailDistributionList, extraOption])
  }

  const removeOptionHandler = (distribution: MailDistributionForm) => {
    const positionDelete = getPosition(mailDistributionList, distribution, 'id')
    const copyDistributionList = mailDistributionList
    copyDistributionList.splice(positionDelete, 1)
    setMailDistributionList([...copyDistributionList])
  }

  // Handlers:
  const onSubmit: SubmitHandler<EditMailDistributionForm> = async data => {
    try {
      await mutateAsync(data)

      if (['success', 'idle'].includes(status)) {
        reset({})
        navigateToDistributionList()
      }
    } catch (error) {
      pushNotification((error?.response?.data?.error?.code as string) ?? t('letters:messages.error'))
    }
  }

  // Render Distribution List Options:
  const renderDistributionList = (distribution: MailDistributionForm, index: number) => {
    return (
      <div key={distribution.id} className='row'>
        {/* Name */}
        <div className='col-md-4'>
          <div className='form-group'>
            <label htmlFor={`distributionList.${index}.name`}>
              {t('letters:mail-distribution.dist-list.name')}
            </label>
            <Input
              type='text'
              name={`distributionList.${index}.name`}
              reg={register(`mailDistributionDetails.${index}.name`)}
              errors={errors}
              placeholder={t('letters:mail-distribution.dist-list.name-placeholder')}
            />
          </div>
        </div>
        {/* Email */}
        <div className='col-md-4'>
          <div className='form-group'>
            <label htmlFor={`distributionList.${index}.email`}>
              {t('letters:mail-distribution.dist-list.email')}
            </label>
            <Input
              type='text'
              name={`distributionList.${index}.email`}
              reg={register(`mailDistributionDetails.${index}.email`)}
              errors={errors}
              placeholder={t('letters:mail-distribution.dist-list.email-placeholder')}
            />
          </div>
        </div>
        {/* Remove */}
        <div className='input-group-prepend'>
          <span
            onClick={() => removeOptionHandler(distribution)}
            className='input-group-text rounded-0'
            style={{ border: 'none', backgroundColor: 'white', cursor: 'pointer' }}
            title={t('letters:mail-distribution.dist-list.delete-option')}
          >
            {/* <i className='fa fa-times-circle'></i> */}
            <i className='fa fa-times' style={{ fontSize: 24 }}></i>
          </span>
        </div>
      </div>
    )
  }

  if (isLoading || status === 'loading') return <CircularProgress />

  return (
    <>
      <ContentHeader
        title={t('letters:mail-distribution.edition-header')}
        breadCrumb={{ home: t('global:sidebar.home'), page: t('global:sidebar.mail-distributions') }}
      />

      <section className='content rounded-0'>
        <div className='container-fluid'>
          <div className='callout callout-primary'>
          <h5>{t('letters:mail-distribution.edition-description-title')}</h5>
            <p>{t('letters:mail-distribution.edition-description-body')}</p>
          </div>
        </div>
      </section>

      <section className='content rounded-0'>
        <div className='card card-primary card-outline'>

          <div className='card-header border-0'>
            <div className='d-flex justify-content-between'>
              <h3 className='card-title'>
                <strong>{data?.name}</strong>
              </h3>
              <Link to='/distributionList'>{t('global:cruds.back-button')}</Link>
            </div>
          </div>

          <div className='card-body'>
            <form
              id='new-mail-distribution-form'
              className='row'
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className='col-md-12'>
                <div className='row'>

                  <div className='col-md-12'>
                    <h4>{t('letters:mail-distribution.table-headers.reply-to')}</h4>
                  </div>

                  <div className='col-md-4'>
                    <div className='form-group'>
                      <label htmlFor='replyTo.name'>
                        {t('letters:mail-distribution.dist-list.name')}
                      </label>
                      <Input
                        type='text'
                        name='replyTo.name'
                        reg={register('replyTo.name')}
                        errors={errors}
                        placeholder={t('letters:mail-distribution.dist-list.name-placeholder')}
                      />
                    </div>
                  </div>

                  <div className='col-md-4'>
                    <div className='form-group'>
                      <label htmlFor='replyTo.email'>
                        {t('letters:mail-distribution.dist-list.email')}
                      </label>
                      <Input
                        type='text'
                        name='replyTo.email'
                        reg={register('replyTo.email')}
                        errors={errors}
                        placeholder={t('letters:mail-distribution.dist-list.email-placeholder')}
                      />
                    </div>
                  </div>

                </div>

                <div className='row'>

                  <div className='col-md-12'>
                    <h4>{t('letters:mail-distribution.table-headers.distribution-list')}</h4>
                  </div>

                  <div className='col-md-12'>
                    {mailDistributionList && mailDistributionList.map(
                      (distribution, index) => renderDistributionList(distribution, index),
                    )}
                  </div>

                </div>

                <div className='row'>
                  <div className='col'>
                    <span className='text-primary add-option' onClick={addOptionHandler}>
                      {` ${t('letters:mail-distribution.dist-list.add-option')} `}
                    </span>
                  </div>
                </div>

                <div className='row'>
                  <div className='offset-md-9 col-md-3'>
                    <div className='form-group'>
                      {/* <div className="col-3"> */}
                      <button type='submit' className='btn btn-success btn-block'>
                        {t('global:cruds.save-button')}
                      </button>
                      {/* </div> */}
                    </div>
                  </div>
                </div>

              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  )
}

EditDistributionList.propTypes = {}

export default EditDistributionList
