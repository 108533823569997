import { useApply, useFetch, usePost, useUpdate } from 'api/reactQuery'
import { ApiURL, services, subServices } from 'services/api.service'
import { EnableDisableRequest, PaginationRequest, ResponsePagination } from 'ts/interfaces/request.interfaces'
import { ScheduleResponse } from 'api/schedule/schedule.interface'
import { EditScheduleForm, NewScheduleForm } from 'ts/interfaces/app.interfaces'

const { URL_API } = ApiURL
const { LETTER } = services
const { GET_SCHEDULE, GET_SCHEDULE_LIST, CREATE_SCHEDULE, EDIT_SCHEDULE, ENABLE_SCHEDULE } = subServices

export const useGetScheduleList = (pagination?: PaginationRequest) =>
  useFetch<ResponsePagination>(`${URL_API}/${LETTER}/${GET_SCHEDULE_LIST}`, pagination)

export const useGetScheduleById = (id: string) => useFetch<ScheduleResponse>(`${URL_API}/${LETTER}/${GET_SCHEDULE}`, { id })

export const useCreateSchedule = () => usePost<NewScheduleForm, NewScheduleForm>(`${URL_API}/${LETTER}/${CREATE_SCHEDULE}`)

export const useEditSchedule = () => useUpdate<EditScheduleForm, EditScheduleForm>(`${URL_API}/${LETTER}/${EDIT_SCHEDULE}`)

export const useEnableSchedule = () => useApply<EnableDisableRequest, EnableDisableRequest>(`${URL_API}/${LETTER}/${ENABLE_SCHEDULE}`)
