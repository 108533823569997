import { useState } from 'react'
import { Scope } from 'api/scope/scope.interface'
import { scopeDictionary } from 'ts/enums/app.enums'

export const ScopeFilter = ({ scopeData, onActiveFilters }) => {
  const [activeFilters, setActiveFilters] = useState([])

  const activeScopeFilter = (scope: Scope) => {
    setActiveFilters((prevState) => {
      const activeScopeFilters = prevState

      if (activeScopeFilters.includes(scope.name)) {
        onActiveFilters(activeScopeFilters.filter((filter) => filter !== scope.name))

        return activeScopeFilters.filter((filter) => filter !== scope.name)
      }
      onActiveFilters([...activeScopeFilters, scope.name])

      return [...activeScopeFilters, scope.name]
    })
  }

  return (
    <div className='row'>
      <div className='col-md-6'>
        <div className='form-group'>
          <div className='row'>
            <div className='col-md-12'>
              <label htmlFor='roleId'>Alcances:</label>
            </div>
          </div>
          <div className='row'>
            <div className='col-md-12'>
              {scopeData.map((scope) => (
                <button
                  className={`btn btn${activeFilters.includes(scope.name) ? '' : '-outline'}-${scopeDictionary[scope.name].color} ml-3`}
                  onClick={() => activeScopeFilter(scope)}
                >
                  {scope.name}
                </button>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
