import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { GridColDef } from '@mui/x-data-grid'
import { ContentHeader } from 'shared/components/contentHeader/contentHeader.component'
import DataTable from 'shared/components/dataTable/dataTable.component'
import { getProductList } from 'redux/products/products.actions'
import { RootState, ProductRedux } from 'redux/interfaces'
import CircularProgress from '@material-ui/core/CircularProgress'
import { currencyFormatter } from 'shared/helpers'

const Products = () => {
  const dispatch = useDispatch()
  const [t] = useTranslation('global')
  const productData = useSelector((state: RootState) => state?.product as ProductRedux)
  const { loading, data } = productData

  const getAllProducts = async () => {
    // dispatch(await getProductList())
  }

  useEffect(() => {
    getAllProducts()
  }, [])

  const breadCrumb = {
    home: 'Home',
    page: t('sidebar.payment_products'),
  }

  const columns: GridColDef[] = [
    { field: 'name', headerName: 'Product', width: 300 },
    {
      field: 'price',
      headerName: 'Price',
      flex: 1,
      valueFormatter: ({ value }) => currencyFormatter.format(value),
      cellClassName: 'font-tabular-nums',
    },
    { field: 'active', headerName: 'Active', flex: 1 },
  ]
  if (loading) {
    return <div className='loading-circular'><CircularProgress /></div>
  }
  return (
    <>
      <ContentHeader title={t('sidebar.payment_products')} breadCrumb={breadCrumb} />

      <section className='content'>
        <div className='container-fluid'>
          <div className='card card-primary card-outline'>
            <div className='card-header border-0'>
              <div className='d-flex justify-content-between'>
                <h3 className='card-title'>List of Products (Stripe)</h3>
              </div>
            </div>

            <div className='card-body'>
              <DataTable
                data={data ?? []}
                columns={columns}
                // getRowId={(row) => row.id}
                // pageSize={pagination.pageSize}
                // pageNumber={pagination.pageNumber}
                totalRows={data?.length ?? 0}
                // sortBy={pagination.sortBy}
                // sortOrder={pagination.sortOrder}
                // serverMode
                // onPaginationChangeHandler={onPaginationChangeHandler}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Products
