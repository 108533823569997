import { useApply, useFetch, usePost, useUpdate } from 'api/reactQuery'
import { ApiURL, services, subServices } from 'services/api.service'
import { CreateLetterRequest, EditLetterRequest, EnableDisableRequest, PaginationRequest, ResponsePagination } from 'ts/interfaces/request.interfaces'
import { LetterResponse } from 'api/letter/letter.interface'

const { URL_API } = ApiURL
const { LETTER } = services
const { GET_LETTER, GET_LETTER_LIST, CREATE_LETTER, EDIT_LETTER, ENABLE_LETTER } = subServices

export const useGetLetterList = (pagination?: PaginationRequest) =>
  useFetch<ResponsePagination>(`${URL_API}/${LETTER}/${GET_LETTER_LIST}`, pagination)

export const useGetLetterById = (id: string) => useFetch<LetterResponse>(`${URL_API}/${LETTER}/${GET_LETTER}`, { id })

export const useCreateLetter = () => usePost<CreateLetterRequest, CreateLetterRequest>(`${URL_API}/${LETTER}/${CREATE_LETTER}`)

export const useEditLetter = () => useUpdate<EditLetterRequest, EditLetterRequest>(`${URL_API}/${LETTER}/${EDIT_LETTER}`)

export const useEnableLetter = () => useApply<EnableDisableRequest, EnableDisableRequest>(`${URL_API}/${LETTER}/${ENABLE_LETTER}`)
