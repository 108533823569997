import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useEnableMailDistribution, useGetMailDistributionList } from 'api/mailDistribution/mailDistribution.api'
import { PaginationRequest } from 'ts/interfaces/request.interfaces'
import { Link } from 'react-router-dom'
import { pushNotification } from 'shared/helpers/notifications/notifications'

import { ContentHeader } from 'shared/components/contentHeader/contentHeader.component'
import DataTable from 'shared/components/dataTable/dataTable.component'
import { GridColDef } from '@mui/x-data-grid'
import Switch from '@mui/material/Switch'
import EditIcon from '@mui/icons-material/Edit'
import { CircularProgress } from '@mui/material'

const DistributionList = () => {
  const [t] = useTranslation(['letters', 'global'])

  const [pagination, setPagination] = useState<PaginationRequest>({
    pageNumber: 1,
    pageSize: 10,
  })

  const { data, status, refetch } = useGetMailDistributionList(pagination)
  const { mutateAsync, status: statusActive } = useEnableMailDistribution()

  // Handlers:
  const onMailDistributionActiveChangeHandler = async (value: boolean, distributionId: string) => {
    try {
      await mutateAsync({ id: distributionId, isActive: value })

      if (['success', 'idle'].includes(statusActive)) {
        pushNotification(value ? t('letters:messages.activated') : t('letters:messages.deactivated'), 'success')
        await refetch()
      }
    } catch (error) {
      pushNotification((error?.response?.data?.error?.code as string) ?? t('letters:messages.error'))
    }
  }

  const onPaginationChangeHandler = (pageSize: number, pageNumber: number, sortBy: string, sortOrder: 'asc' | 'desc') => {
    setPagination({ pageSize, pageNumber, sortBy, sortOrder })
  }

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: t('letters:mail-distribution.table-headers.mail-distribution'),
      type: 'string',
      width: 300,
    }, {
      field: 'distributionList',
      headerName: t('letters:mail-distribution.table-headers.distribution-list'),
      type: 'string',
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
    }, {
      field: 'replyTo',
      headerName: t('letters:mail-distribution.table-headers.reply-to'),
      type: 'string',
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
    }, {
      field: 'editDistribution',
      headerName: t('global:datatable.edit-col'),
      width: 100,
      sortable: false,
      disableColumnMenu: true,
      headerAlign: 'center',
      align: 'center',
      renderCell: params => {
        const distributionId: string = params.row.id
        return (
          <Link to={`/distributionList/edit/${distributionId}`}>
            <EditIcon />
          </Link>
        )
      },
    }, {
      field: 'activeDistribution',
      headerName: t('global:datatable.active-col'),
      width: 100,
      sortable: false,
      disableColumnMenu: true,
      headerAlign: 'center',
      align: 'center',
      renderCell: params => {
        const distributionId: string = params.row.id
        const isActive: boolean = params.row.isActive
        return (
          <Switch
            checked={isActive}
            onChange={(_, value) => onMailDistributionActiveChangeHandler(value, distributionId)}
          />
        )
      },
    },
  ]

  if (status === 'loading') return <CircularProgress />

  return (
    <>
      <ContentHeader
        title={t('global:sidebar.mail-distributions')}
        breadCrumb={{ home: t('global:sidebar.home'), page: t('global:sidebar.mail-distributions') }}
      />

      <section className='content'>
        <div className='container-fluid'>
          <div className='card card-primary card-outline'>

            <div className='card-header border-0'>
              <div className='d-flex justify-content-between'>
                <h3 className='card-title'>{t('letters:mail-distribution.header')}</h3>
                <Link to='/distributionList/new'>{t('letters:mail-distribution.new-distribution-button')}</Link>
              </div>
            </div>

            <div className='card-body'>
              <DataTable
                data={data?.data}
                columns={columns}
                getRowId={row => row.id}
                pageSize={pagination.pageSize}
                pageNumber={pagination.pageNumber}
                totalRows={data?.totalEntitiesCount}
                sortBy={pagination.sortBy}
                sortOrder={pagination.sortOrder}
                serverMode
                onPaginationChangeHandler={onPaginationChangeHandler}
              />
            </div>

          </div>
        </div>
      </section>
    </>
  )
}

DistributionList.propTypes = {}

export default DistributionList
