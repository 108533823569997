/* eslint-disable prettier/prettier */
/* eslint-disable import/prefer-default-export */
import get from 'lodash/get'
import { getReduxIdSuffix } from 'shared/helpers/redux'

const getKeysStartingWith = (prefix, data) => {
  let slicedObj = {}

  try {
    slicedObj = Object.keys(data).filter((k) => {
      return k.indexOf(prefix) === 0
    })
      .reduce((newData, k) => {
        // eslint-disable-next-line prefer-destructuring
        newData[k] = data[k]

        return newData
      }, {})
  } catch (error) {

    console.log('error', error)
  }

  return slicedObj
}


const createLoadingSelector =
  (action, id = '') => (state) => {
    if (id === 'all') {
      return getKeysStartingWith(action, state?.loadingReducer)
    }


    const idSuffix = getReduxIdSuffix(id)

    return get(state, `loadingReducer.${action}${idSuffix}`)
  }

const createErrorSelector = (action, id = '') => (state) => {
  if (id === 'all') {
    return getKeysStartingWith(action, state?.errorReducer)
  }

  const idSuffix = getReduxIdSuffix(id)

  
return get(state, `errorReducer.${action}${idSuffix}`)
}

export const createLoadErrorSelector = (action, id =  '') => {
  return [createLoadingSelector(action, id), createErrorSelector(action, id)]
}
