import * as Yup from 'yup'
import { t } from 'i18next'

const validationNewLetterCompositionSchema = Yup.object().shape({
  name: Yup.string()
    .required(t('letters:yup.letter-composition.required'))
    .min(1, t('letters:yup.letter-composition.min'))
    .max(100, t('letters:yup.letter-composition.max')),
  letterId: Yup.string()
    .required(t('letters:yup.letter-id-required')),
  mailDistributionId: Yup.string()
    .required(t('letters:yup.mail-dist-id-required')),
  scheduleId: Yup.string()
    .required(t('letters:yup.schedule-id-required')),
})

export { validationNewLetterCompositionSchema as validationNewLetterCompositionSchema }

export default {}
