/* eslint-disable import/prefer-default-export */
import { getReduxIdSuffix } from 'shared/helpers/redux'

export const cleanEntityError = (type, id = '') => {
  const idSuffix = getReduxIdSuffix(id)

  console.log('$type}_CLEAN$idSuffix', `${type}_CLEAN${idSuffix}`)
  return {
    type: `${type}_CLEAN${idSuffix}`,
  }
}
