import { useApply, useFetch, usePost } from 'api/reactQuery'
import { ApiURL, services, subServices } from 'services/api.service'
import { EnableDisableRequest, PaginationRequest, ResponsePagination } from 'ts/interfaces/request.interfaces'
import { setPaginationToEndpoint } from 'shared/helpers/pagination'
import type { EvaluatorCategory, UpdateEvaluatorCategory } from './evaluatorCategory.interface'
import { useUpdate } from '../reactQuery'

const { URL_API } = ApiURL
const { THREE_SIXTY } = services
const {
  CREATE_EVALUATOR_CATEGORY,
  GET_EVALUATOR_CATEGORY_LIST,
  GET_EVALUATOR_CATEGORY,
  ENABLE_EVALUATOR_CATEGORY,
  EDIT_EVALUATOR_CATEGORY,
  GET_EVALUATOR_CATEGORY_ACTIVE_LIST,
} = subServices

export const useCreateEvaluatorCategory = () =>
  usePost<EvaluatorCategory, EvaluatorCategory>(`${URL_API}/${THREE_SIXTY}/${CREATE_EVALUATOR_CATEGORY}`, { id: 1 })

export const useGetEvaluatorCategoryList = (pagination?: PaginationRequest) =>
  useFetch<ResponsePagination>(setPaginationToEndpoint(`${URL_API}/${THREE_SIXTY}/${GET_EVALUATOR_CATEGORY_LIST}`, pagination), { id: 1 })

export const useEnableEvaluatorCategory = () =>
  useApply<EnableDisableRequest, EnableDisableRequest>(`${URL_API}/${THREE_SIXTY}/${ENABLE_EVALUATOR_CATEGORY}`)

export const useGetEvaluatorCategory = (id: string) =>
  useFetch<EvaluatorCategory>(`${URL_API}/${THREE_SIXTY}/${GET_EVALUATOR_CATEGORY}`, { id })

export const useUpdateEvaluatorCategory = () =>
  useUpdate<UpdateEvaluatorCategory, UpdateEvaluatorCategory>(`${URL_API}/${THREE_SIXTY}/${EDIT_EVALUATOR_CATEGORY}`, { id: 1 })

export const useGetEvaluatorCategoryActiveList = () =>
  useFetch<EvaluatorCategory[]>(`${URL_API}/${THREE_SIXTY}/${GET_EVALUATOR_CATEGORY_ACTIVE_LIST}`, { id: 1 })
