import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { SubmitHandler, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Link, useNavigate } from 'react-router-dom'
import {
  useCreateLetterSchedule,
  useGetLetterActiveList,
  useGetMailDistributionActiveList,
  useGetScheduleActiveList,
} from 'api/letterSchedule/letterSchedule.api'
import { validationNewLetterCompositionSchema } from 'pages/letterComposition/newLetterComposition.validation'
import { NewLetterScheduleForm, SelectOptionI } from 'ts/interfaces/app.interfaces'
import { pushNotification } from 'shared/helpers/notifications/notifications'

import SelectAutocomplete from 'shared/components/selectInput/selectAutocomplete.component'
import { ContentHeader } from 'shared/components/contentHeader/contentHeader.component'
import Input from 'shared/components/input/input.component'
import { CircularProgress } from '@mui/material'

const NewLetterComposition = () => {
  const [t] = useTranslation(['letters', 'global'])
  // API
  const { mutateAsync: createLetterAsync, status: creationStatus } = useCreateLetterSchedule()
  const { isLoading: isLoadingLetter, data: letterData, status: letterDataStatus } = useGetLetterActiveList()
  const { isLoading: isLoadingDistribution, data: distributionData, status: distributionDataStatus } = useGetMailDistributionActiveList()
  const { isLoading: isLoadingSchedule, data: scheduleData, status: scheduleStatus } = useGetScheduleActiveList()

  const navigate = useNavigate()

  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
    setValue,
  } = useForm<NewLetterScheduleForm>({
    resolver: yupResolver(validationNewLetterCompositionSchema),
  })
  const letterName = watch('name', t('letters:letter-composition.creation-default-name') as string)

  // States:
  const [letterOptions, setLetterOptions] = useState<SelectOptionI[]>([])
  const [mailDistributionOptions, setMailDistributionOptions] = useState<SelectOptionI[]>([])
  const [scheduleOptions, setScheduleOptions] = useState<SelectOptionI[]>([])

  // Effects:
  useEffect(() => {
    if (letterData) {
      setLetterOptions(
        letterData.map((letter) => {
          return { value: letter.id, label: letter.name }
        }),
      )
    }
  }, [letterData, letterDataStatus])
  useEffect(() => {
    if (distributionData) {
      setMailDistributionOptions(
        distributionData.map((distribution) => {
          return { value: distribution.id, label: distribution.name }
        }),
      )
    }
  }, [distributionData, distributionDataStatus])
  useEffect(() => {
    if (scheduleData) {
      setScheduleOptions(
        scheduleData.map((schedule) => {
          return { value: schedule.id, label: schedule.name }
        }),
      )
    }
  }, [scheduleData, scheduleStatus])

  // Handlers:
  const onSubmit: SubmitHandler<NewLetterScheduleForm> = async (data) => {
    try {
      await createLetterAsync(data)

      if (['success', 'idle'].includes(creationStatus)) {
        reset({})
        navigate('/letterComposition')
      }
    } catch (error) {
      pushNotification((error?.response?.data?.error?.code as string) ?? t('letters:messages.error'))
    }
  }

  if (isLoadingLetter || isLoadingSchedule || isLoadingDistribution) {
    return <CircularProgress />
  }

  return (
    <>
      <ContentHeader
        title={t('letters:letter-composition.creation-header')}
        breadCrumb={{ home: t('global:sidebar.home'), page: t('global:sidebar.letter-compositions') }}
      />

      <section className='content rounded-0'>
        <div className='container-fluid'>
          <div className='callout callout-primary'>
            <h5>{t('letters:letter-composition.creation-description-title')}</h5>
            <p>{t('letters:letter-composition.creation-description-body')}</p>
          </div>
        </div>
      </section>

      <section className='content rounded-0'>
        <div className='container-fluid'>
          <div className='card card-primary card-outline'>
            <div className='card-header border-0'>
              <div className='d-flex justify-content-between'>
                <h3 className='card-title'>
                  <strong>{letterName}</strong>
                </h3>
                <Link to='/letterComposition'>{t('global:cruds.back-button')}</Link>
              </div>
            </div>
            <div className='card-body'>
              <form id='new-letter-form' className='row' onSubmit={handleSubmit(onSubmit)}>
                <div className='col-md-12'>
                  <div className='row'>
                    <div className='col-md-4'>
                      <div className='form-group'>
                        <label htmlFor='letter'>{t('letters:letter-composition.table-headers.letter-composition')}</label>
                        <Input
                          id='letter-composition-name'
                          type='text'
                          name='name'
                          reg={register('name')}
                          errors={errors}
                          placeholder={t('letters:letter-composition.table-headers.letter-composition')}
                        />
                      </div>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-md-4'>
                      <div className='form-group'>
                        <label htmlFor='letter-selector'>{t('letters:letter-composition.table-headers.letter')}</label>
                        <SelectAutocomplete
                          id='letter-selector'
                          name='letterId'
                          elements={letterOptions}
                          errors={errors}
                          setFieldValue={(value) => setValue('letterId', value)}
                          placeholder={t('letters:letter-composition.table-headers.letter-placeholder')}
                        />
                      </div>
                    </div>

                    <div className='col-md-4'>
                      <div className='form-group'>
                        <label htmlFor='schedule-selector'>{t('letters:letter-composition.table-headers.schedule')}</label>
                        <SelectAutocomplete
                          id='schedule-selector'
                          name='scheduleId'
                          elements={scheduleOptions}
                          errors={errors}
                          setFieldValue={(value) => setValue('scheduleId', value)}
                          placeholder={t('letters:letter-composition.table-headers.schedule-placeholder')}
                        />
                      </div>
                    </div>

                    <div className='col-md-4'>
                      <div className='form-group'>
                        <label htmlFor='mail-dist-selector'>{t('letters:letter-composition.table-headers.mail-distribution')}</label>
                        <SelectAutocomplete
                          id='mail-distribution-selector'
                          name='mailDistributionId'
                          elements={mailDistributionOptions}
                          errors={errors}
                          setFieldValue={(value) => setValue('mailDistributionId', value)}
                          placeholder={t('letters:letter-composition.table-headers.mail-distribution-placeholder')}
                        />
                      </div>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='offset-md-9 col-md-3'>
                      <div className='form-group'>
                        {/* <div className="col-3"> */}
                        <button type='submit' className='btn btn-success btn-block'>
                          {t('global:cruds.save-button')}
                        </button>
                        {/* </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default NewLetterComposition
