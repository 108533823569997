import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect, useMemo, useState } from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Link, useParams } from 'react-router-dom'
import { getValueByObjectKey } from 'shared/helpers'
import { QuestionSettingsForm } from 'redux/interfaces'
import { ContentHeader } from 'shared/components/contentHeader/contentHeader.component'
import DynamicQuestionComponent from 'shared/components/dynamicQuestion/dynamicQuestion.component'
import Input from 'shared/components/input/input.component'
import { questionSchema } from 'shared/components/questionSettings/questionSettings.validation'
import { useGetQuestionByLevel, usePostQuestion, useUpdateAnswer, useUpdateQuestion } from 'api/question/question.api'
import { usePostAnswer } from 'api/answer/answer.api'
import { pushNotification } from 'shared/helpers/notifications/notifications'

const defaultQuestionFormValues = {
  questionType: '',
  answerType: '0',
}

export const NewQuestion = () => {
  const { levelId, questionId } = useParams()
  const [t] = useTranslation(['threeSixty', 'global'])

  console.log('questionId', questionId)

  const [answerType, setAnswerType] = useState('0')
  const [multipleAnswers, setMultipleAnswers] = useState<any>([
    {
      option: 1,
      value: 'Option 1',
      points: 0,
      answer: '',
      extraOptions: null,
    },
  ])

  const { mutateAsync, status } = usePostQuestion()
  const { mutateAsync: updateAnswerAsync, status: statusUpdateAnswer } = useUpdateAnswer()
  const { mutateAsync: updateQuestionAsync, status: updateQuestionStatus } = useUpdateQuestion()
  const { mutateAsync: mutateAnswer, status: statusAnswer } = usePostAnswer()
  const { data: dataQuestion, status: statusQuestion } = useGetQuestionByLevel(levelId)

  const methods = useForm<QuestionSettingsForm>({
    defaultValues: useMemo(() => {
      return defaultQuestionFormValues
    }, []),
    resolver: yupResolver(questionSchema),
  })

  console.log('multipleAnswers', multipleAnswers)

  useEffect(() => {
    if (questionId && dataQuestion) {
      const questionEdit = dataQuestion.filter((question) => question.id === questionId)?.[0]

      if (questionEdit) {
        methods.setValue('questionConfig', questionEdit.question)
        methods.setValue('answerType', questionEdit.questionType)
        setAnswerType(questionEdit.questionType)

        if (parseInt(questionEdit.questionType, 10) > 2) {
          const multipleResponses = questionEdit.answerViewModels.map((answer) => ({
            id: answer.id,
            option: '',
            value: answer.answer,
            points: answer.pointAnswer,
            answerConfig: '',
          }))

          setMultipleAnswers(multipleResponses)
        } else {
          methods.setValue('answerConfig', questionEdit.answerViewModels)
        }
      }
    }
  }, [questionId, dataQuestion, methods])

  const handleAnswerType = (e: any) => {
    setAnswerType(e.target.value)
  }

  const shortAnswerType = () => {
    return (
      <Input
        type='text'
        name='answerConfig'
        reg={methods.register('answerConfig')}
        errors={methods.formState.errors}
        placeholder={t('threeSixty:level.question.fields.short-text-placeholder')}
      />
    )
  }

  const longAnswerType = () => {
    return (
      <textarea
        style={{ width: '100%' }}
        rows={3}
        {...methods.register('answerConfig')}
        placeholder={t('threeSixty:level.question.fields.long-text-placeholder')}
      />
    )
  }

  const getAnswerValue = ({ answerType, answerConfig }: any) => {
    switch (parseInt(answerType, 10)) {
      case 1:

      case 2:
        return answerConfig

      case 3:

      case 4:
        return multipleAnswers

      default:
        return answerConfig
    }
  }

  // const createFormQuestion = (data: any) => {
  //   return {
  //     id: undefined,
  //     answerConfig: {
  //       answerType: 'Option', // data.answerType,
  //       answerValue: getAnswerValue(data),
  //     },
  //     // question: data.questionConfig
  //   }
  // }

  const resetQuestion = () => {
    setAnswerType('0')
    methods.reset()
    setMultipleAnswers([
      {
        option: 0,
        value: 'Option 1',
        answer: false,
        points: 0,
        extraOptions: '',
      },
    ])
  }

  const formatAnswerSave = (multipleAnswersObj, questionIdData) =>
    multipleAnswersObj?.map((answer) => ({
      idQuestion: questionIdData,
      answer: answer.value,
      points: answer.points,
      order: answer.option,
    }))

  const updateAnswers = async (questionId, answerType, answerConfig) => {
    multipleAnswers.forEach((answer, i) => {
      console.log('answer', answer)
      console.log('i', i)

      if (answer.id) {
        updateAnswerAsync({
          answerId: answer.id,
          answer: answer.value,
          order: answer.option === "" ? i : answer.option,
          points: answer.points,
        })
      }

      // if (!answer.id) {
      //   saveAnswers(questionId, answerType, answerConfig)
      // }
    })
  }

  const saveAnswers = async (questionId, answerType, answerConfig) => {
    console.log('---')

    const answerToSave = getAnswerValue({ answerType: answerType, answerConfig })

    console.log('answerToSave', answerToSave)

    if (answerType > 2) {
      const formatAnswer = formatAnswerSave(answerToSave, questionId)

      if (!formatAnswer.length) return

      await mutateAnswer({
        answerQuestions: formatAnswer,
      })
    } else {
      await mutateAnswer({
        answerQuestions: [
          {
            idQuestion: questionId,
            answer: answerToSave,
            points: '0',
          },
        ],
      })
    }

    try {
      if (['success', 'idle'].includes(status)) {
        pushNotification(t('messages.created'), 'success')
      } else {
        pushNotification(t('messages.error'), 'error')
      }
    } catch (error) {
      pushNotification((error?.response?.data?.error?.code as string) ?? t('messages.error'), 'error')
    }
  }

  const onSubmit: SubmitHandler<any> = async (data) => {
    console.log('data---==', data)
    console.log('questionId', questionId)

    if (questionId) {
      await updateQuestionAsync({
        id: questionId,
        question: data.questionConfig,
        questionType: data.answerType,
      })

      await updateAnswers(questionId, data.answerType, data.answerConfig)

      try {
        if (['success', 'idle'].includes(updateQuestionStatus)) {
          pushNotification(t('messages.created'), 'success')
        } else {
          pushNotification(t('messages.error'), 'error')
        }
      } catch (error) {
        pushNotification((error?.response?.data?.error?.code as string) ?? t('messages.error'), 'error')
      }

      if (statusAnswer) {
        pushNotification('Question Updated', 'success')
      }

      return
    }

    const questionIdToSave = await mutateAsync({
      levelId,
      question: data.questionConfig,
      questionType: data.answerType,
    })

    await saveAnswers(questionIdToSave.data, data.answerType, data.answerConfig)

    // if (questionIdToSave.data) {
    //   const answerToSave = getAnswerValue({ answerType: data.answerType, answerConfig: data.answerConfig })

    //   if (data.answerType > 2) {
    //     const formatAnswer = formatAnswerSave(answerToSave, questionIdToSave.data)

    //     if (!formatAnswer.length) return

    //     await mutateAnswer({
    //       answerQuestions: formatAnswer,
    //     })
    //   } else {
    //     await mutateAnswer({
    //       answerQuestions: [
    //         {
    //           idQuestion: questionIdToSave.data,
    //           answer: answerToSave,
    //           points: '0',
    //         },
    //       ],
    //     })
    //   }

    //   try {
    //     if (['success', 'idle'].includes(status)) {
    //       pushNotification(t('messages.created'), 'success')
    //     } else {
    //       pushNotification(t('messages.error'), 'error')
    //     }
    //   } catch (error) {
    //     pushNotification((error?.response?.data?.error?.code as string) ?? t('messages.error'), 'error')
    //   }
    // }

    if (statusAnswer) {
      pushNotification('Question Created', 'success')
      methods.reset()
    }
    // await mutateAnswer()
    resetQuestion()
  }

  const renderAnswer = () => {
    switch (parseInt(answerType)) {
      case 1:
        return shortAnswerType()
      case 2:
        return longAnswerType()
      case 3:
        return <DynamicQuestionComponent optionQuestions={multipleAnswers} setOptionQuestions={setMultipleAnswers} typeOptionQuestion='multiple' />
      case 4:
        return <DynamicQuestionComponent optionQuestions={multipleAnswers} setOptionQuestions={setMultipleAnswers} typeOptionQuestion='single' />
      default:
        return <p>{t('threeSixty:level.question.fields.no-selected')}</p>
    }
  }

  return (
    <>
      <ContentHeader
        title={t('threeSixty:level.question.creation-header')}
        breadCrumb={{ home: t('global:sidebar.home'), page: t('global:sidebar.new-question') }}
      />

      <section className='content'>
        <div className='container-fluid'>
          <div className='card card-primary card-outline'>
            <div className='card-header border-0'>
              <div className='d-flex justify-content-between'>
                <h3 className='card-title'>{t('threeSixty:level.question.creation-description-title')}</h3>
                <Link to={`/level/questions/${levelId}`}>{t('global:cruds.back-button')}</Link>
              </div>
            </div>

            <div className='card-body'>
              <form onSubmit={methods.handleSubmit(onSubmit)}>
                <div className='col-md-12'>
                  <div className='row'>
                    <div className='col-md-12'>
                      <div className='form-group'>
                        <label htmlFor='questionConfig'>{t('threeSixty:level.question.fields.write-a-question')}</label>
                        <Input
                          type='text'
                          name='questionConfig'
                          reg={methods.register('questionConfig')}
                          errors={methods.formState.errors}
                          placeholder={t('threeSixty:level.question.fields.write-a-question-placeholder')}
                        />
                      </div>
                    </div>

                    <div className='col-md-12'>
                      <div className='form-group'>
                        <label htmlFor='answerType'>{t('threeSixty:level.question.fields.answer-type')}</label>
                        {/* Answer Type Options */}
                        <select
                          className={`form-control rounded-0 ${getValueByObjectKey(methods.formState.errors, 'answerType') ? 'is-invalid' : ''}`}
                          {...methods.register('answerType')}
                          onChange={handleAnswerType}
                          defaultValue={answerType}
                        >
                          <option value='0'>{t('threeSixty:level.question.fields.no-selected')}</option>
                          <option value='1'>{t('threeSixty:level.question.fields.short-text')}</option>
                          <option value='2'>{t('threeSixty:level.question.fields.long-text')}</option>
                          <option value='3'>{t('threeSixty:level.question.fields.multiple-option')}</option>
                          <option value='4'>{t('threeSixty:level.question.fields.single-option')}</option>
                        </select>
                        <div className='invalid-feedback'>{getValueByObjectKey(methods.formState.errors, 'answerType')?.message}</div>
                      </div>
                      {/* Answer Rendering */}
                      <div className='form-group'>
                        <label>{t('threeSixty:level.question.fields.answer')}</label>
                        {renderAnswer()}
                      </div>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='offset-md-9 col-md-3'>
                      <div className='form-group'>
                        <button type='submit' className='btn btn-success btn-block'>
                          {t('global:cruds.save-button')}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default NewQuestion
