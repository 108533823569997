import React, { memo } from 'react'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import PropTypes from 'prop-types'
import { getPosition } from 'shared/helpers'
import './dynamicQuestion.style.scss'

interface OptionQuestions {
  option: number
  value: string
  answer: boolean
  points: number | null
  extraOptions: string | null
}

interface DynamicQuestion {
  optionQuestions: OptionQuestions[]
  setOptionQuestions: React.Dispatch<React.SetStateAction<OptionQuestions[]>>
  typeOptionQuestion?: string
  extraOptions?: boolean
  correctAnswer?: boolean
}

interface OptionsTypeQuestion {
  type: string
}

interface TypeQuestion {
  [key: string]: OptionsTypeQuestion
}

const typeQuestion: TypeQuestion = {
  multiple: {
    type: 'checkbox',
  },
  single: {
    type: 'radio',
  },
  list: {
    type: 'text',
  },
}

const DynamicQuestion = ({
  optionQuestions,
  setOptionQuestions,
  typeOptionQuestion = 'list',
  correctAnswer = false,
  extraOptions = false,
}: DynamicQuestion) => {
  const handleRemoveCheckBox = (option: any) => {
    const positionDelete = getPosition(optionQuestions, option, 'option')
    const copyOptionsCheck = optionQuestions
    copyOptionsCheck.splice(positionDelete, 1)
    setOptionQuestions([...copyOptionsCheck])
  }

  const addOption = () => {
    const position = optionQuestions.length - 1
    console.log('position', position)
    console.log('optionQuestions', optionQuestions)
    console.log('optionQuestions[position]', optionQuestions[position])
    const extraOption: OptionQuestions = {
      option: optionQuestions[position].option === 0 ? 1 : optionQuestions[position].option + 1,
      value: `Option ${optionQuestions.length + 1}`,
      answer: false,
      points: 0,
      extraOptions: null,
    }

    setOptionQuestions((prevState) => [...prevState, extraOption])
  }

  const handleQuestionValue = (e: any, option: any) => {
    const positionEdit = getPosition(optionQuestions, option, 'option')
    const editOptionCheckBox = optionQuestions[positionEdit]
    editOptionCheckBox.value = (e.target as HTMLInputElement).value
    optionQuestions[positionEdit] = editOptionCheckBox
    setOptionQuestions(optionQuestions)
  }

  const handleQuestionPoints = (e: any, option: any) => {
    const positionEdit = getPosition(optionQuestions, option, 'option')
    const editOptionCheckBox = optionQuestions[positionEdit]
    editOptionCheckBox.points = parseInt(e.target.value) ?? 0
    optionQuestions[positionEdit] = editOptionCheckBox
    setOptionQuestions(optionQuestions)
  }

  const markResponse = (e: any, option: any) => {
    const positionEdit = getPosition(optionQuestions, option, 'option')
    const editOptionCheckBox = optionQuestions[positionEdit]
    editOptionCheckBox.answer = e.target.checked
    optionQuestions[positionEdit] = editOptionCheckBox
    setOptionQuestions(optionQuestions)
    console.log('optionQuestions', optionQuestions)
  }

  const changeAllFalse = () => {
    optionQuestions.forEach((option: any) => {
      option.answer = false
    })

    return optionQuestions
  }

  const markResponseRadio = (e: any, option: any) => {
    const optionsRadioFalse = changeAllFalse()
    const positionEdit = getPosition(optionsRadioFalse, option, 'option')
    const editOptionCheckBox = optionsRadioFalse[positionEdit]

    editOptionCheckBox.answer = e.target.checked
    optionsRadioFalse[positionEdit] = editOptionCheckBox
    setOptionQuestions(optionsRadioFalse)
    console.log('optionQuestions', optionQuestions)
  }

  const handleDrop = (droppedItem: any) => {
    if (!droppedItem.destination) {
      return
    }
    const updatedList = [...optionQuestions]
    const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1)
    updatedList.splice(droppedItem.destination.index, 0, reorderedItem)
    setOptionQuestions(updatedList)
  }

  const inputType = () => {
    if (typeOptionQuestion === 'list') {
      return optionQuestions.length + 1
    }
    return <input type={typeQuestion[typeOptionQuestion].type} disabled />
  }

  const inputTypeHeader = (option: number) => {
    if (typeOptionQuestion === 'list') {
      return option + 1
    }
    return <input type={typeQuestion[typeOptionQuestion].type} disabled />
  }

  const typeResponse = (options: OptionQuestions) => {
    if (typeOptionQuestion === '360') {
      return <input type='radio' name='radio-options' className='custom-checkbox' onChange={(e) => markResponseRadio(e, options.option)} />
    }
    return <input type='checkbox' className='custom-checkbox' onChange={(e) => markResponse(e, options.option)} />
  }

  const questionInput = (options: any, index: number) => {
    return (
      <Draggable draggableId={String(options.value)} key={options.value} index={index}>
        {(provided) => (
          <div {...provided.dragHandleProps} {...provided.draggableProps} key={options.option} ref={provided.innerRef} className='row'>
            <div className='col-md-4'>
              <div className='form-group'>
                <div className='input-group'>
                  <div className='input-group-prepend item-container'>
                    <span className='input-group-text rounded-0'>{inputTypeHeader(options.option)}</span>
                  </div>
                  <input type='text' className='form-control' onChange={(e) => handleQuestionValue(e, options.option)} defaultValue={options.value} />
                  {optionQuestions.length > 1 && (
                    <div className='input-group-prepend'>
                      <span
                        onClick={() => handleRemoveCheckBox(options.option)}
                        className='input-group-text rounded-0'
                        style={{ border: 'none', backgroundColor: '#f7f7f7' }}
                        title='Delete Option'
                      >
                        <i className='fa fa-times-circle'></i>
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
            {correctAnswer && (
              <div className='col-md-2 d-flex justify-content-center'>
                <span
                  className='input-group-text rounded-0'
                  style={{ border: 'none', backgroundColor: '#f7f7f7', cursor: 'pointer' }}
                  title='Mark as Response'
                >
                  {typeResponse(options)}
                </span>
              </div>
            )}
            <div className='col-md-1 d-flex justify-content-center'>
              <div className='form-group'>
                <input
                  type='number'
                  className='form-control rounded-0'
                  placeholder='1'
                  onChange={(e) => handleQuestionPoints(e, options.option)}
                  defaultValue={options.points}
                />
              </div>
            </div>
            {extraOptions && (
              <div className='col-md-2'>
                <div className='input-group-prepend'>
                  <select className='form-control rounded-0' name='actions' id='actions'>
                    <option value=''>Select</option>
                    <option value=''>Submit</option>
                    <option value=''>Go to next section</option>
                    <option value=''>Go to specific section</option>
                  </select>
                </div>
              </div>
            )}
          </div>
        )}
      </Draggable>
    )
  }

  return (
    <>
      <div className='row'>
        <div className='col-md-4'></div>
        {correctAnswer && (
          <div className='col-md-2'>
            <p className='text-center'>Correct Answer</p>
          </div>
        )}
        <div className='col-md-1'>
          <p className='text-center'>Poinst</p>
        </div>
        {extraOptions && (
          <div className='col-md-2'>
            <p className='text-center'>Add Action</p>
          </div>
        )}
      </div>
      <DragDropContext onDragEnd={handleDrop}>
        <Droppable droppableId='list-container'>
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {optionQuestions && optionQuestions.map((options: any, index: number) => questionInput(options, index))}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <div className='row'>
        <div className='col-md-4'>
          <div className='form-group'>
            <div className='input-group'>
              <div className='input-group-prepend'>
                <span className='input-group-text rounded-0'>{inputType()}</span>
              </div>
              <span className='text-primary add-option' onClick={() => addOption()}>
                {' '}
                Add Option{' '}
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

DynamicQuestion.propTypes = {
  optionQuestions: PropTypes.array,
  setOptionQuestions: PropTypes.func,
}

export default memo(DynamicQuestion)
