import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { cleanEntityError } from 'redux/api/apiActions'
import { RootState } from 'redux/interfaces'
import useLoadingAndError from 'shared/hooks/useLoadingAndError.hook'
import { AlertProps, HorizontalPostion } from 'ts/interfaces/app.interfaces'


const useNotifications = (entity: string, customMessage = '', customPosition: HorizontalPostion  = 'center') => {
  const dispatch = useDispatch()
  const [isPostLoading, isPostError] = useLoadingAndError(entity)
  const errorMessage = useSelector((state: RootState) => state.login?.errorMessage)
  const response = useSelector((state: RootState) => state.login?.response)
  const [notification, setNotification] = useState<AlertProps>({
    show: false,
    type: 'success',
    message: customMessage,
    position: customPosition,
  })

  useEffect(() => {
    if (isPostError && errorMessage) {
      setNotification({
        ...notification,
        type: 'error',
        message: customMessage ? customMessage! : errorMessage?.message,
        show: true,
      })
      dispatch(cleanEntityError(entity))
    }
  }, [isPostError])

  useEffect(() => {
    if (response && !isPostError && response.success) {
      setNotification({
        ...notification,
        type: 'success',
        message: customMessage ? customMessage! : response.message!,
        show: true,
      })
    } else {
      if (response && !isPostError && !response.success) {
        setNotification({
          ...notification,
          type: 'error',
          message: customMessage ? customMessage! : response.message!,
          show: true,
        })
      }
    }
    dispatch(cleanEntityError(entity))
    setNotification({
      ...notification,
      show: false,
    })
  }, [response, isPostError])

  return { isLoading: isPostLoading, notification }
}

export default useNotifications
