import { useSelector } from 'react-redux'
import { createLoadErrorSelector } from 'redux/api/apiSelectors'

const useLoadingAndError = (entity: string, id = '') => {
  const [loadingSelector, errorSelector] = createLoadErrorSelector(entity, id)

  const [isLoading, isError] = useSelector((state) => [
    loadingSelector(state),
    errorSelector(state),
  ])

  return [isLoading, isError]
}

export default useLoadingAndError
