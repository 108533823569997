import { useTranslation } from 'react-i18next'
import { useCreateUser } from 'api/user/user.api'
import { yupResolver } from '@hookform/resolvers/yup'
import { SubmitHandler, useForm } from 'react-hook-form'
import { CreateUserAdminForm, SelectOptionI } from 'ts/interfaces/app.interfaces'
import { validationCreateUserAdminSchema } from './createUser.validation'

import Input from 'shared/components/input/input.component'
import MultiSelect from 'shared/components/selectInput/multiSelect.component'
import { Button, Box, Modal, Select } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { pushNotification } from 'shared/helpers/notifications/notifications'
import { useGetTenantActiveList } from 'api/tenant/tenant.api'
import { useEffect, useState } from 'react'
import SelectInput from 'shared/components/selectInput/selectInput.component'

interface CreateUserModalProp {
  showModal: boolean
  setShowModal: (showModal: boolean) => void
  onUserCreated?: () => void
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  border: '1px solid #555',
  borderRadius: '10px',
  boxShadow: 24,
  p: 1,
}

const CreateUserModal = ({ showModal, setShowModal, onUserCreated }: CreateUserModalProp) => {
  const [t, i18n] = useTranslation('users')

  // Create selection roles
  const roleOptions: SelectOptionI[] = [
    { value: 'Admin', label: t('roles.admin') },
    { value: 'User', label: t('roles.user') },
    { value: 'PremiumUser', label: t('roles.premium-user') },
  ]

  // Create Api
  const { mutateAsync: createUserAsync, status: creationStatus } = useCreateUser()
  // Get Active Tenants
  const { data: activeTenants, status: activeTenantsStatus } = useGetTenantActiveList()

  // States
  const [tenantOptions, setTenantOptions] = useState<SelectOptionI[]>([])

  // Effects
  useEffect(() => {
    if (activeTenants) {
      setTenantOptions(
        activeTenants.map((tenant) => {
          return { value: tenant.id, label: tenant.name }
        }),
      )
    }
  }, [activeTenants, activeTenantsStatus])

  // Yup:
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<CreateUserAdminForm>({
    resolver: yupResolver(validationCreateUserAdminSchema),
  })

  // Handlers

  const onCloseModalHandler = () => {
    reset({})
    setShowModal(false)
  }

  const onSubmitHandler: SubmitHandler<CreateUserAdminForm> = async data => {
    console.log(data)
    try {
      // Add User:
      await createUserAsync({
        userName: data.userName,
        password: data.password,
        email: data.email,
        language: i18n.language,
        firstName: data.firstName,
        lastName: data.lastName,
        tenantId: data.tenantId,
        roles: data.roles,
      })
      if (['idle', 'success'].includes(creationStatus)) {
        if (onUserCreated) {
          onUserCreated()
        }
        pushNotification(t('messages.user-created'), 'success')
        onCloseModalHandler()
      }
    } catch (error) {
      pushNotification((error?.response?.data?.error?.code as string) ?? t('messages.error'), 'error')
    }
  }

  return (
    <Modal open={showModal} onClose={onCloseModalHandler} aria-labelledby='modal-create-user' aria-describedby='modal-create-new-user-admin'>
      <Box sx={style}>
        <div className='card-header'>
          <div className='row'>
            <div className='col-10 text-left'>
              <h3 className='my-0'>{t('creation.header')}</h3>
            </div>
            <div className='col-2 text-right'>
              <button className='border-0 bg-transparent' onClick={onCloseModalHandler}>
                <CloseIcon />
              </button>
            </div>
          </div>
        </div>
        <div className='card-body'>
          <form onSubmit={handleSubmit(onSubmitHandler)}>
            {/* UserName */}
            <div className='input-group mb-3'>
              <Input
                type='text'
                name='userName'
                id='userName'
                reg={register('userName')}
                errors={errors}
                icon='fa fa-user'
                className='form-control'
                placeholder={t('form.username')}
              />
            </div>

            {/* First Name */}
            <div className='input-group mb-3'>
              <Input
                type='text'
                name='firstName'
                id='firstName'
                reg={register('firstName')}
                errors={errors}
                icon='fa fa-user'
                className='form-control'
                placeholder={t('form.first-name')}
              />
            </div>

            {/* Last Name */}
            <div className='input-group mb-3'>
              <Input
                type='text'
                name='lastName'
                id='lastName'
                reg={register('lastName')}
                errors={errors}
                icon='fa fa-user'
                className='form-control'
                placeholder={t('form.last-name')}
              />
            </div>

            {/* Email */}
            <div className='input-group mb-3'>
              <Input
                type='email'
                name='email'
                id='email'
                reg={register('email')}
                errors={errors}
                icon='fa fa-envelope'
                className='form-control'
                placeholder={t('form.email')}
              />
            </div>

            {/* Password */}
            <div className='input-group mb-3'>
              <Input
                type='password'
                name='password'
                reg={register('password')}
                errors={errors}
                icon='fa fa-lock'
                className='form-control'
                placeholder={t('form.password')}
              />
            </div>

            {/* Confirm Password */}
            <div className='input-group mb-3'>
              <Input
                type='password'
                name='confirmPassword'
                reg={register('confirmPassword')}
                errors={errors}
                icon='fa fa-lock'
                className='form-control'
                placeholder={t('form.confirm-password')}
              />
            </div>

            {/* Select Roles */}
            <div className='input-group mb-3'>
              <MultiSelect
                id='modal-select-roles'
                name='roles'
                elements={roleOptions}
                reg={register('roles')}
                errors={errors}
                placeholder={t('form.select-user-roles')}
              />
            </div>

            {/* Select Tenant */}
            <div className='input-group mb-3'>
              <SelectInput
                id='modal-select-tenant'
                name='tenant'
                reg={register('tenantId')}
                elements={tenantOptions}
                errors={errors}
                placeholder={t('form.select-tenant')}
              />
            </div>

            {/* Register/Update Button */}
            <div className='row'>
              {/* Submit */}
              <div className='d-flex justify-content-center ml-auto mr-4'>
                <Button onClick={onCloseModalHandler} style={{ textTransform: 'none' }}>
                  {t('form.cancel-button')}
                </Button>
                <Button type='submit' variant='contained' className='ml-2' style={{ textTransform: 'none' }}>
                  {t('creation.register-button')}
                </Button>
              </div>
            </div>
          </form>
        </div>
      </Box>
    </Modal>
  )
}

CreateUserModal.propTypes = {}

export default CreateUserModal
