import axios from 'axios'
import Cookies from 'js-cookie'

export const api = {
  get: <T>(url: string, params?: object, headers?: object) =>
    axios.get<T>(url, {
      headers: {
        Authorization: `Bearer ${Cookies.get('token')}`,
        Tenant: Cookies.get('tenantId'),
        Accept: 'application/json',
        'Content-Type': 'application/json',
        ...headers,
      },
      ...params,
    }),
  post: <T>(url: string, data: any) => {
    return axios.post<T>(url, data, {
      headers: {
        Authorization: `Bearer ${Cookies.get('token')}`,
        Tenant: Cookies.get('tenantId'),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
  },
  put: <T>(url: string, data: any) => {
    return axios.put<T>(url, data, {
      headers: {
        Authorization: `Bearer ${Cookies.get('token')}`,
        Tenant: Cookies.get('tenantId'),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
  },
  patch: <T>(url: string, data: any) =>
    axios.patch<T>(url, data, {
      headers: {
        Authorization: `Bearer ${Cookies.get('token')}`,
        Tenant: Cookies.get('tenantId'),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }),
  delete: <T>(url: string) =>
    axios.delete<T>(url, {
      headers: {
        Authorization: `Bearer ${Cookies.get('token')}`,
        Tenant: Cookies.get('tenantId'),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }),
}

export default {}
