import { Input } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { ContentHeader } from 'shared/components/contentHeader/contentHeader.component'

export const NewAssignQuestionnaireComponent = () => {
  const [t] = useTranslation(['letters', 'global'])

  return (
    <>
      <ContentHeader title='Asignacion de Cuestionario!' breadCrumb={{ home: t('global:sidebar.home'), page: t('global:sidebar.letters') }} />

      <section className='content rounded-0'>
        <div className='container-fluid'>
          <div className='callout callout-primary'>
            <h5>Asignacion de Cuestionarios</h5>
            <p>{t('letters:letter.creation-description-body')}</p>
          </div>
        </div>
      </section>

      <section className='content rounded-0'>
        <div className='container-fluid'>
          <div className='card card-primary card-outline'>
            <div className='card-header border-0'>
              <div className='d-flex justify-content-between'>
                <h3 className='card-title'>
                  <strong>Asignacion de Cuestionarios</strong>
                </h3>
                <Link to='/assignQuestionnaire'>{t('global:cruds.back-button')}</Link>
              </div>
            </div>

            <div className='card-body'>
              <form id='new-letter-form' className='row'>
                <div className='col-md-12'>
                  <div className='row'>
                    <div className='col-sm-6'>
                      <div className='form-group'>
                        <label htmlFor='letterName'>Usuario:</label>
                        <Input
                          type='text'
                          name='letterName'
                          // reg={methods.register('letterName')}
                          // errors={methods?.formState?.errors}
                          placeholder='Puesto/Categoria'
                        />
                      </div>
                    </div>

                    <div className='col-sm-6'>
                      <div className='form-group'>
                        <label htmlFor='subject'>Puntaje</label>
                        <Input
                          type='number'
                          name='subject'
                          // reg={methods.register('subject')}
                          // errors={methods?.formState?.errors}
                          placeholder='Puntaje'
                        />
                      </div>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='offset-md-9 col-md-3'>
                      <div className='form-group'>
                        {/* <div className="col-3"> */}
                        <button type='submit' className='btn btn-success btn-block'>
                          {t('global:cruds.save-button')}
                        </button>
                        {/* </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
