import UserTypes from './user.types'
import produce from 'immer'
import { Action, UserRedux } from 'redux/interfaces'
import { createSlice } from '@reduxjs/toolkit'
import { getUserProfile } from 'redux/user/user.actions'

const initialState: UserRedux = {
  data: {
    name: '',
    profilePic: '',
    roles: [],
  },
  loading: false,
  errorMessage: null,
}

const INITIAL_STATE: UserRedux = {
  data: {
    name: '',
    profilePic: '',
    roles: [],
  },
  loading: false,
  errorMessage: null,
}

const userReducerX = (state = INITIAL_STATE, action: Action) => {
  return produce(state, (draftState) => {
    switch (action.type) {
      case UserTypes.TEST:
        draftState.data = action.payload
        break
      case UserTypes.GET_PROFILE_SUCCESS:
        draftState.data = action.payload
        break
      case UserTypes.POST_USER_START:
        draftState.loading = true
        break
      case UserTypes.POST_USER_SUCCESS:
        draftState.data = action.payload
        draftState.loading = false
        break
      case UserTypes.POST_USER_FAILURE:
        draftState.errorMessage = action.payload
        break

      default:
        return state
    }
  })
}

const userReducer = createSlice<any, any, 'userReducer'>({
  name: 'userReducer',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getUserProfile.pending, (state, action) => {
        console.log('loading')
        state.status = 'loading'
      })
      .addCase(getUserProfile.fulfilled, (state, action) => {
        console.log('fulfilled', action.payload)
        action.payload.forEach(
          (todo) => {
            state.entities[todo.id] = todo
            state.ids.push(todo.id)
          },
          { entities: {}, ids: [] } as {
            entities: Record<number, any>
            ids: number[]
          },
        )
        state.status = 'idle'
      })
  },
})

// export default userReducer

export default userReducer.reducer
