import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ContentHeader } from 'shared/components/contentHeader/contentHeader.component'
import CircularProgress from '@mui/material/CircularProgress'
import { Link } from 'react-router-dom'
import { GridColDef } from '@mui/x-data-grid'
import EditIcon from '@mui/icons-material/Edit'
import Switch from '@mui/material/Switch'
import DataTable from 'shared/components/dataTable/dataTable.component'
import { PaginationRequest } from 'ts/interfaces/request.interfaces'
import { useGetClusterTypeList, usePatchClusterType } from 'api/clusterType/clusterType.api'
import { pushNotification } from 'shared/helpers/notifications/notifications'

const ClusterType = () => {
  const [t] = useTranslation(['threeSixty', 'global'])

  const [pagination, setPagination] = useState<PaginationRequest>({
    pageNumber: 1,
    pageSize: 10,
  })
  const { data, status, refetch } = useGetClusterTypeList(pagination)
  const { mutateAsync, status: statusActive } = usePatchClusterType()
  // Handlers
  const onClusterTypeActiveChangeHandler = async (value: boolean, clusterTypeId: string) => {
    try {
      await mutateAsync({ id: clusterTypeId, isActive: value })

      if (['success', 'idle'].includes(statusActive)) {
        pushNotification(value ? t('messages.activated') : t('messages.deactivated'), 'success')
        await refetch()
      }
    } catch (error) {
      pushNotification((error?.response?.data?.error?.code as string) ?? t('messages.error'), 'error')
    }
  }

  const onPaginationChangeHandler = (pageSize: number, pageNumber: number, sortBy: string, sortOrder: 'asc' | 'desc') => {
    setPagination({ pageSize, pageNumber, sortBy, sortOrder })
  }

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: t('cluster-type.table-headers.cluster-type'),
      width: 300,
    },
    {
      field: 'description',
      headerName: t('cluster-type.table-headers.description'),
      flex: 1,
    },
    {
      field: 'editClusterType',
      headerName: t('global:datatable.edit-col'),
      width: 100,
      sortable: false,
      disableColumnMenu: true,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        const clusterTypeId: string = params.row.id

        return (
          <Link to={`/clusterType/edit/${clusterTypeId}`}>
            <EditIcon />
          </Link>
        )
      },
    },
    {
      field: 'activeClusterType',
      headerName: t('global:datatable.active-col'),
      width: 100,
      sortable: false,
      disableColumnMenu: true,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        const clusterTypeId: string = params.row.id
        const isActive: boolean = params.row.isActive

        return <Switch checked={isActive} onChange={(e, value) => onClusterTypeActiveChangeHandler(value, clusterTypeId)} />
      },
    },
  ]

  if (status === 'loading') return <CircularProgress />

  return (
    <>
      <ContentHeader
        title={t('global:sidebar.clusterTypes')}
        breadCrumb={{ home: t('global:sidebar.home'), page: t('global:sidebar.clusterTypes') }}
      />

      <section className='content'>
        <div className='container-fluid'>
          <div className='card card-primary card-outline'>
            <div className='card-header border-0'>
              <div className='d-flex justify-content-between'>
                <h3 className='card-title'>{t('cluster-type.header')}</h3>
                <Link to='/clusterType/new'>{t('cluster-type.new-cluster-type-button')}</Link>
              </div>
            </div>

            <div className='card-body'>
              <DataTable
                data={data?.data}
                columns={columns}
                getRowId={(row) => row.id}
                pageSize={pagination.pageSize}
                pageNumber={pagination.pageNumber}
                totalRows={data?.totalEntitiesCount}
                sortBy={pagination.sortBy}
                sortOrder={pagination.sortOrder}
                serverMode
                onPaginationChangeHandler={onPaginationChangeHandler}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default ClusterType
