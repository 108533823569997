import React from 'react'
import { ContentHeaderI } from 'ts/interfaces/app.interfaces'
import { Link } from 'react-router-dom'

export const ContentHeader: React.FC<ContentHeaderI> = ({ title, breadCrumb }) => {
  return (
    <section className='content-header'>
      <div className='container-fluid'>
        <div className='row mb-2'>
          <div className='col-sm-6'>
            <h1>{title}</h1>
          </div>
          {breadCrumb && (
            <div className='col-sm-6'>
              <ol className='breadcrumb float-sm-right'>
                <li className='breadcrumb-item'>
                  <Link to='/'>{breadCrumb?.home}</Link>
                </li>
                <li className='breadcrumb-item active'>{breadCrumb?.page}</li>
              </ol>
            </div>
          )}
        </div>
      </div>
    </section>
  )
}

export default {}
