import { TypeObjectAction } from 'ts/interfaces/app.interfaces'
import { generateSpecificCrudActionType } from 'shared/helpers/redux/index'

const LoginTypes: TypeObjectAction = {
  LOGOUT: 'LOGOUT',
  ...generateSpecificCrudActionType('POST_LOGIN'),
  ...generateSpecificCrudActionType('POST_LINKEDIN'),
  ...generateSpecificCrudActionType('POST_FORGOT_PASSWORD'),
  ...generateSpecificCrudActionType('POST_RECOVERY_PASSWORD'),
}

export default LoginTypes
