import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { SubmitHandler, useForm } from 'react-hook-form'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'

import CircularProgress from '@mui/material/CircularProgress'
import { ContentHeader } from 'shared/components/contentHeader/contentHeader.component'
import Input from 'shared/components/input/input.component'
import { Factor } from 'api/factor/factor.interface'
import { useCreateFactor, useGetFactorById, useUpdateFactor } from 'api/factor/factor.api'
import { pushNotification } from 'shared/helpers/notifications/notifications'
import { useGetClusterActiveTypeList } from 'api/clusterType/clusterType.api'
import { validationNewFactorSchema } from './factor.validation'

const NewFactor = () => {
  const [t] = useTranslation(['threeSixty', 'global'])

  const { mutateAsync: createFactorAsync, status: creationStatus } = useCreateFactor()
  const { mutateAsync: editFactorAsync, status: editionStatus } = useUpdateFactor()
  const { data: clusterData, status: clusterDataStatus } = useGetClusterActiveTypeList()
  const navigate = useNavigate()

  const { factorId } = useParams()

  let factorData: Factor = null
  let loading = false
  if (factorId) {
    const { isLoading, data: factorGetData } = useGetFactorById(factorId)

    factorData = factorGetData
    loading = isLoading
  }

  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm<Factor>({
    resolver: yupResolver(validationNewFactorSchema),
  })
  const factorName = watch('name', t('factor.creation-default-name') as string)

  const onSubmit: SubmitHandler<Factor> = async (factorDataSubmit) => {
    factorId ? await editFactorAsync(factorDataSubmit) : await createFactorAsync(factorDataSubmit)

    if (creationStatus === 'success' || creationStatus === 'idle' || editionStatus === 'success' || editionStatus === 'idle') {
      pushNotification(factorId ? t('messages.modified') : t('messages.created'), 'success')
      reset({
        name: '',
        clusterId: '',
      })
      navigate('/factor')
    } else {
      pushNotification(t('messages.error'), 'error')
    }
  }

  useEffect(() => {
    if (factorData) {
      reset({
        id: factorData?.id,
        name: factorData?.name,
        clusterId: factorData?.clusterId,
        description: factorData?.description,
      })
    }
  }, [factorData, reset])

  if (clusterDataStatus === 'loading' || loading) return <CircularProgress />

  const actionI18N = factorId ? 'edition' : 'creation'

  return (
    <>
      <ContentHeader title={t(`factor.${actionI18N}-header`)} breadCrumb={{ home: t('global:sidebar.home'), page: t('global:sidebar.factors') }} />

      <section className='content rounded-0'>
        <div className='container-fluid'>
          <div className='callout callout-primary'>
            <h5>{t(`factor.${actionI18N}-description-title`)}</h5>
            <p>{t(`factor.${actionI18N}-description-body`)}</p>
          </div>
        </div>
      </section>

      <section className='content rounded-0'>
        <div className='container-fluid'>
          <div className='card card-primary card-outline'>
            <div className='card-header border-0'>
              <div className='d-flex justify-content-between'>
                <h3 className='card-title'>
                  <strong>{factorName}</strong>
                </h3>
                <Link to='/factor'>{t('global:cruds.back-button')}</Link>
              </div>
            </div>

            <div className='card-body'>
              <form id='new-cluster-form' className='row' onSubmit={handleSubmit(onSubmit)}>
                <div className='col-md-12'>
                  <div className='row'>
                    <div className='col-md-6'>
                      <div className='form-group'>
                        <label htmlFor='name'>{t('factor.table-headers.factor')}</label>
                        <Input type='text' name='name' reg={register('name')} errors={errors} placeholder={t('factor.table-headers.factor')} />
                      </div>
                    </div>
                    <div className='col-md-6'>
                      <div className='form-group'>
                        <label htmlFor='clusterId'>{t('factor.table-headers.cluster')}</label>
                        <select className='form-control rounded-0' {...register('clusterId')}>
                          <option value='0'>{t('factor.table-headers.cluster-placeholder')}</option>
                          {clusterData.map((cluster) => (
                            <option value={cluster.id}>{cluster.name}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-md-12'>
                      <div className='form-group'>
                        <label htmlFor='description'>{t('factor.table-headers.description')}</label>
                        <Input
                          type='text'
                          name='description'
                          reg={register('description')}
                          errors={errors}
                          placeholder={t('factor.table-headers.description')}
                        />
                      </div>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='offset-md-9 col-md-3'>
                      <div className='form-group'>
                        {/* <div className="col-3"> */}
                        <button type='submit' className='btn btn-success btn-block'>
                          {t('global:cruds.save-button')}
                        </button>
                        {/* </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default NewFactor
