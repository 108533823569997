import { useFetch } from 'api/reactQuery'
import { ApiURL, services, subServices } from 'services/api.service'
import type { Scope } from './scope.interface'
import { api } from '../api'

const { URL_API } = ApiURL
const { THREE_SIXTY } = services
const { GET_ROLE_SCOPE_ACTIVE_LIST } = subServices

export const useGetScopeActiveList = () => useFetch<Scope[]>(`${URL_API}/${THREE_SIXTY}/${GET_ROLE_SCOPE_ACTIVE_LIST}`)

export const getScopeList = () => api.get<Scope[]>(`${URL_API}/${THREE_SIXTY}/${GET_ROLE_SCOPE_ACTIVE_LIST}`, { id: 1 })
