import { useEffect } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { EditScheduleForm } from 'ts/interfaces/app.interfaces'
import { yupResolver } from '@hookform/resolvers/yup'
import { validationEditScheduleSchema } from 'pages/schedule/editSchedule.validation'

import { ContentHeader } from 'shared/components/contentHeader/contentHeader.component'
import Input from 'shared/components/input/input.component'
import { useTranslation } from 'react-i18next'
import { useEditSchedule, useGetScheduleById } from 'api/schedule/schedule.api'
import { validateStatusSuccess } from 'shared/helpers/api'
import { CircularProgress } from '@mui/material'
import { pushNotification } from 'shared/helpers/notifications/notifications'

const EditSchedule = () => {
  const [t] = useTranslation(['letters', 'global'])
  // Navigation:
  const navigate = useNavigate()
  const navigateToSchedule = () => navigate('/schedule')
  // Get Params:
  const { scheduleId } = useParams()

  if (!scheduleId) navigateToSchedule()

  const { isLoading, data } = useGetScheduleById(scheduleId)
  const { mutateAsync, status } = useEditSchedule()

  // Yup
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<EditScheduleForm>({
    resolver: yupResolver(validationEditScheduleSchema),
  })

  // Effects:
  useEffect(() => {
    if (data) {
      reset({
        id: data?.id,
        name: data?.name,
        sends: data?.totalOfSents,
        frequency: data?.frequency,
      })
    }
  }, [data, reset])

  // Handlers:
  const onSubmit: SubmitHandler<EditScheduleForm> = async data => {
    try {
      await mutateAsync(data)

      if (validateStatusSuccess(status)) {
        reset({})
        navigate('/schedule')
      }
    } catch (error) {
      pushNotification((error?.response?.data?.error?.code as string) ?? t('letters:messages.error'))
    }
  }

  if (isLoading || status === 'loading') return <CircularProgress />

  return (
    <>
      <ContentHeader
        title={t('letters:schedule.edition-header')}
        breadCrumb={{ home: t('global:sidebar.home'), page: t('global:sidebar.schedulers') }}
      />

      <section className='content rounded-0'>
        <div className='container-fluid'>
          <div className='callout callout-primary'>
          <h5>{t('letters:schedule.edition-description-title')}</h5>
            <p>{t('letters:schedule.edition-description-body')}</p>
          </div>
        </div>
      </section>

      <section className='content rounded-0'>
        <div className='container-fluid'>
          <div className='card card-primary card-outline'>

            <div className='card-header border-0'>
              <div className='d-flex justify-content-between'>
                <h3 className='card-title'><strong>{data?.name}</strong></h3>
                <Link to='/schedule'>{t('global:cruds.back-button')}</Link>
              </div>
            </div>

            <div className='card-body'>
              <form
                id='new-schedule-form'
                className='row'
                onSubmit={handleSubmit(onSubmit)}
              >
                <div className='col-md-12'>

                  <div className='row'>

                    <div className='col-md-6'>
                      <div className='form-group'>
                        <label htmlFor='times'>{t('letters:schedule.table-headers.times-to-send')}</label>
                        <Input
                          type='number'
                          name='timesToSend'
                          reg={register('sends')}
                          errors={errors}
                          placeholder={t('letters:schedule.table-headers.times-to-send')}
                          min={0}
                        />
                      </div>
                    </div>

                    <div className='col-md-6'>
                      <div className='form-group'>
                        <label htmlFor='frequency'>{t('letters:schedule.table-headers.frequency')}</label>
                        <Input
                          type='number'
                          name='frequencyDays'
                          reg={register('frequency')}
                          errors={errors}
                          placeholder={t('letters:schedule.table-headers.frequency')}
                          min={0}
                        />
                      </div>
                    </div>

                  </div>

                  <div className='row'>
                    <div className='offset-md-9 col-md-3'>
                      <div className='form-group'>
                        {/* <div className="col-3"> */}
                        <button type="submit" className="btn btn-success btn-block">
                          {t('global:cruds.save-button')}
                        </button>
                        {/* </div> */}
                      </div>
                    </div>
                  </div>

                </div>
              </form>

            </div>
          </div>
        </div>
      </section>
    </>
  )
}

EditSchedule.propTypes = {}

export default EditSchedule
