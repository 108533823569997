import { toast } from 'react-toastify'

export const pushNotification = (msg: string, type = 'error') => {
  toast[type](msg, {
    position: 'bottom-right',
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  })
}
