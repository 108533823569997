import { useTranslation } from "react-i18next"

export const Footer = () => {
  const [t] = useTranslation('global')
  return (
    <footer className='main-footer'>
      {/* <!-- To the right --> */}
      <div className='float-right d-none d-sm-inline'>{t('footer.anything-you-want')}</div>
      {/* <!-- Default to the left --> */}
      <strong>
        Copyright &copy; 2022 <a href='index.html'>Spyros</a>.
      </strong>{' '}
      {t('footer.all-rights-reserved')}
    </footer>
  )
}

export default Footer
