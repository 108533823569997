import { CircularProgress } from '@material-ui/core'
import { useGetEvaluatorCategoryActiveList } from 'api/evaluatorCategory/evaluatorCategory.api'
import { useCreateUserAssignmentQuestionnaire } from 'api/questionnaire/questionnaire.api'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ContentHeader } from 'shared/components/contentHeader/contentHeader.component'
import { pushNotification } from 'shared/helpers/notifications/notifications'

const userInformation = {
  evaluatorEmail: '',
  evaluatorPhoneNumber: '',
  evaluatorFirstName: '',
  evaluatorCategoryId: '',
  lastName: '',
  language: 'en',
  startDate: '',
  endDate: '',
}

export const AssignQuestionnaireUser = () => {
  const [t, i18n] = useTranslation(['threeSixty', 'global'])
  userInformation.language = i18n.language ?? 'en'

  const [emailUsers, setEmailUsers] = useState(userInformation)
  const { mutateAsync: createUserAssignmentAsync } = useCreateUserAssignmentQuestionnaire()
  const { isLoading: isLoadingCategory, data: categoryData } = useGetEvaluatorCategoryActiveList()

  const submitUserInvitation = () => createUserAssignmentAsync(emailUsers)
    .then((resp) => {
      if (resp.status === 200) {
        pushNotification('Invitacion enviada correctamente', 'success')
        setEmailUsers(userInformation)
      }
    })
    .catch((err) => pushNotification(err.response?.data?.message ?? err.message, 'error'))

  const handleSubmit = () => {
    if (!emailUsers.evaluatorFirstName || !emailUsers.evaluatorEmail) {
      pushNotification('No pueden ir campos vacios', 'error')
      return
    }
    submitUserInvitation()
  }

  const updateInput = (e: any) => {
    setEmailUsers((prevState) => ({ ...prevState, [e.target.id]: e.target.value }))
  }

  const renderUserInvitation = (user: any) => {
    return (
      <div className='row'>
        <div className='col-md-4'>
          <div className='form-group'>
            <label htmlFor='email'>{t('questionnaire-assignment.evaluator.email')}</label>
            <input
              id='evaluatorEmail'
              type='email'
              className='form-control'
              name='evaluatorEmail'
              placeholder={t('questionnaire-assignment.evaluator.email-placeholder')}
              defaultValue={user.evaluatorEmail}
              onChange={(e) => updateInput(e)}
            />
          </div>
        </div>
        <div className='col-md-4'>
          <div className='form-group'>
            <label htmlFor='firstName'>{t('questionnaire-assignment.evaluator.first-name')}</label>
            <input
              id='evaluatorFirstName'
              type='text'
              name='evaluatorFirstName'
              className='form-control'
              placeholder={t('questionnaire-assignment.evaluator.first-name-placeholder')}
              defaultValue={user.evaluatorFirstName}
              onChange={(e) => updateInput(e)}
            />
          </div>
        </div>
        <div className='col-md-4'>
          <div className='form-group'>
            <label htmlFor='phoneNumber'>{t('questionnaire-assignment.evaluator.phone')}</label>
            <input
              id='evaluatorPhoneNumber'
              type='text'
              name='evaluatorPhoneNumber'
              className='form-control'
              placeholder={t('questionnaire-assignment.evaluator.phone-placeholder')}
              defaultValue={user.evaluatorPhoneNumber}
              onChange={(e) => updateInput(e)}
            />
          </div>
        </div>
      </div>
    )
  }

  if (isLoadingCategory) return <CircularProgress />

  return (
    <>
      <ContentHeader
        title={t('questionnaire-assignment.header')}
        breadCrumb={{ home: t('global:sidebar.home'), page: t('global:sidebar.questionnaire-assignments') }}
      />

      <section className='content rounded-0'>
        <div className='container-fluid'>
          <div className='callout callout-primary'>
            <h5>{t('questionnaire-assignment.description-title')}</h5>
            <p>{t('questionnaire-assignment.description-body')}</p>
          </div>
        </div>
      </section>

      <section className='content'>
        <div className='container-fluid'>
          <div className='card card-primary card-outline'>
            <div className='card-header border-0'>
              <div className='d-flex justify-content-between'>
                <h3 className='card-title'>{t('questionnaire-assignment.header-instructions')}</h3>
              </div>
            </div>

            <form id='new-letter-form' className='card-body'>
              <h3>{t('questionnaire-assignment.header-invitation')}</h3>

              <div className='row'>
                <div className='col-md-4'>
                  <div className='form-group'>
                    <label htmlFor='startDate'>{t('questionnaire-assignment.schedule.start')}</label>
                    <input
                      id='startDate'
                      type='date'
                      className='form-control'
                      name='startDate'
                      onChange={(e) => updateInput(e)}
                    />
                  </div>
                </div>

                <div className='col-md-4'>
                  <div className='form-group'>
                    <label htmlFor='endDate'>{t('questionnaire-assignment.schedule.end')}</label>
                    <input
                      id='endDate'
                      type='date'
                      className='form-control'
                      name='endDate'
                      onChange={(e) => updateInput(e)}
                    />
                  </div>
                </div>

                <div className='col-md-4'>
                  <div className='form-group'>
                    <label htmlFor='relationId'>{t('questionnaire-assignment.evaluator.category')}</label>
                    <select
                      name='evaluatorCategoryId'
                      id='evaluatorCategoryId'
                      className='form-control'
                      onChange={(e) => updateInput(e)}
                    >
                      <option value='0'>{t('questionnaire-assignment.evaluator.category-selection')}</option>
                      {categoryData.map((category) => (
                        <option value={category.id}>{category.name}</option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>

              {renderUserInvitation(emailUsers)}

              <div className='row'>
                <div className='offset-9 col-md-3'>
                  <button className='btn btn-success btn-block' type='button' onClick={handleSubmit}>
                    Invitar
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  )
}
