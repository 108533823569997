import React from 'react'
import { styled } from '@mui/material/styles'
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip'
import CircularProgress from '@mui/material/CircularProgress'
import { useGetFamilyFactores, useGetFamilyRoles } from 'api/family/family.api'
import { useGetTargetFamily } from 'api/level/level.api'
import { scopeDictionary } from 'ts/enums/app.enums'
import { LevelTargetPrev } from '../../../api/level/level.interface'

const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => <Tooltip {...props} arrow classes={{ popper: className }} />)(
  ({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
    },
  }),
)

interface ConfigureTargetProps {
  onOpen: (role: string, rolId: string) => void
  onClose: () => void
  familyId: string
  activeFilters?: string[]
}

interface LevelTargetDictionary {
  [rolId: string]: LevelTargetPrev
}

export const ConfigureTarget: React.FC<ConfigureTargetProps> = ({ onOpen, onClose, familyId, activeFilters }) => {
  const { data: familyRolesData, status: familyRolesStatus } = useGetFamilyRoles(familyId)
  const { data: familyFactoresData, status: familyFactoresStatus } = useGetFamilyFactores(familyId)
  const { data: familyTargetData, status: familyTargetStatus, refetch } = useGetTargetFamily(familyId)

  const configuredRoles = (roleId) => {
    return familyFactoresData.map((factor) => {
      const target = checkRoleFactor(roleId, factor.factorId)[0]

      const extraLevels = target?.extraLevels?.map((level) => level.value)?.join(', ') ?? ''

      if (target) {
        return (
          <td key={`td-configuration-${target.factorId}`}>
            {target.level.value}{' '}
            <BootstrapTooltip title={`Niveles Configurados: ${extraLevels}`}>
              <span className='fa fa-info-circle' />
            </BootstrapTooltip>
            {target.isCriticalValue ? <span className='text-danger'> *</span> : ' '}
          </td>
        )
      }

      return <td key={`${factor.factorId}-td`}>0</td>
    })
  }

  const checkRoleFactor = (roleId, factorId) => {
    return familyTargetData.filter((target) => target.rolId === roleId && target.factorId === factorId)
  }

  const factorButtonRender = () => {
    return familyRolesData
      .filter((role) => (activeFilters.length ? activeFilters.includes(role.rolScope.name) : role))
      .map((role) => (
        <tr key={`tr-${role.rolId}`}>
          <td>
            <button
              type='button'
              className={`btn btn-xs btn-${scopeDictionary[role?.rolScope?.name].color}`}
              style={{ width: '12rem' }}
              onClick={() => onOpen(role.rolName, role.rolId)}
            >
              {role.rolName}
            </button>
          </td>
          {configuredRoles(role.rolId)}
        </tr>
      ))
  }

  if ([familyRolesStatus, familyFactoresStatus, familyTargetStatus].includes('loading')) return <CircularProgress />

  return (
    <div className='row'>
      <div className='col-md-12'>
        <table className='table table-three-sixty'>
          <thead>
            <tr>
              <th>Roles \ Factores</th>
              {familyFactoresData.map((factor) => (
                <th key={`th-${factor.factorFamilyId}`} className='factor'>
                  {factor.factorName}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>{factorButtonRender()}</tbody>
        </table>
      </div>
    </div>
  )
}
