import { SubmitHandler, useForm } from 'react-hook-form'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'
import { NewCategoryEvaluatorForm } from 'ts/interfaces/app.interfaces'

import { ContentHeader } from 'shared/components/contentHeader/contentHeader.component'
import Input from 'shared/components/input/input.component'
import CircularProgress from '@mui/material/CircularProgress'
import { useTranslation } from 'react-i18next'
import { pushNotification } from 'shared/helpers/notifications/notifications'
import { validationCreateCategoryValidation } from 'pages/categoryEvaluator/validation'
import { useCreateEvaluatorCategory, useGetEvaluatorCategory, useUpdateEvaluatorCategory } from 'api/evaluatorCategory/evaluatorCategory.api'
import { EvaluatorCategory } from 'api/evaluatorCategory/evaluatorCategory.interface'
import { useEffect } from 'react'

const CategoryEvaluatorForm = () => {
  const [t] = useTranslation(['letters', 'global'])
  const { categoryEvaluatorId } = useParams()

  const { mutateAsync, status } = useCreateEvaluatorCategory()
  const { mutateAsync: editCategoryAsync, status: editionStatus } = useUpdateEvaluatorCategory()

  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
    setValue,
  } = useForm<NewCategoryEvaluatorForm>({
    resolver: yupResolver(validationCreateCategoryValidation),
  })

  let categoryData: EvaluatorCategory = null
  let loading = false

  if (categoryEvaluatorId) {
    const { isLoading, data: categoryGetData } = useGetEvaluatorCategory(categoryEvaluatorId)

    categoryData = categoryGetData

    loading = isLoading
  }

  useEffect(() => {
    if (categoryData) {
      reset({
        evaluatorCategoryId: categoryData?.id,
        name: categoryData?.name,
        description: categoryData?.description,
      })
    }
  }, [categoryData, reset])

  const navigate = useNavigate()

  const categoryName = watch('name', '')

  // Handlers:
  const onSubmit: SubmitHandler<NewCategoryEvaluatorForm> = async (evaluatorCategory) => {
    try {
      categoryEvaluatorId ? await editCategoryAsync(evaluatorCategory) : await mutateAsync(evaluatorCategory)

      if (status === 'success' || status === 'idle') {
        pushNotification(categoryEvaluatorId ? t('messages.modified') : t('messages.created'), 'success')
        reset({
          name: '',
          description: '',
        })
        navigate('/categoryEvaluator')
      } else {
        pushNotification(`${t('messages.error')} to ${categoryEvaluatorId ? 'update' : 'create'}: ${evaluatorCategory.name}!`, 'error')
      }
    } catch (error) {
      pushNotification((error?.response?.data?.error?.code as string) ?? t('letters:messages.error'), 'error')
    }
  }

  if (loading) return <CircularProgress />

  return (
    <>
      <ContentHeader title='Nueva Categoria de Evaluador' breadCrumb={{ home: t('global:sidebar.home'), page: t('global:sidebar.letters') }} />

      <section className='content rounded-0'>
        <div className='container-fluid'>
          <div className='callout callout-primary'>
            <h5>Creacion de categoria de evaluador</h5>
            <p>{t('letters:letter.creation-description-body')}</p>
          </div>
        </div>
      </section>

      <section className='content rounded-0'>
        <div className='container-fluid'>
          <div className='card card-primary card-outline'>
            <div className='card-header border-0'>
              <div className='d-flex justify-content-between'>
                <h3 className='card-title'>
                  <strong>{categoryName}</strong>
                </h3>
                <Link to='/categoryEvaluator'>{t('global:cruds.back-button')}</Link>
              </div>
            </div>

            <div className='card-body'>
              <form id='new-letter-form' className='row' onSubmit={handleSubmit(onSubmit)}>
                <div className='col-md-12'>
                  <div className='row'>
                    <div className='col-sm-4'>
                      <div className='form-group'>
                        <label htmlFor='name'>Puesto/Categoria</label>
                        <Input type='text' name='name' reg={register('name')} errors={errors} placeholder='Puesto/Categoria' />
                      </div>
                    </div>

                    <div className='col-sm-5'>
                      <div className='form-group'>
                        <label htmlFor='subject'>Descripcion</label>
                        <Input type='text' name='description' reg={register('description')} errors={errors} placeholder='Descripcion...' />
                      </div>
                    </div>

                    <div className='col-sm-3'>
                      <div className='form-group'>
                        <label htmlFor='points'>Puntaje</label>
                        <Input
                          type='number'
                          name='points'
                          // reg={register('subject')}
                          // errors={.formState?.errors}
                          placeholder='Puntaje'
                        />
                      </div>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='offset-md-9 col-md-3'>
                      <div className='form-group'>
                        {/* <div className="col-3"> */}
                        <button type='submit' className='btn btn-success btn-block'>
                          {t('global:cruds.save-button')}
                        </button>
                        {/* </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default CategoryEvaluatorForm
