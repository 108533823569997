import { useState } from 'react'
import { UserAdminObject } from 'redux/interfaces'
import { useEnableUser, useGetAllUsers } from 'api/user/user.api'
import { PaginationRequest } from 'ts/interfaces/request.interfaces'
import CircularProgress from '@mui/material/CircularProgress'

import CreateUserModal from 'pages/users/createUserModal.component'
import EditUserModal from 'pages/users/editUserModal.component'
import Button from '@mui/material/Button'
import DataTable from 'shared/components/dataTable/dataTable.component'
import { GridColDef } from '@mui/x-data-grid'
import EditIcon from '@mui/icons-material/Edit'
import Switch from '@mui/material/Switch'
import { getElementByKey } from 'shared/helpers'
import { ContentHeader } from 'shared/components/contentHeader/contentHeader.component'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { resetRoleFamily } from 'api/role/role.api'
import CleaningServicesIcon from '@mui/icons-material/CleaningServices'

const defaultUser: UserAdminObject = {
  userId: '',
  userName: '',
  linkedinId: '',
  roles: [],
  firstName: '',
  lastName: '',
  email: '',
  tenantId: null,
  isActive: false,
}

const Users = () => {
  const [t] = useTranslation(['users', 'global'])

  // Pagination
  const [pagination, setPagination] = useState<PaginationRequest>({
    pageNumber: 1,
    pageSize: 10,
  })
  // Get Users
  const { data: users, isLoading, refetch: reloadUsers } = useGetAllUsers(pagination)
  // Enable User
  const { mutateAsync: enableUserAsync, status: enableStatus } = useEnableUser()

  // States
  const [showCreateUserModal, setShowCreateUserModal] = useState<boolean>(false)
  const [showEditUserModal, setShowEditUserModal] = useState<boolean>(false)
  const [editUser, setEditUser] = useState<UserAdminObject>(defaultUser)

  // Handlers

  const onModifyUserHandler = (userId: string) => {
    const selectedUser = getElementByKey(users.data, userId, 'userId')
    if (selectedUser) {
      // Edit user:
      setEditUser(selectedUser)
      setShowEditUserModal(true)
    }
  }

  const onUserActiveChangeHandler = async (userId: string, value: boolean) => {
    // Enable/Disable User:
    await enableUserAsync({
      userId: userId,
      enable: value,
    })
    // If ok, reload users:
    if (['idle', 'success'].includes(enableStatus)) {
      reloadUsers()
    }
  }

  const onPaginationChangeHandler = (pageSize: number, pageNumber: number, sortBy: string, sortOrder: 'asc' | 'desc') => {
    setPagination({ pageSize, pageNumber, sortBy, sortOrder })
  }

  // Renders
  const getRoleName = (role: string): string => {
    switch (role.toLowerCase()) {
      case 'admin':
        return t('users:roles.admin')
      case 'user':
        return t('users:roles.user')
      case 'premiumuser':
        return t('users:roles.premium-user')
      default:
        return ''
    }
  }
  const renderRoles = (roles: string[]) => {
    return roles.map((r) => getRoleName(r)).join(', ')
  }

  const handleRemoveRole = (userId: string) => {
    resetRoleFamily(userId)
      .then((resp) => {
        console.log('resp', resp)
      })
      .catch((err) => console.log('err', err))
  }

  // Data Grid Columns:
  const columns: GridColDef[] = [
    {
      field: 'userName',
      headerName: t('users:table-cols.username'),
      flex: 1,
    }, {
      field: 'firstName',
      headerName: t('users:table-cols.first-name'),
      flex: 1,
    }, {
      field: 'lastName',
      headerName: t('users:table-cols.last-name'),
      flex: 1,
    }, {
      field: 'email',
      headerName: t('users:table-cols.email'),
      flex: 1,
    }, {
      field: 'roles',
      headerName: t('users:table-cols.roles'),
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
      valueFormatter: (valueFormatter) => renderRoles(valueFormatter.value),
    }, {
      field: 'tenantName',
      headerName: t('users:table-cols.tenant'),
      sortable: true,
      flex: 1,
    }, {
      field: 'removeRole',
      headerName: 'Remove Rol',
      width: 100,
      sortable: false,
      disableColumnMenu: true,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        const userId: string = params.row.userId

        return (
          <Button onClick={() => handleRemoveRole(userId)}>
            <CleaningServicesIcon />
          </Button>
        )
      },
    }, {
      field: 'editUser',
      headerName: t('global:datatable.edit-col'),
      width: 100,
      sortable: false,
      disableColumnMenu: true,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        const userId: string = params.row.userId

        return (
          <Button onClick={() => onModifyUserHandler(userId)}>
            <EditIcon />
          </Button>
        )
      },
    }, {
      field: 'activeUser',
      headerName: t('global:datatable.active-col'),
      width: 100,
      sortable: false,
      disableColumnMenu: true,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        const userId: string = params.row.userId
        const isActive: boolean = params.row.isActive

        return <Switch checked={isActive} onChange={(_, value) => onUserActiveChangeHandler(userId, value)} />
      },
    },
  ]

  if (isLoading) return <CircularProgress />

  return (
    <>
      <ContentHeader
        title={t('global:sidebar.users')}
        breadCrumb={{ home: t('global:sidebar.home'), page: t('global:sidebar.users') }}
      />

      <div className='container-fluid'>
        <div className='row'>
          <div className='col-12'>
            <div className='card card-primary card-outline'>
              {/* Card Header */}
              <div className='card-header border-0'>
                <div className='d-flex justify-content-between align-items-center'>
                  <h3 className='card-title'>{t('users:title')}</h3>
                  {/* onClick={() => setShowCreateUserModal(true)} */}
                  <Link to='/users/new'>{t('users:add-new-button')}</Link>
                </div>
              </div>

              {/* Table */}
              <div className='card-body pt-0'>
                <DataTable
                  data={users.data}
                  columns={columns}
                  getRowId={(row) => row.userId}
                  pageSize={pagination.pageSize}
                  pageNumber={pagination.pageNumber}
                  totalRows={users.totalEntitiesCount}
                  sortBy={pagination.sortBy}
                  sortOrder={pagination.sortOrder}
                  serverMode
                  onPaginationChangeHandler={onPaginationChangeHandler}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Modals */}

      <CreateUserModal showModal={showCreateUserModal} setShowModal={setShowCreateUserModal} onUserCreated={reloadUsers} />
      <EditUserModal showModal={showEditUserModal} setShowModal={setShowEditUserModal} updateUser={editUser} onUserUpdated={reloadUsers} />
    </>
  )
}

Users.propTypes = {}

export default Users
