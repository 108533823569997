import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { SelectOptionI } from 'ts/interfaces/app.interfaces'

import { Box, InputLabel, MenuItem, FormControl, Select } from '@mui/material'

interface SelectInputComponent {
  id?: string
  name: string
  elements: SelectOptionI[]
  reg: any
  errors: any
  errorIcon?: string
  placeholder?: string
  defaultSelectedValue?: string
  onSelectionChange?: (value: string) => void
}

const SelectInput = ({
  id,
  name,
  elements,
  reg,
  errors,
  errorIcon,
  placeholder = name,
  defaultSelectedValue,
  onSelectionChange,
}: SelectInputComponent) => {
  // States:
  const [selectedValue, setSelectedValue] = useState<string>(defaultSelectedValue ?? '')

  // Effects:
  useEffect(() => {
    if (onSelectionChange) {
      onSelectionChange(selectedValue)
    }
  }, [selectedValue])

  return (
    <Box sx={{ minWidth: 120, width: '100%' }}>
      <FormControl className='input-group mb-3'>

        <InputLabel
          id={`${id}-label`}
          className='bg-white pr-2'
          error={!!errors[name]}
        >
          <span className={errors[name] ? 'text-red' : 'text-gray'}>
            {placeholder}
          </span>
        </InputLabel>

        <Select
          id={id}
          labelId={`${id}-label`}
          value={selectedValue}
          label={placeholder}
          {...reg}
          onChange={e => setSelectedValue(e.target.value as string)}
          error={!!errors[name]}
        >
          {elements.map(element => (
            <MenuItem
              key={element.value}
              value={element.value}
            >
              {element.label}
            </MenuItem>
          ))}
        </Select>

        {errors[name] && (
          <div className='d-block invalid-feedback'>
            <span className={`fas ${errorIcon ?? 'fa-exclamation-circle'}`}></span>
            {` ${errors[name]?.message}`}
          </div>
        )}

      </FormControl>
    </Box>
  )
}

SelectInput.propTypes = {
  elements: PropTypes.any,
  reg: PropTypes.object,
  name: PropTypes.string,
  errors: PropTypes.object,
  id: PropTypes.string,
}

export default SelectInput
