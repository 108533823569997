import { ApiURL, services, subServices } from 'services/api.service'
import { useFetch, usePost, useUpdate } from 'api/reactQuery'
import { Question, Questions, Answer, QuestionEdit } from 'api/question/question.interface'

const { URL_API } = ApiURL
const { THREE_SIXTY } = services
const { CREATE_QUESTION, GET_QUESTIONS_LEVEL, EDIT_ANSWER, EDIT_QUESTION } = subServices

export const usePostQuestion = () => usePost<Question, Question>(`${URL_API}/${THREE_SIXTY}/${CREATE_QUESTION}`, { id: 1 })

export const useGetQuestionByLevel = (IdLevel: string) =>
  useFetch<Questions[]>(`${URL_API}/${THREE_SIXTY}/${GET_QUESTIONS_LEVEL}?IdLevel=${IdLevel}`, { IdLevel })

export const useUpdateAnswer = () => useUpdate<Answer, Answer>(`${URL_API}/${THREE_SIXTY}/${EDIT_ANSWER}`, { id: 1 })

export const useUpdateQuestion = () => useUpdate<QuestionEdit, QuestionEdit>(`${URL_API}/${THREE_SIXTY}/${EDIT_QUESTION}`, { id: 1 })
