import * as Yup from 'yup'
import { t } from 'i18next'

const validationEditScheduleSchema = Yup.object().shape({
  sends: Yup.number()
    .required(t('letters:yup.schedule-sents.required'))
    .min(1, t('letters:yup.schedule-sents.min')),
  frequency: Yup.number()
    .required(t('letters:yup.schedule-frequency.required'))
    .min(1, t('letters:yup.schedule-frequency.min')),
})

export { validationEditScheduleSchema as validationEditScheduleSchema }

export default {}
