import React, { useState } from 'react'
import { FileUploader } from 'react-drag-drop-files'
import VisorDocs from 'shared/components/visorDocs/visorDocs.component'

interface File {
  name: string
  size: number
  type: string
  webkitRelativePath: string
}

interface UploadFileProps {
  fileTypes: string[]
  onHandleFile: React.Dispatch<React.SetStateAction<File | undefined>> | any
  file?: File | undefined
}

const UploadFile: React.FC<UploadFileProps> = ({ fileTypes, onHandleFile, file: questionFile }) => {
  const handleChange = (questionFile: File | undefined) => {
    questionFile && onHandleFile?.(questionFile)
  }
  return (
    <div className='col form-group'>
      <FileUploader multiple={false} handleChange={handleChange} name='file' types={fileTypes} />
      <p>{questionFile ? `File name: ${questionFile.name}` : 'no files uploaded yet'}</p>
    </div>
  )
}

UploadFile.propTypes = {}

export default UploadFile
