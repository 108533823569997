import * as Yup from 'yup'
import { t } from 'i18next'

const validationNewLetterSchema = Yup.object().shape({
  letterName: Yup.string()
    .required(t('letters:yup.letter-name.required'))
    .min(1, t('letters:yup.letter-name.min'))
    .max(100, t('letters:yup.letter-name.max')),
  subject: Yup.string().required(t('letters:yup.subject.required')).min(1, t('letters:yup.subject.min')).max(1000, t('letters:yup.subject.max')),
})

export { validationNewLetterSchema }

export default {}
