import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { ContentHeader } from 'shared/components/contentHeader/contentHeader.component'
import CircularProgress from '@mui/material/CircularProgress'
import { Link } from 'react-router-dom'
import { GridColDef } from '@mui/x-data-grid'
import EditIcon from '@mui/icons-material/Edit'
import Switch from '@mui/material/Switch'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import Typography from '@mui/material/Typography'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import DataTable from 'shared/components/dataTable/dataTable.component'
import { PaginationRequest } from 'ts/interfaces/request.interfaces'
import { useGetFactorActiveList, useGetFactorList, usePatchFactor } from 'api/factor/factor.api'
import { useGetFamilyActiveList, useGetFamilyFactores, usePostFamilyFactores, usePostDeactivateFamilyFactores } from 'api/family/family.api'
import { pushNotification } from 'shared/helpers/notifications/notifications'
import './styles.scss'

const Factor = () => {
  const [t] = useTranslation(['threeSixty', 'global'])

  const [pagination, setPagination] = useState<PaginationRequest>({
    pageNumber: 1,
    pageSize: 10,
  })

  const { data: factorListData, status: factorListDataStatus, refetch: refetchFactorList } = useGetFactorList(pagination)

  const { data: familyData, status: familyStatus } = useGetFamilyActiveList()
  const { data: familyFactorsData, status: familyFactoresStatus, refetch: refetchFamilyFactores } = useGetFamilyFactores()
  const { data: factorData, status: factorStatus } = useGetFactorActiveList()
  const { mutateAsync: mutateAsyncFamilyFactor, status: addFamilyFactorStatus } = usePostFamilyFactores()
  const { mutateAsync: mutateAsyncRemoveFamilyFactor, status: removeFamilyFactorStatus } = usePostDeactivateFamilyFactores()
  const { mutateAsync: enableFactorAsync, status: enableStatus } = usePatchFactor()

  const onPaginationChangeHandler = (pageSize: number, pageNumber: number, sortBy: string, sortOrder: 'asc' | 'desc') => {
    setPagination({ pageSize, pageNumber, sortBy, sortOrder })
  }

  const isChecked = (factorId: string, familyId: string): boolean =>
    familyFactorsData.length &&
    familyFactorsData.filter((factorFamily) => factorFamily.familyId === familyId && factorFamily.factorId === factorId).length > 0

  const onClusterTypeActiveChangeHandler = async (value: boolean, factorId: string) => {
    try {
      await enableFactorAsync({ id: factorId, isActive: value })

      if (['success', 'idle'].includes(enableStatus)) {
        pushNotification(`${value ? 'Activated' : 'Deactivated'} correctly`, 'success')
        await refetchFactorList()
      }
    } catch (error) {
      pushNotification((error?.response?.data?.error?.code as string) ?? 'Something went wrong')
    }
  }

  const handleFactorFamily = async (value: boolean, idFactor: string, idFamily: string) => {
    try {
      if (value) {
        await mutateAsyncFamilyFactor({ idFactor, idFamily, order: 1 })

        if (['success', 'idle'].includes(addFamilyFactorStatus)) {
          pushNotification(value ? t('messages.activated') : t('messages.deactivated'), 'success')
          await refetchFamilyFactores()
        }
      } else {
        await mutateAsyncRemoveFamilyFactor({ idFactor, idFamily })

        if (['success', 'idle'].includes(removeFamilyFactorStatus)) {
          pushNotification(value ? t('messages.activated') : t('messages.deactivated'), 'success')
          await refetchFamilyFactores()
        }
      }
    } catch (error) {
      pushNotification((error?.response?.data?.error?.code as string) ?? t('messages.error'))
    }
  }

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: t('factor.table-headers.factor'),
      flex: 1,
    },
    {
      field: 'clusterName',
      headerName: t('factor.table-headers.cluster-name'),
      flex: 1,
    },
    {
      field: 'description',
      headerName: t('factor.table-headers.description'),
      flex: 1,
    },
    {
      field: 'editFactor',
      headerName: t('global:datatable.edit-col'),
      width: 100,
      sortable: false,
      disableColumnMenu: true,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        const factorId: string = params.row.id

        return (
          <Link to={`/factor/edit/${factorId}`}>
            <EditIcon />
          </Link>
        )
      },
    },
    {
      field: 'activeFactor',
      headerName: t('global:datatable.active-col'),
      width: 100,
      sortable: false,
      disableColumnMenu: true,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        const factorId: string = params.row.id
        const isActive: boolean = params.row.isActive

        return <Switch checked={isActive} onChange={(_, value) => onClusterTypeActiveChangeHandler(value, factorId)} />
      },
    },
  ]

  const compare = (a: any, b: any) => {
    if (a.clusterName < b.clusterName) {
      return -1
    }

    if (a.clusterName > b.clusterName) {
      return 1
    }

    return 0
  }

  if (factorListDataStatus === 'loading') return <CircularProgress />

  console.log('factorData', factorData)

  return (
    <>
      <ContentHeader title={t('global:sidebar.factors')} breadCrumb={{ home: t('global:sidebar.home'), page: t('global:sidebar.factors') }} />

      <section className='content'>
        <div className='container-fluid'>
          <div className='card card-primary card-outline'>
            <div className='card-header border-0'>
              <div className='d-flex justify-content-between'>
                <h3 className='card-title'>{t('factor.header')}</h3>
                <Link to='/factor/new'>{t('factor.new-factor-button')}</Link>
              </div>
            </div>

            <div className='card-body'>
              <DataTable
                data={factorListData?.data ?? []}
                columns={columns}
                getRowId={(row) => row.id}
                totalRows={factorListData?.totalEntitiesCount}
                pageSize={pagination.pageSize}
                pageNumber={pagination.pageNumber}
                sortBy={pagination.sortBy}
                sortOrder={pagination.sortOrder}
                serverMode
                onPaginationChangeHandler={onPaginationChangeHandler}
              />

              <hr />

              <h3>Agregar Familia a Factor</h3>
              {[familyStatus, familyFactoresStatus, factorStatus].includes('loading') ? (
                <CircularProgress />
              ) : (
                <div className='row'>
                  <div className='col-md-12'>
                    {factorData?.map((factor) => (
                      <Accordion key={factor.clusterId}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls='panel1a-content' id='panel1a-header'>
                          <Typography>{factor.clusterName}</Typography>
                        </AccordionSummary>
                        <AccordionDetails className='table-content-factor'>
                          <table className='table'>
                            <thead>
                              <tr>
                                <th>Families:</th>
                                {familyData?.map((family) => (
                                  <th key={family.id}>{family.name}</th>
                                ))}
                              </tr>
                            </thead>
                            <tbody>
                              {factor.factors
                                ?.filter((factor) => factor.isActive)
                                .sort(compare)
                                .map((factor) => (
                                  <tr key={factor.id}>
                                    <td>
                                      <p style={{ fontSize: '.7rem', color: 'gray' }}>{factor.clusterName}</p>
                                      {factor.name}
                                    </td>
                                    {familyData?.map((family) => (
                                      <td key={`sw-${factor.id}-${family.id}`}>
                                        <Switch
                                          checked={isChecked(factor.id, family.id)}
                                          onChange={(e, value) => handleFactorFamily(value, factor.id, family.id)}
                                        />
                                      </td>
                                    ))}
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        </AccordionDetails>
                      </Accordion>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Factor
