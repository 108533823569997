import { History } from 'history'
import { connectRouter } from 'connected-react-router'
import { loadingReducer, errorReducer } from 'redux/api/apiReducers'
import loginReducer from 'redux/login/login.reducer'
import userReducer from 'redux/user/user.reducer'
import userAdminReducer from 'redux/userAdmin/userAdmin.reducer'
import rootReducerQuestionnaire from 'redux/questionnaire/rootQuesionnaire'
import mailDistributionReducer from 'redux/mailDistribution/mailDistribution.reducer'
import scheduleReducer from 'redux/schedule/schedule.reducer'
import letterReducer from 'redux/letter/letter.reducer'
import letterScheduleReducer from 'redux/letterSchedule/letterSchedule.reducer'
import subscriptionReducer from 'redux/subscriptions/subscriptions.reducer'
import productReducer from 'redux/products/products.reducer'
import plansReducer from './plans/plans.reducer'
import familyListReducer from 'redux/threeSixty/family/family.reducer'
import clusterListReducer from 'redux/threeSixty/cluster/cluster.reducer'
import clusterTypeListReducer from 'redux/threeSixty/clusterType/clusterType.reducer'
import roleListReducer from 'redux/threeSixty/role/role.reducer'

const rootReducer = (history: History<any>) => ({
  loadingReducer,
  errorReducer,
  user: userReducer,
  // userAdmin: userAdminReducer,
  // login: loginReducer,
  // questionnaire: rootReducerQuestionnaire,
  // mailDistribution: mailDistributionReducer,
  // schedule: scheduleReducer,
  // letter: letterReducer,
  // letterSchedule: letterScheduleReducer,
  // subscription: subscriptionReducer,
  // product: productReducer,
  // plans: plansReducer,
  // family: familyListReducer,
  // cluster: clusterListReducer,
  // clusterType: clusterTypeListReducer,
  // role: roleListReducer,
  router: connectRouter(history),
})

export default rootReducer
