import { useEffect, useState } from 'react'
import Cookies from 'js-cookie'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useForm, SubmitHandler } from 'react-hook-form'
import { RegisterForm, AlertProps } from 'ts/interfaces/app.interfaces'
import { yupResolver } from '@hookform/resolvers/yup'
import CircularProgress from '@material-ui/core/CircularProgress'
import Input from 'shared/components/input/input.component'
import LoginWrapper from 'shared/components/loginWrapper/loginWrapper.component'
import AlertComponent from 'shared/components/alert/alert.component'
import { postUser } from 'api/user/user.api'

import { validationRegisterSchema } from './login.validation'

const Register = () => {
  const [t] = useTranslation('login')

  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [notification, setNotification] = useState<AlertProps>({
    show: false,
    type: 'success',
    message: 'User added correctly',
    position: 'center',
  })

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<RegisterForm>({
    resolver: yupResolver(validationRegisterSchema),
  })
  const autoClose = () => {
    setTimeout(() => {
      setNotification({
        ...notification,
        show: false,
      })
    }, 2000)
  }

  const onSubmit: SubmitHandler<RegisterForm> = async (data) => {
    setLoading(true)
    const resp = await postUser(data)
    const { status, data: responseData } = resp

    try {
      if (status === 200) {
        if (responseData.token) {
          Cookies.set('token', responseData.token)
          Cookies.set('tenant', responseData.tenantId)
          // const message = `Welcome, ${responseData.username}!`
        }

        setNotification({
          show: true,
          type: 'success',
          message: 'User added correctly',
          position: 'center',
        })

        reset()
        autoClose()
      } else {
        console.log('hereee')
        setNotification({
          ...notification,
          type: 'error',
          message: '',
          show: true,
        })
      }
    } catch (error) {
      console.log('error', error)

      setNotification({
        ...notification,
        type: 'error',
        message: error,
        show: true,
      })

      reset()
      autoClose()
    } finally {
      setLoading(false)
      navigate('/')
    }
  }

  const alertComponent = () =>
    notification.show && <AlertComponent type={notification.type} message={notification.message} position={notification.position} />

  return (
    <div className='hold-transition login-page'>
      {alertComponent()}
      {loading && <CircularProgress />}

      <LoginWrapper title={t('register.title')}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className='input-group mb-3'>
            <Input
              type='text'
              name='username'
              reg={register('username')}
              errors={errors}
              icon='fa fa-user'
              placeholder={t('register.form.username-placeholder')}
            />
          </div>
          <div className='input-group mb-3'>
            <Input
              type='email'
              name='email'
              reg={register('email')}
              errors={errors}
              icon='fa fa-envelope'
              placeholder={t('register.form.email-placeholder')}
            />
          </div>
          <div className='input-group mb-3'>
            <Input
              type='password'
              name='password'
              reg={register('password')}
              errors={errors}
              icon='fa fa-lock'
              placeholder={t('register.form.password-placeholder')}
            />
          </div>

          <div className='input-group mb-3'>
            <Input
              type='password'
              name='confirmPassword'
              reg={register('confirmPassword')}
              errors={errors}
              icon='fa fa-lock'
              placeholder={t('register.form.confirm-password-placeholder')}
            />
          </div>

          <div className='row'>
            <div className='col-8'>
              <div className='icheck-primary'>
                <Input type='checkbox' name='acceptTerms' reg={register('acceptTerms')} errors={errors} id='acceptTerms' className='' />
                &nbsp;
                <label htmlFor='acceptTerms' className='form-check-label'>
                  {t('register.form.accept-terms')} <a href='#'>{t('register.form.accept-terms-link')}</a>
                </label>
              </div>
            </div>
            {/* <!-- /.col --> */}
            <div className='col-4'>
              <button type='submit' className='btn btn-primary btn-block'>
                {t('register.form.register-button')}
              </button>
            </div>
            {/* <!-- /.col --> */}
          </div>
        </form>

        <div className='social-auth-links text-center'>
          <a href='#' className='btn btn-block btn-primary'>
            <i className='fab fa-linkedin mr-2'></i>
            {t('register.sign-in-using-linkedin-button')}
          </a>
        </div>

        <Link to='/' className='text-center'>
          {t('register.already-have-membership')}
        </Link>
      </LoginWrapper>
    </div>
  )
}

export default Register
