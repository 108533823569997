import { useGetLetterActiveList, useGetScheduleActiveList } from 'api/letterSchedule/letterSchedule.api'
import { useEffect, useState } from 'react'
import SelectAutocomplete from 'shared/components/selectInput/selectAutocomplete.component'
import { NewSeasonForm, SelectOptionI } from 'ts/interfaces/app.interfaces'
import { SubmitHandler, useForm } from 'react-hook-form'
import Input from 'shared/components/input/input.component'
import { CircularProgress } from '@mui/material'
import { validationCreateSeason } from 'pages/assignQuestionnaire/validation'
import { yupResolver } from '@hookform/resolvers/yup'
import { getSeason, useCreateSeason, useGetActiveSeason } from 'api/season/season.api'
import { pushNotification } from 'shared/helpers/notifications/notifications'

export const AssignSeasonQuestionnaire = ({ onSessionSelected, seasonSelected, onHandleActiveEdit, isEditActive }) => {
  const [letterOptions, setLetterOptions] = useState<SelectOptionI[]>([])
  const [scheduleOptions, setScheduleOptions] = useState<SelectOptionI[]>([])
  const [seasonOptions, setSeasonOptions] = useState<SelectOptionI[]>([])

  const { isLoading: isLoadingLetter, data: letterData, status: letterDataStatus } = useGetLetterActiveList()
  const { isLoading: isLoadingSchedule, data: scheduleData, status: scheduleStatus } = useGetScheduleActiveList()
  const { isLoading: isLoadingSeason, data: seasonData, status: statusSeason, refetch } = useGetActiveSeason()

  const { mutateAsync, isLoading, status: statusCreateSeason } = useCreateSeason()

  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
    setValue,
  } = useForm<NewSeasonForm>({
    resolver: yupResolver(validationCreateSeason),
  })

  const getSeasonSelected = () => {
    getSeason(seasonSelected)
      .then((response) => {
        console.log('response', response.data)

        reset({
          name: response.data.name,
          startDate: response.data.startDate.split('T')[0].toString(),
          endDate: response.data.endDate.split('T')[0].toString(),
          invitationLetterId: response.data.invitationLetter.id,
          reminderLetterId: response.data.reminderLetter.id,
          scheduleId: response.data.schedule.id,
        })
      })
      .catch((error) => console.log(error))
  }

  useEffect(() => {
    if (seasonSelected) {
      getSeasonSelected()
    }
  }, [seasonSelected])

  useEffect(() => {
    if (letterData) {
      setLetterOptions(
        letterData.map((letter) => {
          return { value: letter.id, label: letter.name }
        }),
      )
    }
  }, [letterData])

  useEffect(() => {
    if (scheduleData) {
      setScheduleOptions(
        scheduleData.map((schedule) => {
          return { value: schedule.id, label: schedule.name }
        }),
      )
    }
  }, [scheduleData])

  useEffect(() => {
    if (seasonData) {
      setSeasonOptions(
        seasonData.map((season) => {
          return { value: season.id, label: season.name }
        }),
      )
    }
  }, [seasonData])

  const saveSeason: SubmitHandler<NewSeasonForm> = async (data) => {
    try {
      await mutateAsync(data)

      if (statusCreateSeason === 'success' || statusCreateSeason === 'idle') {
        reset({})
        pushNotification('Programacion creada correctamente', 'success')
        await refetch()
      }
    } catch (error) {
      pushNotification('Error al guardar la programacion', 'error')
    }
  }

  const handleChangeSeason = () => {
    onSessionSelected(true)
  }

  if (isLoadingLetter || isLoadingSchedule || isLoadingSeason) {
    return <CircularProgress />
  }

  return (
    <section className='content'>
      <div className='container-fluid'>
        <div className='card card-primary card-outline'>
          <div className='card-header border-0'>
            <div className='d-flex justify-content-between'>
              <h3 className='card-title'>Por favor seleccione o cree la programacion a utilizar</h3>
            </div>
          </div>

          <form id='new-letter-form' className='card-body' onSubmit={handleSubmit(saveSeason)}>
            <div className='row'>
              <div className='col-md-3'>
                <div className='form-group'>
                  <label htmlFor='sessionId'>Programaciones disponibles</label>
                  <SelectAutocomplete
                    id='sessionId'
                    name='userId'
                    elements={seasonOptions}
                    errors={errors}
                    setFieldValue={(value) => onSessionSelected(value)}
                    placeholder='Selecciona la session'
                  />
                </div>
              </div>
              {seasonSelected && (
                <div className='col-md-1'>
                  <div className='form-group'>
                    <label htmlFor='editBtn'>&nbsp;</label>
                    <button
                      type='button'
                      className='btn btn-primary form-control'
                      onClick={isEditActive ? () => onHandleActiveEdit(false) : handleSubmit(saveSeason)}
                    >
                      {isEditActive ? 'Edit' : 'Save'}
                    </button>
                  </div>
                </div>
              )}
            </div>
            <h3>{!isEditActive ? 'Actualizar' : 'Crear nueva'} programacion</h3>
            <div className='row'>
              <div className='col-md-4'>
                <div className='form-group'>
                  <label htmlFor='name'>Nombre de Programacion</label>
                  <Input id='name' type='text' name='name' reg={register('name')} errors={errors} placeholder='Nombre de programacion' />
                </div>
              </div>
              <div className='col-md-4'>
                <div className='form-group'>
                  <label htmlFor='startDate'>Inicio de Programacion</label>
                  <Input id='startDate' type='date' name='startDate' reg={register('startDate')} errors={errors} placeholder='Fecha de inicio' />
                </div>
              </div>
              <div className='col-md-4'>
                <div className='form-group'>
                  <label htmlFor='endDate'>Fin de Programacion</label>
                  <Input id='endDate' type='date' name='endDate' reg={register('endDate')} errors={errors} placeholder='Fecha de fin' />
                </div>
              </div>
              <div className='col-md-4'>
                <div className='form-group'>
                  <label htmlFor='invitationLetterId'>Carta a utilizar</label>
                  <SelectAutocomplete
                    id='letter-selector'
                    name='invitationLetterId'
                    elements={letterOptions}
                    errors={errors}
                    setFieldValue={(value) => setValue('invitationLetterId', value)}
                    placeholder='Seleccion una carta'
                  />
                </div>
              </div>
              <div className='col-md-4'>
                <div className='form-group'>
                  <label htmlFor='reminderLetterId'>Carta de recordatorio</label>
                  <SelectAutocomplete
                    id='letter-selector'
                    name='reminderLetterId'
                    elements={letterOptions}
                    errors={errors}
                    setFieldValue={(value) => setValue('reminderLetterId', value)}
                    placeholder='Seleccion una carta'
                  />
                </div>
              </div>
              <div className='col-md-4'>
                <div className='form-group'>
                  <label htmlFor='scheduleId'>Programacion a utilizar</label>
                  <SelectAutocomplete
                    id='scheduleId'
                    name='scheduleId'
                    elements={scheduleOptions}
                    errors={errors}
                    setFieldValue={(value) => setValue('scheduleId', value)}
                    placeholder='Seleccion la programacion'
                  />
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='offset-9 col-md-3'>
                <button className='btn btn-success btn-block' type='submit'>
                  Guardar
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  )
}
