import { SubmitHandler, useForm } from 'react-hook-form'
import { Link, useNavigate } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'
import { NewTenantForm } from 'ts/interfaces/app.interfaces'

import { ContentHeader } from 'shared/components/contentHeader/contentHeader.component'
import Input from 'shared/components/input/input.component'
import { useTranslation } from 'react-i18next'
import { pushNotification } from 'shared/helpers/notifications/notifications'
import { useCreateTenant } from 'api/tenant/tenant.api'
import { validationNewTenantSchema } from 'pages/tenant/tenant.validation'

const NewTenant = () => {
  const [t] = useTranslation(['tenant', 'global'])

  const { mutateAsync: createTenant, status: createTenantStatus } = useCreateTenant()
  const navigate = useNavigate()

  // Yup:
  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm<NewTenantForm>({
    resolver: yupResolver(validationNewTenantSchema),
  })
  const tenantName = watch('name', t('tenant.creation-default-name') as string)

  // Handlers:
  const onSubmit: SubmitHandler<NewTenantForm> = async data => {
    try {
      await createTenant({
        name: data?.name,
        description: data?.description,
        expirationDate: data?.expirationDate,
        companyName: data?.companyName,
        country: data?.country,
        privacyNotice: data?.privacyNotice,
        registrationCode: data?.registrationCode,
      })

      if (createTenantStatus === 'success' || createTenantStatus === 'idle') {
        pushNotification(t('tenant:messages.created'), 'success')
        reset({})
        navigate('/tenant')
      }
    } catch (error) {
      console.log(error)
      pushNotification((error?.response?.data?.error?.code as string) ?? t('tenant:messages.error'), 'error')
    }
  }

  return (
    <>
      <ContentHeader
        title={t('tenant.creation-header')}
        breadCrumb={{ home: t('global:sidebar.home'), page: t('global:sidebar.tenants') }}
      />

      <section className='content rounded-0'>
        <div className='container-fluid'>
          <div className='callout callout-primary'>
            <h5>{t('tenant.creation-description-title')}</h5>
            <p>{t('tenant.creation-description-body')}</p>
          </div>
        </div>
      </section>

      <section className='content rounded-0'>
        <div className='container-fluid'>
          <div className='card card-primary card-outline'>
            <div className='card-header border-0'>
              <div className='d-flex justify-content-between'>
                <h3 className='card-title'>
                  <strong>{tenantName}</strong>
                </h3>
                <Link to='/tenant'>{t('global:cruds.back-button')}</Link>
              </div>
            </div>

            <div className='card-body'>
              <form id='new-letter-form' className='row' onSubmit={handleSubmit(onSubmit)}>
                <div className='col-md-12'>
                  <div className='row'>
                    <div className='col-sm-6'>
                      <div className='form-group'>
                        <label htmlFor='name'>{t('tenant.table-headers.tenant')}</label>
                        <Input
                          type='text'
                          name='name'
                          reg={register('name')}
                          errors={errors}
                          placeholder={t('tenant.table-headers.tenant')}
                        />
                      </div>
                    </div>

                    <div className='col-sm-6'>
                      <div className='form-group'>
                        <label htmlFor='subject'>{t('tenant.table-headers.company-name')}</label>
                        <Input
                          type='text'
                          name='companyName'
                          reg={register('companyName')}
                          errors={errors}
                          placeholder={t('tenant.table-headers.company-name')}
                        />
                      </div>
                    </div>

                    <div className='col-sm-6'>
                      <div className='form-group'>
                        <label htmlFor='letterName'>{t('tenant.table-headers.description')}</label>
                        <Input
                          type='text'
                          name='description'
                          reg={register('description')}
                          errors={errors}
                          placeholder={t('tenant.table-headers.description')}
                        />
                      </div>
                    </div>

                    <div className='col-sm-6'>
                      <div className='form-group'>
                        <label htmlFor='subject'>{t('tenant.table-headers.country')}</label>
                        <Input
                          type='text'
                          name='country'
                          reg={register('country')}
                          errors={errors}
                          placeholder={t('tenant.table-headers.country')}
                        />
                      </div>
                    </div>

                    <div className='col-sm-6'>
                      <div className='form-group'>
                        <label htmlFor='subject'>{t('tenant.table-headers.registration-code')}</label>
                        <Input
                          type='text'
                          name='registrationCode'
                          reg={register('registrationCode')}
                          errors={errors}
                          placeholder={t('tenant.table-headers.registration-code')}
                        />
                      </div>
                    </div>

                    <div className='col-sm-6'>
                      <div className='form-group'>
                        <label htmlFor='subject'>{t('tenant.table-headers.expiration-date')}</label>
                        <Input
                          type='date'
                          name='expirationDate'
                          reg={register('expirationDate')}
                          errors={errors}
                          placeholder={t('tenant.table-headers.expiration-date')}
                        />
                      </div>
                    </div>

                    <div className='col-sm-12'>
                      <div className='form-group'>
                        <label htmlFor='subject'>{t('tenant.table-headers.privacy-notice')}</label>
                        <Input
                          type='textarea'
                          name='privacyNotice'
                          reg={register('privacyNotice')}
                          errors={errors}
                          placeholder={t('tenant.table-headers.privacy-notice')}
                        />
                      </div>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='offset-md-9 col-md-3'>
                      <div className='form-group'>
                        {/* <div className="col-3"> */}
                        <button type='submit' className='btn btn-success btn-block'>
                          {t('global:cruds.save-button')}
                        </button>
                        {/* </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default NewTenant
