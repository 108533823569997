import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import validateStatusSuccess from 'shared/helpers/api'
import { PaginationRequest } from 'ts/interfaces/request.interfaces'
import { pushNotification } from 'shared/helpers/notifications/notifications'

import { ContentHeader } from 'shared/components/contentHeader/contentHeader.component'
import { Link } from 'react-router-dom'
import { GridColDef } from '@mui/x-data-grid'
import EditIcon from '@mui/icons-material/Edit'
import Switch from '@mui/material/Switch'
import { CircularProgress } from '@mui/material'
import DataTable from 'shared/components/dataTable/dataTable.component'
import { useEnableTenant, useGetTenantList, useSetAsDefaultTenant } from 'api/tenant/tenant.api'
import Moment from 'moment'

const Tenant = () => {
  const [t] = useTranslation(['tenant', 'global'])

  const [pagination, setPagination] = useState<PaginationRequest>({
    pageNumber: 1,
    pageSize: 10,
  })

  const { data: tenantData, status: getTenantStatus, refetch: getTenantRefetch } = useGetTenantList(pagination)
  const { mutateAsync: enableTenant, status: enableTenantStatus } = useEnableTenant()
  const { mutateAsync: setAsDefaultTenant, status: setAsDefaultTenantStatus } = useSetAsDefaultTenant()

  // Handlers:
  const onTenantActiveChangeHandler = async (value: boolean, tenantId: string) => {
    try {
      await enableTenant({ id: tenantId, isActive: value })

      if (validateStatusSuccess(enableTenantStatus)) {
        pushNotification(value ? t('tenant:messages.activated') : t('tenant:messages.deactivated'), 'success')
        await getTenantRefetch()
      }
    } catch (error) {
      pushNotification((error?.response?.data?.message as string) ?? t('tenant:messages.error'))
    }
  }

  const onSetAsDefaultTenantChangeHandler = async (value: boolean, tenantId: string) => {
    try {
      await setAsDefaultTenant({ id: tenantId })

      if (validateStatusSuccess(setAsDefaultTenantStatus)) {
        pushNotification(t('tenant:messages.set-as-default'), 'success')
        await getTenantRefetch()
      }
    } catch (error) {
      pushNotification((error?.response?.data?.code as string) ?? t('tenant:messages.error'))
    }
  }

  const onPaginationChangeHandler = (pageSize: number, pageNumber: number, sortBy: string, sortOrder: 'asc' | 'desc') => {
    setPagination({ pageSize, pageNumber, sortBy, sortOrder })
  }

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: t('tenant.table-headers.tenant'),
      width: 300,
    }, {
      field: 'companyName',
      headerName: t('tenant.table-headers.company-name'),
      flex: 1,
    }, {
      field: 'description',
      headerName: t('tenant.table-headers.description'),
      flex: 1,
    }, {
      field: 'expirationDate',
      headerName: t('tenant.table-headers.expiration-date'),
      flex: 1,
      valueFormatter: (params) => Moment(params.value).format('YYYY-MM-DD'),
    }, {
      field: 'editTenant',
      headerName: t('global:datatable.edit-col'),
      width: 100,
      sortable: false,
      disableColumnMenu: true,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        const tenantId: string = params.row.id

        return (
          <Link to={`/tenant/edit/${tenantId}`}>
            <EditIcon />
          </Link>
        )
      },
    }, {
      field: 'isDefaultTenant',
      headerName: t('tenant.table-headers.is-default-tenant'),
      width: 140,
      sortable: false,
      disableColumnMenu: true,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        const tenantId: string = params.row.id
        const isDefaultTenant: boolean = params.row.isDefaultTenant

        return <Switch checked={isDefaultTenant} onChange={(_, value) => onSetAsDefaultTenantChangeHandler(value, tenantId)} />
      },
    }, {
      field: 'activeTenant',
      headerName: t('global:datatable.active-col'),
      width: 100,
      sortable: false,
      disableColumnMenu: true,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        const tenantId: string = params.row.id
        const isActive: boolean = params.row.isActive

        return <Switch checked={isActive} onChange={(_, value) => onTenantActiveChangeHandler(value, tenantId)} />
      },
    },
  ]

  if (getTenantStatus === 'loading') return <CircularProgress />

  return (
    <>
      <ContentHeader
        title={t('global:sidebar.tenants')}
        breadCrumb={{ home: t('global:sidebar.home'), page: t('global:sidebar.tenants') }}
      />

      <section className='content'>
        <div className='container-fluid'>
          <div className='card card-primary card-outline'>
            <div className='card-header border-0'>
              <div className='d-flex justify-content-between'>
                <h3 className='card-title'>{t('tenant.header')}</h3>
                <Link to='/tenant/new'>{t('tenant.new-tenant-button')}</Link>
              </div>
            </div>

            <div className='card-body'>
              <DataTable
                data={tenantData.data}
                columns={columns}
                getRowId={(row) => row.id}
                pageSize={pagination.pageSize}
                pageNumber={pagination.pageNumber}
                totalRows={tenantData?.totalEntitiesCount}
                sortBy={pagination.sortBy}
                sortOrder={pagination.sortOrder}
                serverMode
                onPaginationChangeHandler={onPaginationChangeHandler}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Tenant
