import { useTranslation } from 'react-i18next'

import { useEffect, useState } from 'react'
import { DataGrid, GridColDef, GridFeatureMode, GridRowIdGetter, GridSortDirection, GridSortModel, GridValidRowModel } from '@mui/x-data-grid'

export interface DisplayedRowsObject {
  from: number
  to: number
  count: number
}

export interface DataTableProp<T> {
  data: T[]
  columns: GridColDef[]
  getRowId?: GridRowIdGetter<GridValidRowModel>
  serverMode?: boolean
  noRowsLabel?: string
  rowsPerPage?: number[]
  rowsPerPageLabel?: string
  displayedRowsLabelTemplate?: string
  totalRows?: number // serverMode required
  pageSize?: number
  pageNumber?: number
  sortBy?: string
  sortOrder?: GridSortDirection
  onPaginationChangeHandler?: (pageSize: number, pageNumber: number, sortBy: string, sortOrder: 'asc' | 'desc') => void
}

const DataTable = <T,>({
  data,
  columns,
  getRowId,
  serverMode,
  noRowsLabel,
  rowsPerPage,
  rowsPerPageLabel,
  displayedRowsLabelTemplate,
  totalRows,
  pageSize,
  pageNumber,
  sortBy,
  sortOrder,
  onPaginationChangeHandler,
}: DataTableProp<T>) => {
  const [t] = useTranslation('global')
  // States:
  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: sortBy ?? '',
      sort: sortOrder,
    },
  ])
  const [currentPageSize, setCurrentPageSize] = useState<number>(pageSize ?? 10)
  const [currentPageNumber, setCurrentPageNumber] = useState<number>(pageNumber ? pageNumber - 1 : 0)

  // Consts
  const rowsPerPageConfiguration: number[] = rowsPerPage ?? [5, 10, 20, 50, 100]
  const featureMode: GridFeatureMode = serverMode ? 'server' : 'client'
  const setDisplayedRowsLabel = (displayedRows: DisplayedRowsObject): string => {
    const { from, to, count } = displayedRows
    const label = displayedRowsLabelTemplate ? displayedRowsLabelTemplate : t('datatable.displayed-rows');
    return label
      .replace(':from', `${from}`)
      .replace(':to', `${to}`)
      .replace(':count', `${count}`)
  }

  // Effects:
  useEffect(() => {
    if (onPaginationChangeHandler) {
      const { field, sort } = sortModel?.length > 0 ? sortModel[0] : { field: '', sort: null }
      onPaginationChangeHandler(currentPageSize, currentPageNumber + 1, field, sort as 'asc' | 'desc')
    }
  }, [currentPageNumber, currentPageSize, sortModel])

  return (
    <DataGrid
      rows={data}
      rowCount={totalRows}
      getRowId={getRowId}
      columns={columns}
      pageSize={currentPageSize}
      page={currentPageNumber}
      sortModel={sortModel}
      rowsPerPageOptions={rowsPerPageConfiguration}
      sortingMode={featureMode}
      paginationMode={featureMode}
      onPageSizeChange={(ps) => setCurrentPageSize(ps)}
      onPageChange={(p) => setCurrentPageNumber(p)}
      onSortModelChange={(sm) => setSortModel(sm)}
      autoHeight
      disableColumnFilter
      isRowSelectable={() => false}
      localeText={{ noRowsLabel: noRowsLabel ?? t('datatable.no-rows')}}
      componentsProps={{
        pagination: {
          labelRowsPerPage: rowsPerPageLabel ?? t('datatable.rows-per-page'),
          labelDisplayedRows: setDisplayedRowsLabel,
        }
      }}
    />
  )
}

export default DataTable
