import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useParams } from 'react-router-dom'
import { ContentHeader } from 'shared/components/contentHeader/contentHeader.component'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import Typography from '@mui/material/Typography'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { useGetQuestionByLevel, useUpdateAnswer } from 'api/question/question.api'
import CheckIcon from '@mui/icons-material/Check'
import DeleteIcon from '@mui/icons-material/Delete'
import './style.scss'
import CircularProgress from '@mui/material/CircularProgress'
import Switch from '@mui/material/Switch'
import { useGetLevelById } from 'api/level/level.api'
import { usePatchQuestion } from 'api/questionnaire/questionnaire.api'
import { pushNotification } from 'shared/helpers/notifications/notifications'

const Questions = () => {
  const { levelId } = useParams()
  const [edit, setEdit] = useState(false)
  const { data: levelData, isLoading: levelIsLoading } = useGetLevelById(levelId)
  const { data, isLoading, refetch } = useGetQuestionByLevel(levelId)
  const { mutateAsync, status: statusUpdate } = useUpdateAnswer()
  const { mutateAsync: mutateAsyncQuestion, status: statusQuestion } = usePatchQuestion()
  const [t] = useTranslation(['threeSixty', 'global'])

  const editQuestion = (active: boolean) => {
    console.log('Edit question')
    setEdit(active)
  }

  const saveQuestion = async (answer, e) => {
    const resp = await mutateAsync({
      answerId: answer.id,
      answer: answer.answer,
      points: answer.pointAnswer,
    })
  }

  const handleEnableQuestion = async (value, questionId) => {
    try {
      await mutateAsyncQuestion({
        id: questionId,
        enable: value,
      })

      if (['success', 'idle'].includes(statusQuestion)) {
        pushNotification(value ? t('messages.activated') : t('messages.deactivated'), 'success')
        await refetch()
      }
    } catch (error) {
      console.log('error', error)
    }
  }

  if (isLoading || levelIsLoading) return <CircularProgress />

  return (
    <>
      <ContentHeader title={t('level.question.header')} breadCrumb={{ home: t('global:sidebar.home'), page: t('global:sidebar.questions') }} />

      <section className='content'>
        <div className='container-fluid'>
          <div className='card card-primary card-outline'>
            <div className='card-header border-0'>
              <div className='d-flex justify-content-between'>
                <h3 className='card-title'>{`${t('level.question.questions-level')}: ${levelData.levelNumber} - ${levelData.description}`}</h3>
                <Link to={`/level`}>{t('level.question.return-to-levels-button')}</Link>
                <Link to={`/level/questions/new/${levelId}`}>{t('level.question.new-question-button')}</Link>
              </div>
            </div>

            <div className='card-body'>
              {t('level.question.questions')}:
              {data?.map((question) => (
                <Accordion key={question.question}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls='panel1a-content' id='panel1a-header'>
                    <Typography>{question.question}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className='row'>
                      <div className='col-md-3'>
                        <label htmlFor='activeQuestion'>{t('level.question.active-question-switch')}</label>
                        <Switch id='activeQuestion' checked={question.isActive} onChange={(_, value) => handleEnableQuestion(value, question.id)} />
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-md-1'>
                        <button type='button' className='btn btn-outline-primary mb-2'>
                          <Link to={`/level/questions/edit/${levelId}/${question.id}`}>{t('level.question.edit-question-button')}</Link>
                        </button>
                      </div>
                    </div>
                    {!edit && (
                      <table className='table'>
                        <tr>
                          <th>{t('level.question.table-cols.answer')}</th>
                          <th style={{ textAlign: 'center' }}>{t('level.question.table-cols.points')}</th>
                          <th style={{ textAlign: 'center' }}>{t('level.question.table-cols.delete')}</th>
                        </tr>
                        <tbody>
                          {question?.answerViewModels?.map((answer) => (
                            <tr key={answer.id}>
                              <td>{answer.answer}</td>
                              <td width={150} align='center'>
                                {answer.pointAnswer}
                              </td>
                              <td width={100} align='center'>
                                <DeleteIcon className='icons-spy' />
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    )}
                    {edit && (
                      <table className='table'>
                        <tr>
                          <th>Answer</th>
                          <th>Points</th>
                          <th>Save</th>
                        </tr>
                        <tbody>
                          {question?.answerViewModels?.map((answer) => (
                            <tr key={answer.id}>
                              <td>
                                <input type='text' id={answer.id} defaultValue={answer.answer} />
                              </td>
                              <td>
                                <input type='number' id={answer.id} defaultValue={answer.pointAnswer} />
                              </td>
                              <td>
                                <CheckIcon className='icons-spy' onClick={(e) => saveQuestion(answer, e)} />
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    )}
                  </AccordionDetails>
                </Accordion>
              ))}
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Questions
