import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useEnableLetter, useGetLetterList } from 'api/letter/letter.api'
import validateStatusSuccess from 'shared/helpers/api'
import { PaginationRequest } from 'ts/interfaces/request.interfaces'
import { pushNotification } from 'shared/helpers/notifications/notifications'

import { ContentHeader } from 'shared/components/contentHeader/contentHeader.component'
import { Link } from 'react-router-dom'
import { GridColDef } from '@mui/x-data-grid'
import EditIcon from '@mui/icons-material/Edit'
import Switch from '@mui/material/Switch'
import { CircularProgress } from '@mui/material'
import DataTable from 'shared/components/dataTable/dataTable.component'

const Letter = () => {
  const [t] = useTranslation(['letters', 'global'])

  const [pagination, setPagination] = useState<PaginationRequest>({
    pageNumber: 1,
    pageSize: 10,
  })

  const { data, status, refetch } = useGetLetterList(pagination)
  const { mutateAsync, status: statusActive } = useEnableLetter()

  // Handlers:
  const onLetterActiveChangeHandler = async (value: boolean, letterId: string) => {
    try {
      await mutateAsync({ id: letterId, isActive: value })

      if (validateStatusSuccess(statusActive)) {
        pushNotification(value ? t('letters:messages.activated') : t('letters:messages.deactivated'), 'success')
        await refetch()
      }
    } catch (error) {
      pushNotification((error?.response?.data?.error?.code as string) ?? t('letters:messages.error'))
    }
  }

  const onPaginationChangeHandler = (pageSize: number, pageNumber: number, sortBy: string, sortOrder: 'asc' | 'desc') => {
    setPagination({ pageSize, pageNumber, sortBy, sortOrder })
  }

  const columns: GridColDef[] = [
    { field: 'name', headerName: t('letters:letter.table-headers.letter'), width: 300 },
    { field: 'subject', headerName: t('letters:letter.table-headers.subject'), flex: 1 },
    {
      field: 'editLetter',
      headerName: t('global:datatable.edit-col'),
      width: 100,
      sortable: false,
      disableColumnMenu: true,
      headerAlign: 'center',
      align: 'center',
      renderCell: params => {
        const letterId: string = params.row.id
        return (
          <Link to={`/letter/edit/${letterId}`}>
            <EditIcon />
          </Link>
        )
      },
    }, {
      field: 'activeLetter',
      headerName: t('global:datatable.active-col'),
      width: 100,
      sortable: false,
      disableColumnMenu: true,
      headerAlign: 'center',
      align: 'center',
      renderCell: params => {
        const letterId: string = params.row.id
        const isActive: boolean = params.row.isActive
        return (
          <Switch
            checked={isActive}
            onChange={(_, value) => onLetterActiveChangeHandler(value, letterId)}
          />
        )
      },
    },
  ]

  if (status === 'loading') return <CircularProgress />

  return (
    <>
      <ContentHeader
        title={t('global:sidebar.letters')}
        breadCrumb={{ home: t('global:sidebar.home'), page: t('global:sidebar.letters') }}
      />

      <section className='content'>
        <div className='container-fluid'>
          <div className='card card-primary card-outline'>

            <div className='card-header border-0'>
              <div className='d-flex justify-content-between'>
                <h3 className='card-title'>{t('letters:letter.header')}</h3>
                <Link to='/letter/new'>{t('letters:letter.new-letter-button')}</Link>
              </div>
            </div>

            <div className='card-body'>
              <DataTable
                data={data?.data}
                columns={columns}
                getRowId={row => row.id}
                pageSize={pagination.pageSize}
                pageNumber={pagination.pageNumber}
                totalRows={data?.totalEntitiesCount}
                sortBy={pagination.sortBy}
                sortOrder={pagination.sortOrder}
                serverMode
                onPaginationChangeHandler={onPaginationChangeHandler}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Letter
