import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useEnableSchedule, useGetScheduleList } from 'api/schedule/schedule.api'
import validateStatusSuccess from 'shared/helpers/api'
import { Link } from 'react-router-dom'
import { PaginationRequest } from 'ts/interfaces/request.interfaces'
import { pushNotification } from 'shared/helpers/notifications/notifications'

import DataTable from 'shared/components/dataTable/dataTable.component'
import { GridColDef } from '@mui/x-data-grid'
import EditIcon from '@mui/icons-material/Edit'
import Switch from '@mui/material/Switch'
import { ContentHeader } from 'shared/components/contentHeader/contentHeader.component'
import { CircularProgress } from '@mui/material'

const Schedule = () => {
  const [t] = useTranslation(['letters', 'global'])

  const [pagination, setPagination] = useState<PaginationRequest>({
    pageNumber: 1,
    pageSize: 10,
  })

  const { data, status, refetch } = useGetScheduleList(pagination)
  const { mutateAsync, status: statusActive } = useEnableSchedule()

  // Handlers:
  const onScheduleActiveChangeHandler = async (value: boolean, scheduleId: string) => {
    try {
      await mutateAsync({ id: scheduleId, isActive: value })

      if (validateStatusSuccess(statusActive)) {
        pushNotification(value ? t('letters:messages.activated') : t('letters:messages.deactivated'))
        await refetch()
      }
    } catch (error) {
      pushNotification((error?.response?.data?.error?.code as string) ?? 'Something went wrong')
    }
  }

  const onPaginationChangeHandler = (pageSize: number, pageNumber: number, sortBy: string, sortOrder: 'asc' | 'desc') => {
    setPagination({ pageSize, pageNumber, sortBy, sortOrder })
  }

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: t('letters:schedule.table-headers.schedule'),
      type: 'string',
      flex: 1,
    }, {
      field: 'totalOfSents',
      headerName: t('letters:schedule.table-headers.times-to-send'),
      type: 'string',
      flex: 1,
      valueFormatter: valueFormatter => `${valueFormatter.value} time(s)`,
    }, {
      field: 'frequency',
      headerName: t('letters:schedule.table-headers.frequency'),
      type: 'string',
      flex: 1,
      valueFormatter: valueFormatter => `${valueFormatter.value} day(s)`,
    }, {
      field: 'numberOfSents',
      headerName: t('letters:schedule.table-headers.sents'),
      type: 'string',
      flex: 1,
      valueFormatter: valueFormatter => `${valueFormatter.value} time(s)`,
    }, {
      field: 'editSchedule',
      headerName: t('global:datatable.edit-col'),
      width: 100,
      sortable: false,
      disableColumnMenu: true,
      headerAlign: 'center',
      align: 'center',
      renderCell: params => {
        const scheduleId: string = params.row.id
        return (
          <Link to={`/schedule/edit/${scheduleId}`}>
            <EditIcon />
          </Link>
        )
      },
    }, {
      field: 'activeSchedule',
      headerName: t('global:datatable.active-col'),
      width: 100,
      sortable: false,
      disableColumnMenu: true,
      headerAlign: 'center',
      align: 'center',
      renderCell: params => {
        const scheduleId: string = params.row.id
        const isActive: boolean = params.row.isActive
        return (
          <Switch
            checked={isActive}
            onChange={(_, value) => onScheduleActiveChangeHandler(value, scheduleId)}
          />
        )
      },
    },
  ]

  if (status === 'loading') return <CircularProgress />

  return (
    <>
      <ContentHeader
        title={t('global:sidebar.schedulers')}
        breadCrumb={{ home: t('global:sidebar.home'), page: t('global:sidebar.schedulers') }}
      />

      <section className='content'>
        <div className='container-fluid'>
          <div className='card card-primary card-outline'>

            <div className='card-header border-0'>
              <div className='d-flex justify-content-between'>
                <h3 className='card-title'>{t('letters:schedule.header')}</h3>
                <Link to='/schedule/new'>{t('letters:schedule.new-schedule-button')}</Link>
              </div>
            </div>

            <div className='card-body'>
              <DataTable
                data={data?.data}
                columns={columns}
                getRowId={row => row.id}
                pageSize={pagination.pageSize}
                pageNumber={pagination.pageNumber}
                totalRows={data?.totalEntitiesCount}
                sortBy={pagination.sortBy}
                sortOrder={pagination.sortOrder}
                serverMode
                onPaginationChangeHandler={onPaginationChangeHandler}
              />
            </div>

          </div>
        </div>
      </section>
    </>
  )
}

Schedule.propTypes = {}

export default Schedule
