import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { validateStatusSuccess } from 'shared/helpers/api'
import { PaginationRequest } from 'ts/interfaces/request.interfaces'
import { pushNotification } from 'shared/helpers/notifications/notifications'

import { ContentHeader } from 'shared/components/contentHeader/contentHeader.component'
import { Link } from 'react-router-dom'
import { GridColDef } from '@mui/x-data-grid'
import EditIcon from '@mui/icons-material/Edit'
import Switch from '@mui/material/Switch'
import { CircularProgress } from '@mui/material'
import DataTable from 'shared/components/dataTable/dataTable.component'
import { useEnableEvaluatorCategory, useGetEvaluatorCategoryList } from 'api/evaluatorCategory/evaluatorCategory.api'

const CategoryEvaluator = () => {
  const [t] = useTranslation(['letters', 'global'])

  const [pagination, setPagination] = useState<PaginationRequest>({
    pageNumber: 1,
    pageSize: 10,
  })

  const { data, isLoading: isLoadingCategory, refetch } = useGetEvaluatorCategoryList(pagination)
  const { mutateAsync, status: statusActive } = useEnableEvaluatorCategory()

  // Handlers:
  const onCategoryActiveChangeHandler = async (value: boolean, letterId: string) => {
    try {
      await mutateAsync({ id: letterId, isActive: value })

      if (validateStatusSuccess(statusActive)) {
        pushNotification(value ? t('letters:messages.activated') : t('letters:messages.deactivated'), 'success')
        await refetch()
      }
    } catch (error) {
      pushNotification((error?.response?.data?.error?.code as string) ?? t('letters:messages.error'))
    }
  }

  const onPaginationChangeHandler = (pageSize: number, pageNumber: number, sortBy: string, sortOrder: 'asc' | 'desc') => {
    setPagination({ pageSize, pageNumber, sortBy, sortOrder })
  }

  const columns: GridColDef[] = [
    { field: 'name', headerName: 'Categoria de Evaluador', width: 300 },
    { field: 'description', headerName: 'Descripcion', flex: 1 },
    { field: 'value', headerName: 'Valor', flex: 1 },
    {
      field: 'editCategory',
      headerName: t('global:datatable.edit-col'),
      width: 100,
      sortable: false,
      disableColumnMenu: true,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        const categoryEvaluatorId: string = params.row.id

        return (
          <Link to={`/categoryEvaluator/edit/${categoryEvaluatorId}`}>
            <EditIcon />
          </Link>
        )
      },
    },
    {
      field: 'activeCategory',
      headerName: t('global:datatable.active-col'),
      width: 100,
      sortable: false,
      disableColumnMenu: true,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        const { id: categoryEvaluatorId, isActive } = params.row

        return <Switch checked={isActive} onChange={(_, value) => onCategoryActiveChangeHandler(value, categoryEvaluatorId)} />
      },
    },
  ]

  if (isLoadingCategory) return <CircularProgress />

  return (
    <>
      <ContentHeader title='Categorias de Evaluador' breadCrumb={{ home: t('global:sidebar.home'), page: t('global:sidebar.letters') }} />

      <section className='content'>
        <div className='container-fluid'>
          <div className='card card-primary card-outline'>
            <div className='card-header border-0'>
              <div className='d-flex justify-content-between'>
                <h3 className='card-title'>Lista de Categorias de Evaluador</h3>
                <Link to='/categoryEvaluator/new'>Agregar nueva categoria</Link>
              </div>
            </div>

            <div className='card-body'>
              <DataTable
                data={data.data}
                columns={columns}
                getRowId={(row) => row.id}
                pageSize={pagination.pageSize}
                pageNumber={pagination.pageNumber}
                totalRows={data?.totalEntitiesCount}
                sortBy={pagination.sortBy}
                sortOrder={pagination.sortOrder}
                serverMode
                onPaginationChangeHandler={onPaginationChangeHandler}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default CategoryEvaluator
