import { useFetch, usePost, useUpdate, useApply } from 'api/reactQuery'
import { ApiURL, services, subServices } from 'services/api.service'
import { CreateTenant, EditTenant, EnableTenant, SetAsDefaultTenant, Tenant } from 'api/tenant/tenant.interface'
import { PaginationRequest, ResponsePagination } from 'ts/interfaces/request.interfaces'

const { URL_API } = ApiURL
const { TENANT } = services
const { CREATE_TENANT, EDIT_TENANT, ENABLE_TENANT, GET_TENANT, GET_TENANT_LIST, GET_TENANT_ACTIVE_LIST, SET_AS_DEFAULT_TENANT } = subServices

export const useGetTenant = (id: string) => useFetch<Tenant>(`${URL_API}/${TENANT}/${GET_TENANT}`, { id })

export const useGetTenantList = (request?: PaginationRequest) => useFetch<ResponsePagination>(`${URL_API}/${TENANT}/${GET_TENANT_LIST}`, request)

export const useGetTenantActiveList = () => useFetch<Tenant[]>(`${URL_API}/${TENANT}/${GET_TENANT_ACTIVE_LIST}`)

export const useCreateTenant = () => usePost<CreateTenant, CreateTenant>(`${URL_API}/${TENANT}/${CREATE_TENANT}`)

export const useEditTenant = () => useUpdate<EditTenant, EditTenant>(`${URL_API}/${TENANT}/${EDIT_TENANT}`)

export const useEnableTenant = () => useApply<EnableTenant, EnableTenant>(`${URL_API}/${TENANT}/${ENABLE_TENANT}`)

export const useSetAsDefaultTenant = () => useApply<SetAsDefaultTenant, SetAsDefaultTenant>(`${URL_API}/${TENANT}/${SET_AS_DEFAULT_TENANT}`)
