import { CircularProgress, Switch } from '@mui/material'
import { useGetAllUsers, useGetAllUsersAssignedRoleFamilyList } from 'api/user/user.api'
import { useEffect, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { ContentHeader } from 'shared/components/contentHeader/contentHeader.component'
import SelectAutocomplete from 'shared/components/selectInput/selectAutocomplete.component'
import { MailDistributionForm, SelectOptionI } from 'ts/interfaces/app.interfaces'
import { AssignSeasonQuestionnaire } from 'pages/assignQuestionnaire/assignSeasonQuestionnaire'
import { AssignQuestionnaireList } from 'pages/assignQuestionnaire/assignQuestionnaireList.component'

import './styles.scss'
import { useGetEvaluatorCategoryActiveList } from 'api/evaluatorCategory/evaluatorCategory.api'
import { useGetUserQuestionnaireList, usePostUserQuestionnaire } from 'api/questionnaire/questionnaire.api'
import { pushNotification } from 'shared/helpers/notifications/notifications'
import UploadFile from 'shared/components/uploadFile/uploadFile.component'
import readXlsxFile from 'read-excel-file'
import { generateStringId } from 'shared/helpers'

const id = generateStringId()

const baseAssignationData = {
  evaluatorUserIdEvaluatorUserId: '',
  evaluatedUserId: '',
  relationId: '',
  isEmailUsed: false,
  email: '',
}

const createNewAssignationOption = (evaluadorId, evaluadoId, relationId, seasonSelected) => {
  const idCategory = uuidv4 as string

  return { [idCategory]: { id: idCategory, evaluadorId, evaluadoId, relationId, seasonSelected } }
}

export const AssignQuestionnaireComponent = () => {
  const [t] = useTranslation(['letters', 'global'])

  const { isLoading: isLoadingCategory, data: categoryData, status: statusCategory } = useGetEvaluatorCategoryActiveList()
  const { mutateAsync, isLoading: isLoadingUserQuestionnaire } = usePostUserQuestionnaire()
  const [seasonSelected, setSeasonSelected] = useState()
  const { refetch } = useGetUserQuestionnaireList(seasonSelected)
  const [userData, setUserData] = useState<SelectOptionI[]>([])
  const { data: users, isLoading } = useGetAllUsersAssignedRoleFamilyList()
  const [assignationData, setAssignationData] = useState({ [id]: { ...baseAssignationData, id } })
  const [importFile, setImportFile] = useState<File>()
  const [isActiveImportDL, setIsActiveImportDL] = useState(false)
  const [isEditActive, setEditActive] = useState(true)

  useEffect(() => {
    if (users) {
      setUserData(
        users.map((user) => {
          return { value: user.userId, label: user.userName }
        }),
      )
    }
  }, [users])

  console.log('assignationData', assignationData)

  const getSaveAssignation = ([evaluadorId, evaluadoId, relationId]: string[]) => {
    const newUserAssignation = createNewAssignationOption(evaluadorId, evaluadoId, relationId, seasonSelected)

    console.log('newUserAssignation', newUserAssignation)

    // setAssignationData((previousState) => [...previousState, newUserAssignation])
  }

  useEffect(() => {
    try {
      importFile &&
        readXlsxFile(importFile).then((rows) => {
          rows.forEach((row, i) => {
            console.log('row', row)
            if (i !== 0) {
              getSaveAssignation(row as string[])
            }
          })
        })
    } catch (error) {
      console.log('error', error)
    } finally {
      setIsActiveImportDL(false)
    }
  }, [importFile])

  const onUpdateAssignationData = (assignationId, value, index) => {
    setAssignationData((prevSate) => ({
      ...prevSate,
      [assignationId]: {
        ...prevSate[assignationId],
        [index]: value,
      },
    }))
  }

  const addOptionHandler = () => {
    const uuID = generateStringId()

    setAssignationData((prevState) => ({ ...prevState, [uuID]: { ...baseAssignationData, id: uuID } }))
  }

  const saveAssignations = async () => {
    try {
      const assignationObj = Object.keys(assignationData).map((assignation) => ({
        evaluatedUserId: assignationData[assignation].evaluadoId,
        evaluatorUserId: assignationData[assignation].evaluadorId,
        // toUserEmail: assignationData[assignation].email,
        seasonId: seasonSelected,
        categoryId: assignationData[assignation].relationId,
      }))

      await mutateAsync({ assignments: assignationObj })

      if (statusCategory === 'success' || statusCategory === 'idle') {
        pushNotification('Asignacion creada correctamente', 'success')
        setAssignationData({})
        await refetch()
      }
    } catch (error) {
      console.log('error', error)
      pushNotification(error.response.data.message, 'error')
    }
  }

  const handleActiveEdit = (isActive) => {
    setEditActive(isActive)
  }

  const removeOptionHandler = (assignationId) => {
    setAssignationData((prevState) => {
      const { [assignationId]: deletedObject, ...rest } = prevState

      return rest
    })
  }

  const createAssignation = (assignation) => (
    <div className='row' key={`a-${assignation.id}`}>
      <div className='col-md-4'>
        <div className='form-group'>
          <label htmlFor='evaluadorId'>Evaluado</label>
          {assignation.isEmailUsed ? (
            <input
              type='email'
              className='form-control'
              placeholder='email...'
              onChange={(value) => onUpdateAssignationData(assignation.id, value, 'email')}
            />
          ) : (
            <SelectAutocomplete
              id='evaluadorId'
              name='evaluadorId'
              elements={userData}
              errors={null}
              setFieldValue={(value) => onUpdateAssignationData(assignation.id, value, 'evaluadorId')}
              placeholder='Selecciona al evaluador'
            />
          )}
        </div>
        <span>use email</span>
        <Switch checked={assignation.isEmailUsed} onChange={(_, value) => onUpdateAssignationData(assignation.id, value, 'isEmailUsed')} />
      </div>
      <div className='col-md-4'>
        <div className='form-group'>
          <label htmlFor='evaluadoId'>Evaluador</label>
          <SelectAutocomplete
            id='evaluadoId'
            name='userId'
            elements={userData}
            errors={null}
            setFieldValue={(value) => onUpdateAssignationData(assignation.id, value, 'evaluadoId')}
            placeholder='Selecciona al evaluado'
          />
        </div>
      </div>
      <div className='col-md-3'>
        <div className='form-group'>
          <label htmlFor='relationId'>Categoria de Evaluador</label>
          <select
            name=''
            id='relationId'
            className='form-control'
            onChange={(e) => onUpdateAssignationData(assignation.id, e.target.value, 'relationId')}
          >
            <option value='0'>Seleccion</option>
            {categoryData.map((category) => (
              <option value={category.id}>{category.name}</option>
            ))}
          </select>
        </div>
      </div>
      <div className='input-group-prepend'>
        <span
          onClick={() => removeOptionHandler(assignation.id)}
          className='input-group-text rounded-0'
          style={{ border: 'none', backgroundColor: 'white', cursor: 'pointer' }}
          title={t('letters:mail-distribution.dist-list.delete-option')}
        >
          {/* <i className='fa fa-times-circle'></i> */}
          <i className='fa fa-times' style={{ fontSize: 24 }} />
        </span>
      </div>
    </div>
  )

  if (isLoading || isLoadingCategory) return <CircularProgress />

  return (
    <>
      <ContentHeader title='Asignacion de cuestionarios' breadCrumb={{ home: t('global:sidebar.home'), page: t('global:sidebar.letters') }} />
      <AssignSeasonQuestionnaire
        onSessionSelected={setSeasonSelected}
        seasonSelected={seasonSelected}
        onHandleActiveEdit={handleActiveEdit}
        isEditActive={isEditActive}
      />
      {seasonSelected && (
        <>
          <section className='content'>
            <div className='container-fluid'>
              <div className='card card-primary card-outline'>
                <div className='card-header border-0'>
                  <div className='d-flex justify-content-between'>
                    <h3 className='card-title'>Busqueda de usuarios</h3>
                  </div>
                </div>
                <div className='card-body'>
                  {Object.values(assignationData).map((assignation) => createAssignation(assignation))}
                  <div className='row'>
                    <div className='col'>
                      <span className='text-primary add-option' onClick={addOptionHandler}>
                        Agregar Asignacion
                      </span>
                      /
                      <span className='text-primary add-option' onClick={() => setIsActiveImportDL(!isActiveImportDL)}>
                        Importar
                      </span>
                    </div>
                  </div>
                  {isActiveImportDL && (
                    <div className='row'>
                      <div className='col'>
                        <UploadFile fileTypes={['XLS', 'XLSX']} onHandleFile={setImportFile} file={importFile} />
                      </div>
                    </div>
                  )}
                  <div className='row'>
                    <div className='offset-9 col-md-3'>
                      <button className='btn btn-success btn-block' type='button' onClick={saveAssignations}>
                        Guardar
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className='content'>
            <div className='container-fluid'>
              <div className='card card-primary card-outline'>
                <div className='card-header border-0'>
                  <div className='d-flex justify-content-between'>
                    <h3 className='card-title'>Lista Asignacion de Cuestionarios</h3>
                    <Link to='/assignQuestionnaire/new'>Agregar Asignacion</Link>
                  </div>
                </div>

                <div className='card-body'>
                  <AssignQuestionnaireList seasonSelected={seasonSelected} />
                </div>
              </div>
            </div>
          </section>
        </>
      )}
    </>
  )
}
