import { ApiURL, services, subServices } from 'services/api.service'
import { useApply, useFetch, usePost, useUpdate } from 'api/reactQuery'
import type { GetUserQuestionnaireQuestionsAnswers } from 'api/questionnaire/userQuestionnaireAnswer'
import { api } from '../api'
import type {
  AssignmentsQuestionnaire,
  EnableQuestion,
  Guest,
  GuestsResponse,
  PaginationQuestionnaireRequest,
  Questionnaire,
  QuestionnaireList,
  QuestionnaireQuestions,
  UserAssignmentQuestionnaire,
  UserQuestionnaireAnswers,
  UserQuestionnaireList,
} from './questionnaire.interface'
import { PaginationRequest, ResponsePagination } from 'ts/interfaces/request.interfaces'

const { URL_API } = ApiURL
const { THREE_SIXTY, GUESTS } = services
const {
  CREATE_QUESTIONNAIRE,
  GET_QUESTIONNAIRE_LIST,
  GET_QUESTIONNAIRE_QUESTIONS,
  POST_QUESTIONNAIRE_ANSWERS,
  CREATE_USER_QUESTIONNAIRE,
  ENABLE_QUESTION,
  GET_USER_QUESTIONNAIRE_LIST,
  GET_USER_QUESTIONNAIRE_QUESTION_ANSWERS,
  CREATE_USER_ASSIGNMENT_QUESTIONNAIRE,
} = subServices

export const usePostQuestionnaire = () => usePost<Questionnaire, Questionnaire>(`${URL_API}/${THREE_SIXTY}/${CREATE_QUESTIONNAIRE}`, { id: 1 })

export const useGetQuestionnaireList = (pagination?: PaginationQuestionnaireRequest) =>
  useFetch<QuestionnaireList>(`${URL_API}/${THREE_SIXTY}/${GET_QUESTIONNAIRE_LIST}`, pagination)

export const useGetQuestionnaireQuestions = (questionnaireId: string) =>
  useFetch<QuestionnaireQuestions>(`${URL_API}/${THREE_SIXTY}/${GET_QUESTIONNAIRE_QUESTIONS}?QuestionnaireId=${questionnaireId}`, { id: 1 })

export const getQuestionnaireQuestions = (questionnaireId: string) =>
  api.get<QuestionnaireQuestions>(`${URL_API}/${THREE_SIXTY}/${GET_QUESTIONNAIRE_QUESTIONS}?QuestionnaireId=${questionnaireId}`, { id: 1 })

export const getUserQuestionnaireQuestionsAnswers = (userQuestionnaireId: string) =>
  api.get<GetUserQuestionnaireQuestionsAnswers>(
    `${URL_API}/${THREE_SIXTY}/${GET_USER_QUESTIONNAIRE_QUESTION_ANSWERS}?UserQuestionnaireId=${userQuestionnaireId}`,
    {
      id: userQuestionnaireId,
    },
  )

export const usePostQuestionnaireAnswers = () =>
  usePost<UserQuestionnaireAnswers, UserQuestionnaireAnswers>(`${URL_API}/${THREE_SIXTY}/${POST_QUESTIONNAIRE_ANSWERS}`, { id: 1 })

export const usePostUserQuestionnaire = () =>
  usePost<AssignmentsQuestionnaire, AssignmentsQuestionnaire>(`${URL_API}/${THREE_SIXTY}/${CREATE_USER_QUESTIONNAIRE}`, { id: 1 })

export const usePatchQuestion = () => useApply<EnableQuestion, EnableQuestion>(`${URL_API}/${THREE_SIXTY}/${ENABLE_QUESTION}`, { id: 1 })

export const useGetUserQuestionnaireList = (seasonId?: string, userId?: string, role?: string) => {
  const isAdminUser = role == 'admin'
  // Show configuration
  let showPastDuePeriods: boolean = false
  let showOwnQuestionnaires: boolean = true
  if (isAdminUser) {
    showPastDuePeriods = true
  }
  // Fetch
  return useFetch<UserQuestionnaireList[]>(`${URL_API}/${GUESTS}/`, {
    seasonId,
    userId: isAdminUser ? null : userId,
    showPastDuePeriods,
    showOwnQuestionnaires,
  })
}

export const useCreateUserAssignmentQuestionnaire = () =>
  usePost<UserAssignmentQuestionnaire, UserAssignmentQuestionnaire>(`${URL_API}/${THREE_SIXTY}/${CREATE_USER_ASSIGNMENT_QUESTIONNAIRE}`, { id: 1 })

export const useGetGuests = () => useFetch<GuestsResponse>(`${URL_API}/${GUESTS}`, { id: 1 })

export const useCreateGuest = () => usePost<Guest, Guest>(`${URL_API}/${GUESTS}`, { id: 1 })

export const useGetGuestById = (id: string) => useFetch<GuestsResponse>(`${URL_API}/${GUESTS}/${id}`, { id })

export const updateGuest = (id: string, guest: Guest) =>
  api.put<Guest>(`${URL_API}/${GUESTS}/${id}`, {
    ...guest,
  })

export const deleteGuest = (id: string) => api.delete<GuestsResponse>(`${URL_API}/${GUESTS}/${id}`)
