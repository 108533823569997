import * as Yup from 'yup'
import { t } from 'i18next'

const validationEditLetterCompositionSchema = Yup.object().shape({
  letterId: Yup.string()
    .required(t('letters:yup.letter-id-required')),
  mailDistributionId: Yup.string()
    .required(t('letters:yup.mail-dist-id-required')),
  scheduleId: Yup.string()
    .required(t('letters:yup.schedule-id-required')),
})

export { validationEditLetterCompositionSchema as validationEditLetterCompositionSchema }

export default {}
