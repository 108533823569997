import { useTranslation } from 'react-i18next'

import { ContentHeader } from 'shared/components/contentHeader/contentHeader.component'
import { Link } from 'react-router-dom'
import { GridColDef } from '@mui/x-data-grid'
import EditIcon from '@mui/icons-material/Edit'
import Switch from '@mui/material/Switch'
import DataTable from 'shared/components/dataTable/dataTable.component'

const breadCrumb = {
  home: 'Home',
  page: 'Configure Questionnaire', // t('sidebar.letters'),
}

const ConfigureQuestionnaire = () => {
  const [t] = useTranslation('global')

  const columns: GridColDef[] = [
    { field: 'cluster', headerName: 'Cluster', width: 300 },
    { field: 'description', headerName: 'Description', flex: 1 },
    { field: 'factor', headerName: 'Factors', width: 300 },
    { field: 'levels', headerName: 'Levels', width: 300 },
    {
      field: 'edit',
      headerName: 'Edit',
      width: 100,
      sortable: false,
      disableColumnMenu: true,
      headerAlign: 'center',
      align: 'center',
      renderCell: params => {
        const id: string = params.row.id
        return (
          <Link to={`/configurationQuestionnaire/edit/${id}`}>
            <EditIcon />
          </Link>
        )
      },
    }, {
      field: 'active',
      headerName: 'Active',
      width: 100,
      sortable: false,
      disableColumnMenu: true,
      headerAlign: 'center',
      align: 'center',
      renderCell: params => {
        const id: string = params.row.id
        const isActive: boolean = params.row.isActive
        return (
          <Switch
            checked={isActive}
            // onChange={() => onLetterActiveChangeHandler(letterId)}
          />
        )
      },
    },
  ]

  return (
    <>
      <ContentHeader title={t('sidebar.configureQuestionnaire')} breadCrumb={breadCrumb} />

      <section className='content'>
        <div className='container-fluid'>
          <div className='card card-primary card-outline'>

            <div className='card-header border-0'>
              <div className='d-flex justify-content-between'>
                <h3 className='card-title'>List of Configurations</h3>
                <Link to='/configurationQuestionnaire/new'>Add New Configuration</Link>
              </div>
            </div>

            <div className='card-body'>
              <DataTable
                data={[]}
                columns={columns}
                getRowId={row => row.id}
                // pageSize={pagination.pageSize}
                // pageNumber={pagination.pageNumber}
                // totalRows={totalEntitiesCount}
                // sortBy={pagination.sortBy}
                // sortOrder={pagination.sortOrder}
                serverMode
                // onPaginationChangeHandler={onPaginationChangeHandler}
              />
            </div>

          </div>
        </div>
      </section>
    </>
  )
}

export default ConfigureQuestionnaire
