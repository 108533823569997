import { generateSpecificCrudActionType } from 'shared/helpers/redux'
import { TypeObjectAction } from 'ts/interfaces/app.interfaces'

const UserTypes: TypeObjectAction = {
  TEST: 'TEST',
  ...generateSpecificCrudActionType('GET_PROFILE'),
  ...generateSpecificCrudActionType('POST_USER'),
}

export default UserTypes
