import { ApiURL, services, subServices } from 'services/api.service'
import { fetcher, useApply, useFetch, usePost, useUpdate } from 'api/reactQuery'
import { QueryClient } from 'react-query'
import { Level, LevelFactor, LevelTarget, LevelTargetPrev, PaginationLevelRequest, ResponsePaginationLevel } from './level.interface'

const { URL_API } = ApiURL
const { THREE_SIXTY } = services
const {
  GET_LEVEL_LIST,
  GET_LEVEL,
  CREATE_LEVEL,
  EDIT_LEVEL,
  ENABLE_LEVEL,
  GET_LEVEL_ACTIVE_LIST,
  LEVEL_TARGET,
  GET_TARGET_FAMILY,
  GET_TARGETS_ROLE,
} = subServices

export const queryClient = new QueryClient()

export const useCreateLevel = () => usePost<Level, Level>(`${URL_API}/${THREE_SIXTY}/${CREATE_LEVEL}`, { id: 1 })

export const useGetLevelById = (id: string) => useFetch<Level>(`${URL_API}/${THREE_SIXTY}/${GET_LEVEL}`, { id })

export const useGetLevelsList = (pagination?: PaginationLevelRequest) =>
  useFetch<ResponsePaginationLevel>(`${URL_API}/${THREE_SIXTY}/${GET_LEVEL_LIST}`, pagination)

export const useUpdateLevel = () => useUpdate<Level, Level>(`${URL_API}/${THREE_SIXTY}/${EDIT_LEVEL}`, { id: 1 })

export const usePatchLevel = () => useApply<Level, Level>(`${URL_API}/${THREE_SIXTY}/${ENABLE_LEVEL}`, { id: 1 })

export const useGetLevelFactorList = (pagination?: PaginationLevelRequest) =>
  useFetch<LevelFactor[]>(`${URL_API}/${THREE_SIXTY}/${GET_LEVEL_ACTIVE_LIST}`, pagination)

export const getLevelsFactor = (pagination?: PaginationLevelRequest) => {
  return fetcher<Level[]>({ queryKey: [`${URL_API}/${THREE_SIXTY}/${GET_LEVEL_ACTIVE_LIST}`, pagination] })
}

export const usePostTarget = () => usePost<LevelTarget, LevelTarget>(`${URL_API}/${THREE_SIXTY}/${LEVEL_TARGET}`, { id: 1 })

export const useGetTargetFamily = (familyId?: string) => useFetch<LevelTargetPrev[]>(`${URL_API}/${THREE_SIXTY}/${GET_TARGET_FAMILY}`, { familyId })

export const useGetTargetsByRole = (familyId: string, roleId: string) =>
  useFetch<LevelTargetPrev[]>(`${URL_API}/${THREE_SIXTY}/${GET_TARGETS_ROLE}`, { familyId, roleId })
