import { HelperEnum } from 'ts/enums/app.enums'
import { TypeObjectAction } from 'ts/interfaces/app.interfaces'

const CRUD_ACTIONS = ['GET', 'POST', 'PUT', 'DELETE']
const CRUD_STATES = ['START', 'SUCCESS', 'FAILURE', 'CLEAN']

export const getReduxIdSuffix = (id = '') => {
  return id ? `${HelperEnum.REDUX_ID_SEPARATOR}${id}` : ''
}

export const generateCrudActionTypes = (entity: string, { crudActions } = { crudActions: CRUD_ACTIONS }) => {
  const actionTypes = {}

  crudActions.forEach((crudAction) => {
    const baseAction = `${crudAction}_${entity}`
    actionTypes[baseAction] = baseAction

    CRUD_STATES.forEach((crudState) => {
      const action = `${baseAction}_${crudState}`

      actionTypes[action] = action
    })
  })

  return actionTypes
}

export const generateMiddlewareActionTypes = (entity: string, id = '') => {
  const idSuffix = getReduxIdSuffix(id)

  const type = {
    START: `${entity}_START${idSuffix}`,
    SUCCESS: `${entity}_SUCCESS${idSuffix}`,
    FAILURE: `${entity}_FAILURE${idSuffix}`,
  }

  return type
}

export const generateSpecificCrudActionType = (entity: string) => {
  const crudType: TypeObjectAction = {
    [entity]: entity,
    [`${entity}_START`]: `${entity}_START`,
    [`${entity}_SUCCESS`]: `${entity}_SUCCESS`,
    [`${entity}_FAILURE`]: `${entity}_FAILURE`,
    [`${entity}_CLEAN`]: `${entity}_CLEAN`,
  }

  return crudType
}
