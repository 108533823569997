import * as Yup from 'yup'
import { t } from 'i18next'

export const validationEditClusterTypeSchema = Yup.object().shape({
  description: Yup.string()
    .required(t('threeSixty:yup.cluster-type.description.required'))
    .min(1, t('threeSixty:yup.cluster-type.description.min'))
    .max(500, t('threeSixty:yup.cluster-type.description.max')),
})

export const validationNewClusterTypeSchema = Yup.object().shape({
  name: Yup.string()
    .required(t('threeSixty:yup.cluster-type.name.required'))
    .min(1, t('threeSixty:yup.cluster-type.name.min'))
    .max(100, t('threeSixty:yup.cluster-type.name.max')),
  description: Yup.string()
    .required(t('threeSixty:yup.cluster-type.description.required'))
    .min(1, t('threeSixty:yup.cluster-type.description.min'))
    .max(500, t('threeSixty:yup.cluster-type.description.max')),
})
