import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { SubmitHandler, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useEditLetterSchedule, useGetLetterActiveList, useGetLetterScheduleById, useGetMailDistributionActiveList, useGetScheduleActiveList } from 'api/letterSchedule/letterSchedule.api'
import { EditLetterScheduleForm, SelectOptionI } from 'ts/interfaces/app.interfaces'
import { validationEditLetterCompositionSchema } from 'pages/letterComposition/editLetterComposition.validation'
import { pushNotification } from 'shared/helpers/notifications/notifications'

import { ContentHeader } from 'shared/components/contentHeader/contentHeader.component'
import SelectAutocomplete from 'shared/components/selectInput/selectAutocomplete.component'
import { CircularProgress } from '@mui/material'

const EditLetterComposition = () => {
  const [t] = useTranslation(['letters', 'global'])

  const navigate = useNavigate()
  const navigateToLetterComposition = () => navigate('/letterComposition')
  const { letterScheduleId } = useParams()

  if (!letterScheduleId) navigateToLetterComposition()

  const { isLoading, data: composition } = useGetLetterScheduleById(letterScheduleId)
  const { mutateAsync: editLetterScheduleAsync, status: editionStatus } = useEditLetterSchedule()
  const { isLoading: isLoadingLetter, data: letterData, status: letterDataStatus } = useGetLetterActiveList()
  const { isLoading: isLoadingDistribution, data: distributionData, status: distributionDataStatus } = useGetMailDistributionActiveList()
  const { isLoading: isLoadingSchedule, data: scheduleData, status: scheduleStatus } = useGetScheduleActiveList()

  const {
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
  } = useForm<EditLetterScheduleForm>({
    resolver: yupResolver(validationEditLetterCompositionSchema),
  })

  // States:
  const [letterOptions, setLetterOptions] = useState<SelectOptionI[]>([])
  const [mailDistributionOptions, setMailDistributionOptions] = useState<SelectOptionI[]>([])
  const [scheduleOptions, setScheduleOptions] = useState<SelectOptionI[]>([])

  const [selectedLetterOption, setSelectedLetterOption] = useState<SelectOptionI>()
  const [selectedScheduleOption, setSelectedScheduleOption] = useState<SelectOptionI>()
  const [selectedMailDistributionOption, setSelectedMailDistributionOption] = useState<SelectOptionI>()

  // Effects:
  useEffect(() => {
    if (composition) {
      reset({
        id: composition.id,
        name: composition.name,
        letterId: composition.letter.id,
        mailDistributionId: composition.mailDistribution.id,
        scheduleId: composition.schedule.id,
      })
    }
  }, [composition, reset])

  useEffect(() => {
    if (letterData) {
      setLetterOptions(letterData.map(letter => {
        return { value: letter.id, label: letter.name }
      }))
    }
  }, [letterData, letterDataStatus])
  useEffect(() => {
    if (distributionData) {
      setMailDistributionOptions(distributionData.map(distribution => {
        return { value: distribution.id, label: distribution.name }
      }))
    }
  }, [distributionData, distributionDataStatus])
  useEffect(() => {
    if (scheduleData) {
      setScheduleOptions(scheduleData.map(schedule => {
        return { value: schedule.id, label: schedule.name }
      }))
    }
  }, [scheduleData, scheduleStatus])

  useEffect(() => {
    if (letterOptions && composition) {
      const selectedLetter = letterOptions.find(
        option => option.value === composition.letter.id,
      )
      if (selectedLetter) {
        setSelectedLetterOption(selectedLetter)
      }
    }
    if (scheduleOptions) {
      const selectedSchedule = scheduleOptions.find (
        option => option.value === composition.schedule.id,
      )
      if (selectedSchedule) {
        setSelectedScheduleOption(selectedSchedule)
      }
    }
    if (mailDistributionOptions) {
      const selectedMailDistribution = mailDistributionOptions.find(
        option => option.value === composition.mailDistribution.id,
      )
      if (selectedMailDistribution) {
        setSelectedMailDistributionOption(selectedMailDistribution)
      }
    }
  }, [letterOptions, scheduleOptions, mailDistributionOptions])

  // Handlers:
  const onSubmit: SubmitHandler<EditLetterScheduleForm> = async data => {
    try {
      await editLetterScheduleAsync(data)

      if (['success', 'idle'].includes(editionStatus)) {
        reset({})
        navigateToLetterComposition()
      }
    } catch (error) {
      pushNotification((error?.response?.data?.error?.code as string) ?? t('letters:messages.error'))
    }
  }

  if (isLoading || isLoadingLetter || isLoadingSchedule || isLoadingDistribution ) {
    return <CircularProgress />
  }

  return (
    <>
      <ContentHeader
        title={t('letters:letter-composition.edition-header')}
        breadCrumb={{ home: t('global:sidebar.home'), page: t('global:sidebar.letter-compositions') }}
      />

      <section className='content rounded-0'>
        <div className='container-fluid'>
          <div className='callout callout-primary'>
            <h5>{t('letters:letter-composition.edition-description-title')}</h5>
            <p>{t('letters:letter-composition.edition-description-body')}</p>
          </div>
        </div>
      </section>

      <section className='content rounded-0'>
        <div className='container-fluid'>
          <div className='card card-primary card-outline'>

            <div className='card-header border-0'>
              <div className='d-flex justify-content-between'>
                <h3 className='card-title'>
                  <strong>{composition?.name}</strong>
                </h3>
                <Link to='/letterComposition'>{t('global:cruds.back-button')}</Link>
              </div>
            </div>

            <div className='card-body'>

              <form id='new-letter-form' className='row' onSubmit={handleSubmit(onSubmit)}>
                <div className='col-md-12'>

                  <div className='row'>

                    {/* LETTER selector */}
                    <div className='col-sm-4'>
                      <div className='form-group'>
                        <label htmlFor='letter-selector'>
                          {t('letters:letter-composition.table-headers.letter')}
                        </label>
                        <SelectAutocomplete
                          id='letter-selector'
                          name='letterId'
                          elements={letterOptions}
                          defaultSelectedOption={selectedLetterOption}
                          errors={errors}
                          setFieldValue={value => setValue('letterId', value)}
                          placeholder={t('letters:letter-composition.table-headers.letter-placeholder')}
                        />
                      </div>
                    </div>

                    {/* SCHEDULE selector */}
                    <div className='col-sm-4'>
                      <div className='form-group'>
                        <label htmlFor='schedule-selector'>
                          {t('letters:letter-composition.table-headers.schedule')}
                        </label>
                        <SelectAutocomplete
                          id='schedule-selector'
                          name='scheduleId'
                          elements={scheduleOptions}
                          defaultSelectedOption={selectedScheduleOption}
                          errors={errors}
                          setFieldValue={value => setValue('scheduleId', value)}
                          placeholder={t('letters:letter-composition.table-headers.schedule-placeholder')}
                        />
                      </div>
                    </div>

                    {/* MAIL DISTRIBUTION selector */}
                    <div className='col-sm-4'>
                      <div className='form-group'>
                        <label htmlFor='mail-dist-selector'>
                          {t('letters:letter-composition.table-headers.mail-distribution')}
                        </label>
                        <SelectAutocomplete
                          id='mail-distribution-selector'
                          name='mailDistributionId'
                          elements={mailDistributionOptions}
                          defaultSelectedOption={selectedMailDistributionOption}
                          errors={errors}
                          setFieldValue={value => setValue('mailDistributionId', value)}
                          placeholder={t('letters:letter-composition.table-headers.mail-distribution-placeholder')}
                        />
                      </div>
                    </div>

                  </div>

                  {/* Save Submit */}
                  <div className='row'>
                    <div className='offset-md-9 col-md-3'>
                      <div className='form-group'>
                        {/* <div className="col-3"> */}
                        <button type='submit' className='btn btn-success btn-block'>
                          {t('global:cruds.save-button')}
                        </button>
                        {/* </div> */}
                      </div>
                    </div>
                  </div>

                </div>
              </form>

            </div>
          </div>
        </div>
      </section>
    </>
  )
}

EditLetterComposition.propTypes = {}

export default EditLetterComposition
