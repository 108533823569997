import { compile } from 'path-to-regexp'

export const generateStringId = () => Math.random().toString()

export const getPosition = (object: any, option: any, index: string) => object.findIndex((values: any) => values[index] === option)

export const getValueByObjectKey = (object: any, keysPath: string): any => {
  if (!object || !keysPath) {
    return undefined
  }
  const keys: string[] = keysPath.split('.')

  if (keys && keys.length > 0) {
    let currentValue: any = object

    keys.forEach((key) => {
      if (currentValue) {
        currentValue = currentValue[key]
      }
    })

    return currentValue
  }

  return undefined
}

export const getElementByKey = <T, undefined>(array: T[], value: any, key: string): T | undefined => {
  return array.find((el) => el[key] === value)
}

export const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
})

export const pathToUrl = (path: string, params: object = {}) => compile(path)(params)

export const sleep = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

export const getClassAsPercentage = (percentage: number) => {
  let colorResponse = 'danger'

  if (percentage <= 50) {
    colorResponse = 'danger'
  } else if (percentage <= 75 && percentage >= 50) {
    colorResponse = 'warning'
  } else if (percentage >= 75 && percentage <= 100) {
    colorResponse = 'success'
  }

  return colorResponse
}

export const getLabelNotification = (percentage: number) => {
  let labelResponse = 'No Iniciado'

  if (percentage === 0) {
    labelResponse = 'No Iniciado'
  } else if (percentage === 100) {
    labelResponse = 'Terminado'
  } else {
    labelResponse = `${percentage}%`
  }

  return labelResponse
}

export default generateStringId
