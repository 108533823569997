import { ApiURL, services, subServices } from 'services/api.service'
import { RegisterForm, ResponseToken } from 'ts/interfaces/app.interfaces'
import { PaginationRequest, ResponsePagination } from 'ts/interfaces/request.interfaces'
import { useFetch, usePost, useUpdate } from 'api/reactQuery'
import { api } from '../api'
import { CreateUserRequest, EditUserRequest, EnableUserRequest, RoleFamilyUser, UsersAssigned } from './user.interface'

const { URL_API } = ApiURL
const { ACCOUNT, THREE_SIXTY } = services
const { REGISTER, GET_USERS, ENABLE_USER, CREATE_USER, EDIT_USER, GET_ROL_FAMILY_USER, GET_USERS_ASSIGNED_ROLE_FAMILY_LIST } = subServices

export const postUser = (body: RegisterForm) => api.post<ResponseToken>(`${URL_API}/${ACCOUNT}/${REGISTER}`, JSON.stringify(body))

// export const getUsers = (pagination?: PaginationRequest) =>
//   api.get<PaginationRequest>(setPaginationToEndpoint(`${URL_API}/${ACCOUNT}/${GET_USERS}`, pagination))

export const useGetAllUsers = (pagination?: PaginationRequest, userName?: string) => {
  const searchFilter = userName ? `?SearchFilter=${userName}` : ''

  return useFetch<ResponsePagination>(`${URL_API}/${ACCOUNT}/${GET_USERS}${searchFilter}`, pagination)
}

export const useGetAllUsersAssignedRoleFamilyList = () =>
  useFetch<UsersAssigned[]>(`${URL_API}/${THREE_SIXTY}/${GET_USERS_ASSIGNED_ROLE_FAMILY_LIST}`)

export const useEnableUser = () => usePost<EnableUserRequest, EnableUserRequest>(`${URL_API}/${ACCOUNT}/${ENABLE_USER}`)

export const useCreateUser = () => usePost<CreateUserRequest, CreateUserRequest>(`${URL_API}/${ACCOUNT}/${CREATE_USER}`)

export const useEditUser = () => useUpdate<EditUserRequest, EditUserRequest>(`${URL_API}/${ACCOUNT}/${EDIT_USER}`)

export const getUserRolFamily = (userId: string) =>
  api.get<RoleFamilyUser>(`${URL_API}/${THREE_SIXTY}/${GET_ROL_FAMILY_USER}?userId=${userId}`, { userId })
