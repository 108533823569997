import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { SelectOptionI } from 'ts/interfaces/app.interfaces'

import { InputLabel, MenuItem, FormControl, Select, SelectChangeEvent } from '@mui/material'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * (4.5 + ITEM_PADDING_TOP),
      width: 'auto',
    },
  },
}

interface MultiSelectComponent {
  id?: string
  name: string
  elements: SelectOptionI[]
  reg: any
  errors: any
  errorIcon?: string
  placeholder?: string
  defaultSelectedElements?: string[]
  onSelectionChange?: (selectedValues: string[]) => void
}

const MultiSelect = ({
  id,
  name,
  elements,
  reg,
  errors,
  errorIcon,
  placeholder = name,
  defaultSelectedElements,
  onSelectionChange,
}: MultiSelectComponent) => {
  // States:
  const [selectedElements, setSelectedElements] = useState<string[]>(defaultSelectedElements ?? [])

  // Effects:
  useEffect(() => {
    if (onSelectionChange) {
      onSelectionChange(selectedElements)
    }
  }, [selectedElements])

  // Handlers
  const onChangeHandler = (event: SelectChangeEvent<typeof selectedElements>) => {
    const { target: { value } } = event
    const newValue: string[] = typeof value === 'string' ? value.split(',') : value
    setSelectedElements(newValue)
  }

  return (
    <FormControl className={'input-group mb-3'}>

      <InputLabel id={`${id}-label`} className={'bg-white pr-2'}>
        {placeholder}
      </InputLabel>

      <Select
        id={id}
        labelId={`${id}-label`}
        multiple={true}
        value={selectedElements}
        label={placeholder}
        {...reg}
        onChange={onChangeHandler}
        MenuProps={MenuProps}
        error={!!errors[name]}
      >
        {elements.map(roleElement => (
          <MenuItem
            key={roleElement.value}
            value={roleElement.value}
          >
            {roleElement.label}
          </MenuItem>
        ))}
      </Select>

      {errors[name] && (
        <div className='d-block invalid-feedback'>
          <span className={`fas ${errorIcon ?? 'fa-exclamation-circle'}`}></span>
          {` ${errors[name]?.message}`}
        </div>
      )}

    </FormControl>
  )
}

MultiSelect.propTypes = {
  elements: PropTypes.any,
  reg: PropTypes.any,
  name: PropTypes.string,
  errors: PropTypes.object,
  id: PropTypes.string,
}

export default MultiSelect
