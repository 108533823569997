import { useFetch, usePost } from 'api/reactQuery'
import { ApiURL, services, subServices } from 'services/api.service'
import { Season, SeasonActiveList, SeasonItem } from './season.interface'
import { api } from '../api'

const { URL_API } = ApiURL
const { THREE_SIXTY } = services
const { CREATE_SEASON, GET_SEASON_ACTIVE_LIST, GET_SEASON } = subServices

export const useCreateSeason = () => usePost<Season, Season>(`${URL_API}/${THREE_SIXTY}/${CREATE_SEASON}`)

export const useGetActiveSeason = () => useFetch<SeasonActiveList[]>(`${URL_API}/${THREE_SIXTY}/${GET_SEASON_ACTIVE_LIST}?Individual=false`, { id: 1 })

export const getSeason = (seasonId: string) => api.get<SeasonItem>(`${URL_API}/${THREE_SIXTY}/${GET_SEASON}?SeasonId=${seasonId}`, { id: seasonId })
