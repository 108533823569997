import { useState } from 'react'
import { ContentHeader } from 'shared/components/contentHeader/contentHeader.component'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import Modal from '@mui/material/Modal'
import { useGetFamilyActiveList } from 'api/family/family.api'
import { useGetTargetFamily, usePostTarget } from 'api/level/level.api'

import { useTranslation } from 'react-i18next'
import { pushNotification } from 'shared/helpers/notifications/notifications'

import './configureThreeSixty.style.scss'
import { useGetScopeActiveList } from 'api/scope/scope.api'
import { ModalConfiguration, TargetConfiguration } from './modalConfiguration'
import { ConfigureTarget } from './configureTarget'
import { ScopeFilter } from 'shared/components/scopeFilter/scopeFilter'

const style = {
  position: 'absolute',
  width: '80rem',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '1px solid #77cddd',
  boxShadow: 24,
  p: 4,
}

export interface RoleData {
  role: string
  roleId: string
}

export const ConfigureThreeSixty = () => {
  const [t] = useTranslation(['threeSixty', 'global'])

  const [familyId, setFamilyId] = useState('')
  const [familyName, setFamilyName] = useState('')
  const [show, setShow] = useState(false)
  const [roleConfigure, setRoleConfigure] = useState<RoleData>()
  const { mutateAsync, status: statusPostTarget } = usePostTarget()
  const { refetch } = useGetTargetFamily(familyId)
  const { data: scopeData, isLoading: scopeIsLoading } = useGetScopeActiveList()
  const [activeFilters, setActiveFilters] = useState([])

  const { data: activeFamilies, status: activeFamiliesStatus } = useGetFamilyActiveList()

  const handleClose = () => {
    setShow(false)
  }
  const handleOpen = (role: string, roleId: string) => {
    setRoleConfigure({ roleId, role })
    setShow(true)
  }

  // const getExtraLevelsIds = (extraLevels) => {
  //   return extraLevels?.map((level) => level.value)
  // }

  const getActiveFilters = (activeFiltersFromChildren) => {
    setActiveFilters(activeFiltersFromChildren)
  }

  const saveData = async (objToSave) => {
    try {
      await mutateAsync(objToSave)

      if (statusPostTarget === 'success' || statusPostTarget === 'idle') {
        handleClose()
        pushNotification('Target configured', 'success')
      } else {
        pushNotification('Something went wrong', 'error')
      }
    } catch (error) {
      pushNotification(`Something went wrong: ${error}`, 'error')
    }
  }

  const handleSave = async (configurationData: TargetConfiguration[]) => {
    const objToSave = []

    Object.values(configurationData).map((config) => {
      objToSave.push({
        familyId,
        rolId: roleConfigure.roleId,
        level: { id: config.level.id },
        factorId: config.factorId,
        extraLevels: config.extraLevels,
        isCriticalValue: config.isCriticalValue,
        weightedValue: config.weightedValue,
      })
    })
    await saveData({ targetLevels: objToSave })
    await refetch()
  }

  const modalConfigurations = (open: boolean) => {
    return (
      <Modal open={open} onClose={handleClose} aria-labelledby='modal-modal-title' aria-describedby='modal-modal-description'>
        <Box sx={style}>
          <ModalConfiguration
            roleConfigure={roleConfigure?.role}
            familyId={familyId}
            roleId={roleConfigure?.roleId}
            onClose={handleClose}
            onSave={handleSave}
          />
        </Box>
      </Modal>
    )
  }

  const showConfiguration = (e: any) => {
    const familyIdSelected = e.target.value as string
    const familyNameSelected = e.target[e.target.selectedIndex].text as string

    setFamilyId(familyIdSelected)
    setFamilyName(familyNameSelected)
  }

  if (activeFamiliesStatus === 'loading' || scopeIsLoading) return <CircularProgress />

  return (
    <>
      <ContentHeader
        title={t('global:sidebar.questionnaires-360')}
        breadCrumb={{ home: t('global:sidebar.home'), page: t('global:sidebar.questionnaires-360') }}
      />

      <section className='content rounded-0'>
        <div className='container-fluid'>
          <div className='card card-primary card-outline'>
            <div className='card-header border-0'>
              <div className='d-flex justify-content-between'>
                <h3 className='card-title'>
                  <strong>{t('questionnaire-360.header')}</strong>
                </h3>
              </div>
            </div>

            <div className='card-body'>
              <div id='new-quiz-form' className='row'>
                <div className='col-md-12'>
                  <div className='row'>
                    <div className='col-md-6'>
                      <div className='form-group'>
                        <label htmlFor='family-id-selection'>{t('questionnaire-360.select-a-family')}</label>
                        <select className='form-control rounded-0' name='actions' id='familyId' onChange={(e) => showConfiguration(e)}>
                          <option value=''>{t('questionnaire-360.select')}</option>
                          {activeFamilies?.map((family) => (
                            <option key={`family-${family.id}`} value={family.id}>
                              {family.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  {familyId && (
                    <ScopeFilter scopeData={scopeData} onActiveFilters={getActiveFilters} />
                  )}
                </div>
              </div>
            </div>

            <div className='card-body'>
              <div className='row'>
                <div className='col'>
                  <h3 className='card-title'>
                    <strong>
                      {t('questionnaire-360.configuration')} - {familyName}
                    </strong>
                  </h3>
                </div>
              </div>
              {familyId && <ConfigureTarget onOpen={handleOpen} onClose={handleClose} familyId={familyId} activeFilters={activeFilters} />}
            </div>
          </div>
        </div>
      </section>
      {modalConfigurations(show)}
    </>
  )
}

export default ConfigureThreeSixty
