import { ApiURL, services, subServices } from 'services/api.service'
import { PaginationRequest, ResponsePagination } from 'ts/interfaces/request.interfaces'
import { setPaginationToEndpoint } from 'shared/helpers/pagination'
import { useApply, useFetch, usePost, useUpdate } from 'api/reactQuery'
import { ThreeSixtyEditForm } from 'ts/interfaces/app.interfaces'
import { ClusterType, ClusterTypeResponse } from 'api/clusterType/clusterType.interface'

const { URL_API } = ApiURL
const { THREE_SIXTY } = services
const { GET_CLUSTER_TYPE_LIST, GET_CLUSTER_TYPE, CREATE_CLUSTER_TYPE, EDIT_CLUSTER_TYPE, ENABLE_CLUSTER_TYPE, GET_CLUSTER_ACTIVE_LIST } = subServices

export const useCreateClusterType = () =>
  usePost<ClusterTypeResponse, ClusterTypeResponse>(`${URL_API}/${THREE_SIXTY}/${CREATE_CLUSTER_TYPE}`, { id: 1 })

export const useGetClusterTypeById = (id: string) => useFetch<ClusterType>(`${URL_API}/${THREE_SIXTY}/${GET_CLUSTER_TYPE}?id=${id}`, { id })

export const useGetClusterActiveTypeList = () => useFetch<ClusterType[]>(`${URL_API}/${THREE_SIXTY}/${GET_CLUSTER_ACTIVE_LIST}`, { id: 1 })

export const useGetClusterTypeList = (pagination?: PaginationRequest) =>
  useFetch<ResponsePagination>(setPaginationToEndpoint(`${URL_API}/${THREE_SIXTY}/${GET_CLUSTER_TYPE_LIST}`, pagination), pagination)

export const useUpdateClusterType = () =>
  useUpdate<ThreeSixtyEditForm, ThreeSixtyEditForm>(`${URL_API}/${THREE_SIXTY}/${EDIT_CLUSTER_TYPE}`, { id: 1 })

export const usePatchClusterType = () => useApply<ClusterType, ClusterType>(`${URL_API}/${THREE_SIXTY}/${ENABLE_CLUSTER_TYPE}`, { id: 1 })
