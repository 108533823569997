import { RecoveryResponseI } from 'api/auth/auth.interface'
import { ApiURL, services, subServices } from 'services/api.service'
import { ForgotPasswordI, LoginForm, recoveryPasswordI, ResponseToken } from 'ts/interfaces/app.interfaces'
import { api } from '../api'

const { URL_API } = ApiURL
const { ACCOUNT } = services
const { LOGIN, FORGOT_PASSWORD, RECOVERY_PASSWORD, LINKEDIN_LOGIN } = subServices

export const getTokenByPassword = (dataUser: LoginForm) =>
  api.post<ResponseToken>(`${URL_API}/${ACCOUNT}/${LOGIN}`, dataUser)

export const postForgotPassword = (request: ForgotPasswordI) =>
  api.post<any>(`${URL_API}/${ACCOUNT}/${FORGOT_PASSWORD}`, request)

export const postRecoveryPassword = (request: recoveryPasswordI) =>
  api.post<RecoveryResponseI>(`${URL_API}/${ACCOUNT}/${RECOVERY_PASSWORD}`, request)

export const postLinkedinLogin = (code: string) =>
  api.post<ResponseToken>(`${URL_API}/${ACCOUNT}/${LINKEDIN_LOGIN}`, {
    code,
    scope: process.env.REACT_APP_LINKEDIN_SCOPE,
  })
