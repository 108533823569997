import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { SubmitHandler, useForm } from 'react-hook-form'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'

import { ContentHeader } from 'shared/components/contentHeader/contentHeader.component'
import Input from 'shared/components/input/input.component'
import CircularProgress from '@mui/material/CircularProgress'
import { ThreeSixtyClusterEditForm } from 'ts/interfaces/app.interfaces'
import { useGetClusterById, useUpdateCluster } from 'api/cluster/cluster.api'
import { useGetClusterTypeList } from 'api/clusterType/clusterType.api'
import { validationEditClusterSchema } from './cluster.validation'
import { pushNotification } from 'shared/helpers/notifications/notifications'

const EditCluster = () => {
  const [t] = useTranslation(['threeSixty', 'global'])

  const navigate = useNavigate()
  const navigateToCluster = () => navigate('/cluster')
  const { clusterId } = useParams()

  const { isLoading, data } = useGetClusterById(clusterId)
  const { mutateAsync, status } = useUpdateCluster()
  const { data: clusterTypeData, status: clusterTypeStatus } = useGetClusterTypeList()

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<ThreeSixtyClusterEditForm>({
    defaultValues: useMemo(() => {
      return {
        clusterTypeId: data?.clusterType.id,
        description: data?.description,
      }
    }, [data]),
    resolver: yupResolver(validationEditClusterSchema),
  })

  useEffect(() => {
    if (data) {
      reset({
        id: data?.id,
        name: data?.name,
        description: data?.description,
        clusterTypeId: data?.clusterType.id,
      })
    }
  }, [data, reset])

  const onSubmit: SubmitHandler<ThreeSixtyClusterEditForm> = async (clusterData) => {
    try {
      await mutateAsync(clusterData)

      if (status === 'success' || status === 'idle') {
        pushNotification(t('messages.modified'), 'success')
        reset({})
        navigateToCluster()
      } else {
        pushNotification(t('messages.error'), 'error')
      }
    } catch (error) {
      pushNotification((error?.response?.data?.error?.code as string) ?? t('letters:messages.error'), 'error')
    }
  }

  if (isLoading || clusterTypeStatus === 'loading') return <CircularProgress />

  return (
    <>
      <ContentHeader title={t('cluster.edition-header')} breadCrumb={{ home: t('global:sidebar.home'), page: t('global:sidebar.clusters') }} />

      <section className='content rounded-0'>
        <div className='container-fluid'>
          <div className='callout callout-primary'>
            <h5>{t('cluster.edition-description-title')}</h5>
            <p>{t('cluster.edition-description-body')}</p>
          </div>
        </div>
      </section>

      <section className='content rounded-0'>
        <div className='container-fluid'>
          <div className='card card-primary card-outline'>
            <div className='card-header border-0'>
              <div className='d-flex justify-content-between'>
                <h3 className='card-title'>
                  <strong>{data?.name}</strong>
                </h3>
                <Link to='/cluster'>{t('global:cruds.back-button')}</Link>
              </div>
            </div>
            <div className='card-body'>
              <form id='new-cluster-form' className='row' onSubmit={handleSubmit(onSubmit)}>
                <div className='col-md-12'>
                  <div className='row'>
                    <div className='col-md-12'>
                      <div className='form-group'>
                        <label htmlFor='name'>{t('cluster.table-headers.cluster')}</label>
                        <Input type='text' name='name' reg={register('name')} errors={errors} placeholder={t('cluster.table-headers.cluster')} />
                      </div>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-md-6'>
                      <div className='form-group'>
                        <label htmlFor='clusterType'>{t('cluster.table-headers.cluster-type')}</label>
                        <select name='clusterTypeId' {...register('clusterTypeId')} id='clusterTypeId' className='form-control'>
                          <option value='0'>{t('cluster.table-headers.cluster-type-placeholder')}</option>
                          {clusterTypeData?.data.map((clusterType) => (
                            <option key={clusterType.id} value={clusterType.id}>
                              {clusterType.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-md-12'>
                      <div className='form-group'>
                        <label htmlFor='description'>{t('cluster.table-headers.description')}</label>
                        {/* <Input
                          type='text'
                          name='description'
                          reg={register('description')}
                          errors={errors}
                          placeholder={t('cluster.table-headers.description')}
                        /> */}
                        <textarea
                          placeholder={t('cluster-type.table-headers.description')}
                          id='decription'
                          name='description'
                          className='form-control rounded-0 '
                          {...register('description')}
                        />
                      </div>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='offset-md-9 col-md-3'>
                      <div className='form-group'>
                        {/* <div className="col-3"> */}
                        <button type='submit' className='btn btn-success btn-block'>
                          {t('global:cruds.save-button')}
                        </button>
                        {/* </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

EditCluster.propTypes = {}

export default EditCluster
