import { useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { Link, useNavigate } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'
import { validationNewLetterSchema } from 'pages/letter/newLetter.validation'
import { NewLetterForm } from 'ts/interfaces/app.interfaces'

import { ContentHeader } from 'shared/components/contentHeader/contentHeader.component'
import Input from 'shared/components/input/input.component'
import TextEditor from 'shared/components/textEditor/textEditor.component'
import { useCreateLetter } from 'api/letter/letter.api'
import { useTranslation } from 'react-i18next'
import { pushNotification } from 'shared/helpers/notifications/notifications'

const NewLetter = () => {
  const [t] = useTranslation(['letters', 'global'])

  const { mutateAsync, status } = useCreateLetter()
  // Navigation:
  const navigate = useNavigate()

  // Yup:

  const methods = useForm<NewLetterForm>({
    resolver: yupResolver(validationNewLetterSchema),
  })

  const letterName = methods.watch('letterName', t('letters:letter.creation-default-name') as string)

  // States:
  const [htmlContent, setHtmlContent] = useState('</br></br></br></br></br></br>')

  // Handlers:
  const onSubmit: SubmitHandler<NewLetterForm> = async (data) => {
    try {
      // Create Letter
      await mutateAsync({
        name: data?.letterName,
        subject: data?.subject,
        date: '',
        htmlContent,
      })

      if (status === 'success' || status === 'idle') {
        methods.reset({})
        navigate('/letter')
      }
    } catch (error) {
      pushNotification((error?.response?.data?.error?.code as string) ?? t('letters:messages.error'), 'error')
    }
  }

  return (
    <>
      <ContentHeader title={t('letters:letter.creation-header')} breadCrumb={{ home: t('global:sidebar.home'), page: t('global:sidebar.letters') }} />

      <section className='content rounded-0'>
        <div className='container-fluid'>
          <div className='callout callout-primary'>
            <h5>{t('letters:letter.creation-description-title')}</h5>
            <p>{t('letters:letter.creation-description-body')}</p>
          </div>
        </div>
      </section>

      <section className='content rounded-0'>
        <div className='container-fluid'>
          <div className='card card-primary card-outline'>
            <div className='card-header border-0'>
              <div className='d-flex justify-content-between'>
                <h3 className='card-title'>
                  <strong>{letterName}</strong>
                </h3>
                <Link to='/letter'>{t('global:cruds.back-button')}</Link>
              </div>
            </div>

            <div className='card-body'>
              <form id='new-letter-form' className='row' onSubmit={methods.handleSubmit(onSubmit)}>
                <div className='col-md-12'>
                  <div className='row'>
                    <div className='col-sm-6'>
                      <div className='form-group'>
                        <label htmlFor='letterName'>{t('letters:letter.table-headers.letter')}</label>
                        <Input
                          type='text'
                          name='letterName'
                          reg={methods.register('letterName')}
                          errors={methods?.formState?.errors}
                          placeholder={t('letters:letter.table-headers.letter')}
                        />
                      </div>
                    </div>

                    <div className='col-sm-6'>
                      <div className='form-group'>
                        <label htmlFor='subject'>{t('letters:letter.table-headers.subject')}</label>
                        <Input
                          type='text'
                          name='subject'
                          reg={methods.register('subject')}
                          errors={methods?.formState?.errors}
                          placeholder={t('letters:letter.table-headers.subject')}
                        />
                      </div>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col'>
                      <div className='form-group'>
                        <label htmlFor='letterDesign'>{t('letters:letter.table-headers.design-your-letter')}</label>
                        <TextEditor content={htmlContent} setContent={setHtmlContent} />
                      </div>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='offset-md-9 col-md-3'>
                      <div className='form-group'>
                        <button type='submit' className='btn btn-success btn-block'>
                          {t('global:cruds.save-button')}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default NewLetter
