import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ContentHeader } from 'shared/components/contentHeader/contentHeader.component'
import { Link } from 'react-router-dom'
import { GridColDef } from '@mui/x-data-grid'
import EditIcon from '@mui/icons-material/Edit'
import Switch from '@mui/material/Switch'
import CircularProgress from '@mui/material/CircularProgress'
import DataTable from 'shared/components/dataTable/dataTable.component'
import { PaginationRequest } from 'ts/interfaces/request.interfaces'
import { useGetRolesActiveList, useGetRolesList, usePatchRole } from 'api/role/role.api'
import { useGetFamilyActiveList, useGetFamilyRoles, usePostFamilyRoles, usePostDeactivateFamilyRoles } from 'api/family/family.api'
import { pushNotification } from 'shared/helpers/notifications/notifications'
import { ScopeFilter } from 'shared/components/scopeFilter/scopeFilter'
import { useGetScopeActiveList } from 'api/scope/scope.api'
import { scopeDictionary } from 'ts/enums/app.enums'

import 'react-toastify/dist/ReactToastify.css'
import './styles.scss'

const Role = () => {
  const [t] = useTranslation(['threeSixty', 'global'])

  const [activeFilters, setActiveFilters] = useState([])
  const [pagination, setPagination] = useState<PaginationRequest>({
    pageNumber: 1,
    pageSize: 10,
  })
  const { data, status, refetch } = useGetRolesList(pagination)
  const { data: activeRolesData, status: activeRolesStatus, refetch: refetchRolActiveList } = useGetRolesActiveList()
  const { data: familyRolesData, status: familyRolesStatus, refetch: refetchFamilyRoles } = useGetFamilyRoles()
  const { data: familyData, status: familyStatus, refetch: familyActiveList } = useGetFamilyActiveList()
  const { mutateAsync: mutateAsyncFamilyRole, status: addFamilyRoleStatus } = usePostFamilyRoles()
  const { mutateAsync, status: statusActive } = usePatchRole()
  const { mutateAsync: mutateAsyncRemoveFamilyRole, status: removeFamilyRoleStatus } = usePostDeactivateFamilyRoles()
  const { data: scopeData, isLoading: scopeIsLoading } = useGetScopeActiveList()

  const onRoleActiveChangeHandler = async (value: boolean, roleId: string) => {
    try {
      await mutateAsync({ id: roleId, isActive: value })

      if (['success', 'idle'].includes(statusActive)) {
        pushNotification(`${value ? 'Activated' : 'Deactivated'} correctly`, 'success')
        await refetch()
        await refetchRolActiveList()
        await familyActiveList()
      }
    } catch (error) {
      pushNotification((error?.response?.data?.error?.code as string) ?? 'Something went wrong')
    }
  }

  const getActiveFilters = (activeFiltersFromChildren) => {
    setActiveFilters(activeFiltersFromChildren)
  }

  const isChecked = (roleId: string, familyId: string): boolean =>
    familyRolesData.length && familyRolesData.filter((roleFamily) => roleFamily.familyId === familyId && roleFamily.rolId === roleId).length > 0

  const onPaginationChangeHandler = (pageSize: number, pageNumber: number, sortBy: string, sortOrder: 'asc' | 'desc') => {
    setPagination({ pageSize, pageNumber, sortBy, sortOrder })
  }

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: t('role.table-headers.role'),
      width: 300,
    },
    {
      field: 'description',
      headerName: t('role.table-headers.description'),
      flex: 1,
    },
    {
      field: 'scope',
      headerName: 'Alcance',
      flex: 1,
      renderCell: (params) => <span>{params.row?.scope?.name}</span>,
    },
    {
      field: 'editRole',
      headerName: t('global:datatable.edit-col'),
      width: 100,
      sortable: false,
      disableColumnMenu: true,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        const roleId: string = params.row.id

        return (
          <Link key={`link-${roleId}`} to={`/role/edit/${roleId}`}>
            <EditIcon />
          </Link>
        )
      },
    },
    {
      field: 'activeClusterType',
      headerName: t('global:datatable.active-col'),
      width: 100,
      sortable: false,
      disableColumnMenu: true,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        const roleId: string = params.row.id
        const isActive: boolean = params.row.isActive

        return <Switch key={`switch-${roleId}`} checked={isActive} onChange={(e, value) => onRoleActiveChangeHandler(value, roleId)} />
      },
    },
  ]

  const handleRoleFamily = async (e, value: boolean, idRol: string, idFamily: string) => {
    try {
      if (value) {
        await mutateAsyncFamilyRole({ idRol, idFamily })

        if (['success', 'idle'].includes(addFamilyRoleStatus)) {
          pushNotification(`${value ? 'Activated' : 'Deactivated'} correctly`, 'success')
          await refetchFamilyRoles()
        }
      } else {
        await mutateAsyncRemoveFamilyRole({ idRol, idFamily })

        if (['success', 'idle'].includes(removeFamilyRoleStatus)) {
          pushNotification(`${value ? 'Activated' : 'Deactivated'} correctly`, 'success')
          await refetchFamilyRoles()
        }
      }
    } catch (error) {
      pushNotification((error?.response?.data?.error?.code as string) ?? 'Something went wrong')
    }
  }

  if (status === 'loading') return <CircularProgress />

  return (
    <>
      <ContentHeader title={t('global:sidebar.roles')} breadCrumb={{ home: t('global:sidebar.home'), page: t('global:sidebar.roles') }} />

      <section className='content'>
        <div className='container-fluid'>
          <div className='card card-primary card-outline'>
            <div className='card-header border-0'>
              <div className='d-flex justify-content-between'>
                <h3 className='card-title'>{t('role.header')}</h3>
                <Link to='/role/new'>{t('role.new-role-button')}</Link>
              </div>
            </div>

            <div className='card-body'>
              <DataTable
                data={data?.data}
                columns={columns}
                getRowId={(row) => row.id}
                pageSize={pagination.pageSize}
                pageNumber={pagination.pageNumber}
                totalRows={data?.totalEntitiesCount}
                sortBy={pagination.sortBy}
                sortOrder={pagination.sortOrder}
                serverMode
                onPaginationChangeHandler={onPaginationChangeHandler}
              />
              <hr />
              <h3>Agregar Familia a Role</h3>
              {[familyStatus, familyRolesStatus, activeRolesStatus].includes('loading') ? (
                <CircularProgress />
              ) : (
                <div className='row table-content'>
                  <div className='col-md-12'>
                    <ScopeFilter scopeData={scopeData} onActiveFilters={getActiveFilters} />
                  </div>
                  <div className='col-md-12'>
                    <table className='table table-hover'>
                      <thead>
                        <tr className='stick-header'>
                          <th>Families:</th>
                          {familyData?.map((family) => (
                            <th key={family.id}>{family.name}</th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {activeRolesData
                          .filter((role) => (activeFilters.length ? activeFilters.includes(role.scope.name) : role))
                          ?.map((role) => (
                            <tr key={role.id}>
                              <td className={`text-${scopeDictionary[role?.scope?.name].color}`}>{role.name}</td>
                              {familyData.map((family) => (
                                <td key={`sw-${role.id}-${family.id}`}>
                                  <Switch
                                    checked={isChecked(role.id, family.id)}
                                    onChange={(e, value) => handleRoleFamily(e, value, role.id, family.id)}
                                  />
                                </td>
                              ))}
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Role
