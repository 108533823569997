import { useState } from 'react'
import { useDispatch } from 'react-redux'
// import { t } from 'i18next'
import { ContentHeader } from 'shared/components/contentHeader/contentHeader.component'
import Input from 'shared/components/input/input.component'
import TextEditor from 'shared/components/textEditor/textEditor.component'
import { SubmitHandler, useForm } from 'react-hook-form'
import { Link, useNavigate } from 'react-router-dom'
import classNames from 'classnames'
import { yupResolver } from '@hookform/resolvers/yup'


const breadCrumb = { home: 'Home', page: 'Configuration Questionnaire' }

interface ConfigurationQuestionnaireForm {
  name: string
  description: string
  cluster: string[]
  factor: string[]
}

const NewConfigurationQuestionnaire = () => {
  // States:
  const [htmlContent, setHtmlContent] = useState('</br></br></br></br></br></br>')

  // Dispatcher:
  const dispatch = useDispatch()
  // Navigation:
  const navigate = useNavigate()

  // Yup:
  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm<ConfigurationQuestionnaireForm>({
    // resolver: yupResolver(validationNewLetterSchema),
  })
  const name = watch('name', 'New template')

  // Handlers:
  const onSubmit: SubmitHandler<ConfigurationQuestionnaireForm> = async (data) => {
    // Create Cluster
    console.log('data', data)
    // Reset
    reset({})
    navigate('/configurationQuestionnaire')
  }


  return (
    <>
      <ContentHeader title='New Configuration Questionnaire' breadCrumb={breadCrumb} />

      <section className='content rounded-0'>
        <div className='container-fluid'>
          <div className='callout callout-primary'>
            <h5>Creation of Configuration Questionnaire</h5>
            <p>This module will help you to configure a questionnaire.</p>
          </div>
        </div>
      </section>

      <section className='content rounded-0'>
        <div className='container-fluid'>
          <div className='card card-primary card-outline'>
            <div className='card-header border-0'>
              <div className='d-flex justify-content-between'>
                <h3 className='card-title'>
                  <strong>{name}</strong>
                </h3>
                <Link to='/'>Back</Link>
              </div>
            </div>

            <div className='card-body'>
              <form id='new-level-form' className='row' onSubmit={handleSubmit(onSubmit)}>
                <div className='col-md-12'>
                  <div className='row'>
                    <div className='col-md-6'>
                      <div className='form-group'>
                        <label htmlFor='Name'>Name</label>
                        <Input type='text' name='Name' reg={register('name')} errors={errors} placeholder='Name' />
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-md-12'>
                      <div className='form-group'>
                        <label htmlFor='description'>Description</label>
                        <Input type='text' name='description' reg={register('description')} errors={errors} placeholder='Description' />
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-md-6'>
                      <div className='form-group'>
                        <label htmlFor='description'>Cluster</label>
                        <Input type='text' name='description' reg={register('description')} errors={errors} placeholder='Clusters' />
                      </div>
                    </div>
                    <div className='col-md-6'>
                      <div className='form-group'>
                        <label htmlFor='description'>Factors</label>
                        <Input type='text' name='description' reg={register('description')} errors={errors} placeholder='Factors' />
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-md-6'>
                      <div className='form-group'>
                        <label htmlFor='Design'>Questionnaire Template</label>
                        <Input type='text' name='description' reg={register('description')} errors={errors} placeholder='Questionnaires' />
                      </div>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='offset-md-9 col-md-3'>
                      <div className='form-group'>
                        {/* <div className="col-3"> */}
                        <button type='submit' className='btn btn-success btn-block'>
                          Save
                        </button>
                        {/* </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default NewConfigurationQuestionnaire
