import { useEffect, useState } from 'react'
import Cookies from 'js-cookie'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { useForm, SubmitHandler } from 'react-hook-form'
import { AlertProps, RegisterGuestForm } from 'ts/interfaces/app.interfaces'
import { yupResolver } from '@hookform/resolvers/yup'
import CircularProgress from '@material-ui/core/CircularProgress'
import Input from 'shared/components/input/input.component'
import LoginWrapper from 'shared/components/loginWrapper/loginWrapper.component'
import AlertComponent from 'shared/components/alert/alert.component'

import { validationRegisterGuestUserSchema, validationRegisterSchema } from './login.validation'
import { getGuestUser, useRegisterGuestUserAsCommon } from 'api/account/account.api'

const RegisterGuestUser = () => {
  const [t] = useTranslation('login')
  const { token } = useParams()

  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [notification, setNotification] = useState<AlertProps>({
    show: false,
    type: 'success',
    message: 'User added correctly',
    position: 'center',
  })

  const { mutateAsync, status } = useRegisterGuestUserAsCommon()

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm<RegisterGuestForm>({
    resolver: yupResolver(validationRegisterGuestUserSchema),
  })
  const autoClose = () => {
    setTimeout(() => {
      setNotification({
        ...notification,
        show: false,
      })
    }, 2000)
  }

  useEffect(() => {
    console.log('token', token)

    if (token) {
      getGuestUser(token)
        .then((user) => {
          reset({
            guestUserId: token,
            userName: user.data.userName,
            firstName: user.data.firstName,
            lastName: user.data.lastName,
            phoneNumber: user.data.phoneNumber,
          })
        })
        .catch((err) => {
          setNotification({
            ...notification,
            type: 'error',
            message: 'User Not Found',
            show: true,
          })

          navigate('/')
        })
    }
  }, [reset, token])

  const onSubmit: SubmitHandler<RegisterGuestForm> = async (data) => {
    setLoading(true)
    setValue('guestUserId', token)

    try {
      mutateAsync(data)
        .then((resp) => {
          console.log('resp', resp)

          if (resp.status === 200) {
            Cookies.set('token', resp.data.token)
            Cookies.set('tenant', resp.data.tenantId)
            // const message = `Welcome, ${resp.data.username}!`

            setNotification({
              show: true,
              type: 'success',
              message: 'User added correctly',
              position: 'center',
            })

            reset()
            autoClose()
            navigate('/')
          }
        })
        .catch((err) => {
          setNotification({
            ...notification,
            type: 'error',
            message: '',
            show: true,
          })
        })
    } catch (error) {
      console.log('error', error)

      setNotification({
        ...notification,
        type: 'error',
        message: error,
        show: true,
      })

      reset()
      autoClose()
    } finally {
      setLoading(false)
      navigate('/')
    }
  }

  const alertComponent = () =>
    notification.show && <AlertComponent type={notification.type} message={notification.message} position={notification.position} />

  return (
    <div className='hold-transition login-page'>
      {alertComponent()}
      {loading && <CircularProgress />}

      <LoginWrapper title={t('register.title')}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className='input-group mb-3'>
            <Input
              type='text'
              name='userName'
              reg={register('userName')}
              errors={errors}
              icon='fa fa-user'
              placeholder={t('register.form.username-placeholder')}
            />
          </div>
          <div className='input-group mb-3'>
            <Input type='text' name='firstName' reg={register('firstName')} errors={errors} icon='fa fa-user' placeholder='Nombre' />
          </div>
          <div className='input-group mb-3'>
            <Input type='text' name='lastName' reg={register('lastName')} errors={errors} icon='fa fa-user' placeholder='Apellido' />
          </div>
          <div className='input-group mb-3'>
            <Input type='text' name='phoneNumber' reg={register('phoneNumber')} errors={errors} icon='fa fa-phone' placeholder='Numero Telefonico' />
          </div>
          <div className='input-group mb-3'>
            <Input
              type='password'
              name='password'
              reg={register('password')}
              errors={errors}
              icon='fa fa-lock'
              placeholder={t('register.form.password-placeholder')}
            />
          </div>

          <div className='input-group mb-3'>
            <Input
              type='password'
              name='confirmPassword'
              reg={register('confirmPassword')}
              errors={errors}
              icon='fa fa-lock'
              placeholder={t('register.form.confirm-password-placeholder')}
            />
          </div>

          <div className='row'>
            <div className='col-8'>
              <div className='icheck-primary'>
                <Input type='checkbox' name='acceptTerms' reg={register('acceptTerms')} errors={errors} id='acceptTerms' className='' />
                &nbsp;
                <label htmlFor='acceptTerms' className='form-check-label'>
                  {t('register.form.accept-terms')} <a href='#'>{t('register.form.accept-terms-link')}</a>
                </label>
              </div>
            </div>
            {/* <!-- /.col --> */}
            <div className='col-4'>
              <button type='submit' className='btn btn-primary btn-block'>
                {t('register.form.register-button')}
              </button>
            </div>
            {/* <!-- /.col --> */}
          </div>
        </form>
      </LoginWrapper>
    </div>
  )
}

export default RegisterGuestUser
