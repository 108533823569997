import { memo } from 'react'
import useCheckToken from 'shared/hooks/useCheckToken.hook'

const checkType = (type: any) => {
  const typeOf = typeof type
  if (typeOf === 'function') {
    return type()
  } else if (typeOf === 'object') {
    return type
  }
}

const RoleConditionalRender = (props: any) => {
  const { role, isMyTokenExpired } = useCheckToken()
  return !isMyTokenExpired && checkType(props[role] ?? null)
}

export default memo(RoleConditionalRender)
