import * as Yup from 'yup'
import { t } from 'i18next'

export const validationNewDistributionListSchema = Yup.object().shape({
  name: Yup.string()
    .required(t('letters:yup.distribution-list.name.required'))
    .min(1, t('letters:yup.distribution-list.name.min'))
    .max(50, t('letters:yup.distribution-list.name.max')),
  replyTo: Yup.object().shape({
    name: Yup.string()
      .required(t('letters:yup.distribution-list.reply-to.name.required'))
      .min(1, t('letters:yup.distribution-list.reply-to.name.min'))
      .max(50, t('letters:yup.distribution-list.reply-to.name.max')),
    email: Yup.string()
      .required(t('letters:yup.distribution-list.reply-to.email.required'))
      .email(t('letters:yup.distribution-list.reply-to.email.email')),
  }),
  mailDistributionDetails: Yup.array()
    .of(
      Yup.object().shape({
        name: Yup.string()
          .required(t('letters:yup.distribution-list.distribution.name.required'))
          .min(1, t('letters:yup.distribution-list.distribution.name.min'))
          .max(50, t('letters:yup.distribution-list.distribution.name.max')),
        email: Yup.string()
          .required(t('letters:yup.distribution-list.distribution.email.required'))
          .email(t('letters:yup.distribution-list.distribution.email.email')),
      }),
    )
    .required(t('letters:yup.distribution-list.distribution.required')),
})
