import * as Yup from 'yup'
import { t } from 'i18next'

const validationNewScheduleSchema = Yup.object().shape({
  name: Yup.string()
    .required(t('letters:yup.schedule-name.required'))
    .min(1, t('letters:yup.schedule-name.min'))
    .max(100, t('letters:yup.schedule-name.max')),
  sends: Yup.number()
    .required(t('letters:yup.schedule-sents.required'))
    .min(1, t('letters:yup.schedule-sents.min')),
  frequency: Yup.number()
    .required(t('letters:yup.schedule-frequency.required'))
    .min(1, t('letters:yup.schedule-frequency.min')),
})

export { validationNewScheduleSchema as validationNewScheduleSchema }

export default {}
