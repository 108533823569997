import * as Yup from 'yup'
import { t } from 'i18next'

export const validationLevelSchema = Yup.object().shape({
  levelNumber: Yup.number()
    .required(t('threeSixty:yup.level.level-number.required')),
  description: Yup.string()
    .required(t('threeSixty:yup.level.description.required'))
    .min(1, t('threeSixty:yup.level.description.min'))
    .max(100, t('threeSixty:yup.level.description.max')),
  factorId: Yup.string().required(t('threeSixty:yup.level.factor.required')),
})
