import { PaginationRequest } from 'ts/interfaces/request.interfaces'
import { paginationParams } from 'services/api.service'

const { PAGE_NUMBER, PAGE_SIZE, SEARCH_FILTER, SORT_BY, SORT_ORDER } = paginationParams

export const getAsPascalCase = (camelCaseName: string): string => {
  if (!camelCaseName) {
    return ''
  }
  return camelCaseName.charAt(0).toUpperCase() + camelCaseName.slice(1)
}

export const setPaginationToEndpoint = (endpoint: string, pagination?: PaginationRequest): string => {
  const paramList: string[] = []
  if (pagination) {
    if (pagination.pageNumber) {
      paramList.push(`${PAGE_NUMBER}=${pagination.pageNumber}`)
    }
    if (pagination.pageSize) {
      paramList.push(`${PAGE_SIZE}=${pagination.pageSize}`)
    }
    if (pagination.searchFilter) {
      paramList.push(`${SEARCH_FILTER}=${pagination.searchFilter}`)
    }
    if (pagination.sortBy) {
      paramList.push(`${SORT_BY}=${getAsPascalCase(pagination.sortBy)}`)
    }
    if (pagination.sortOrder) {
      paramList.push(`${SORT_ORDER}=${pagination.sortOrder}`)
    }
  }
  if (paramList.length > 0) {
    return `${endpoint}?${paramList.join('&')}`
  }
  return endpoint
}
