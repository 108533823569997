import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import Typography from '@mui/material/Typography'
import CircularProgress from '@mui/material/CircularProgress'
import { v4 as uuidv4 } from 'uuid'
import { useGetFamilyFactores } from 'api/family/family.api'
import { getLevelsFactor, useGetTargetsByRole } from 'api/level/level.api'
import { pushNotification } from 'shared/helpers/notifications/notifications'
import Switch from '@mui/material/Switch'

export interface TargetLevel {
  id: string
  value: number
}

export interface TargetConfiguration {
  id: string
  factorId: string
  level: TargetLevel
  extraLevels?: TargetLevel[]
  isSaved: boolean
  targetLevel: TargetLevel
  isCriticalValue?: boolean
  weightedValue?: number
}

export interface TargetConfigurationDictionary {
  [key: string]: TargetConfiguration
}

export interface TargetDictionary {
  [key: string]: TargetMultiSelect[]
}

const target: TargetConfiguration = {
  id: '',
  factorId: '',
  level: { id: '', value: 0 },
  isSaved: false,
  targetLevel: { id: '', value: 0 },
  isCriticalValue: false,
  weightedValue: 0,
}

interface ModalConfigurationProps {
  roleConfigure: string
  familyId: string
  roleId: string
  onClose: () => void
  onSave: (configurationData: any) => void
}

interface TargetMultiSelect {
  id: string
  label: string
  value?: string
}

export const ModalConfiguration: React.FC<ModalConfigurationProps> = ({ roleConfigure, roleId, familyId, onClose, onSave }) => {
  const { data: familyFactoresData, status: familyFactoresStatus } = useGetFamilyFactores(familyId)
  const [factorSelectedId, setFactorSelectedId] = useState([])
  const [targetData, setTargetData] = useState<TargetDictionary>({})
  const { data: familyTargetData, status: familyTargetStatus } = useGetTargetsByRole(familyId, roleId)

  const [configuration, setConfiguration] = useState<TargetConfigurationDictionary>({})

  useEffect(() => {
    if (familyTargetData) {
      const transformToDictionary = familyTargetData.reduce((acc, current) => {
        const uuID = uuidv4() as string

        return { ...acc, [uuID]: { id: uuID, ...current, level: { id: current.targetLevel.id }, isSaved: true } }
      }, {})

      if (JSON.stringify(transformToDictionary) !== '{}') {
        setConfiguration(transformToDictionary)
      } else {
        const uuidStart = uuidv4() as string
        // [target.targetLevel.id]

        setConfiguration({ [uuidStart]: { ...target, id: uuidStart } })
      }
    }
  }, [familyTargetData, roleConfigure, roleId, familyId])

  const verifyMissingFields = (targeId: string) => {
    let response = true
    let message = null

    const { isSaved, isCriticalValue, weightedValue, ...copyConfiguration } = configuration[targeId]

    Object.keys(copyConfiguration).forEach((valueField) => {
      if (!copyConfiguration[valueField] || copyConfiguration[valueField] === '' || copyConfiguration[valueField]?.length === 0) {
        message = valueField
        response = false
      }
    })

    return { status: response, message }
  }

  const addExtraConfiguration = (targetId: string) => {
    if (!verifyMissingFields(targetId).status) return pushNotification(`Missing field ${verifyMissingFields(targetId).message}`)
    const uuid = uuidv4() as string
    const newConfiguration: TargetConfigurationDictionary = { [uuid]: { ...target, id: uuid } }

    setConfiguration((prevState) => ({ ...prevState, ...newConfiguration }))
  }

  const generateTargetLevels = async (factorId) => {
    if (!targetData[factorId]) {
      const data = await getLevelsFactor({ factorId })

      const targetDataUpdate: TargetMultiSelect[] = data?.map((targetLevel) => ({
        id: targetLevel.id,
        label: targetLevel.levelNumber.toString(),
        value: targetLevel.id,
      }))

      setTargetData((prevState) => ({ ...prevState, ...{ [factorId]: targetDataUpdate } }))
    }
  }

  const removeConfiguration = (targetId: string) => {
    delete configuration[targetId]
    setConfiguration({ ...configuration })
  }

  const handlerFactorSelected = async (e: any, configId: string, key: string) => {
    if (key === 'factorId') {
      await generateTargetLevels(e.target.value)
    }

    setConfiguration((prevState) => {
      let bodyObject = null

      if (key === 'level') {
        bodyObject = { id: e.target.value }
      }

      if (key === 'extraLevels') {
        bodyObject = e
      }

      if (['factorId', 'weightedValue'].includes(key)) {
        bodyObject = e.target.value
      }

      if (key === 'isCriticalValue') {
        bodyObject = e.target.checked
      }

      return {
        ...prevState,
        [configId]: {
          ...prevState[configId],
          [key]: bodyObject,
        },
      }
    })
  }

  const getFactoresConfigured = (configuration: TargetConfigurationDictionary) => {
    return Object.values(configuration).map((config) => config.factorId)
  }

  useEffect(() => {
    if (configuration) {
      setFactorSelectedId(getFactoresConfigured(configuration))
    }
  }, [configuration])

  const showTargetOptions = (targetConfiguration) => {
    return (
      <>
        <option value=''>Selecciona</option>
        {targetData[targetConfiguration.factorId]?.map((level) => (
          <option key={level.label} value={level.id}>
            {level.label}
          </option>
        ))}
      </>
    )
  }

  const generateOptions = (targetConfiguration) =>
    targetData[targetConfiguration?.factorId]?.filter((targetDataConf) => {
      return targetDataConf.id !== targetConfiguration.level.id
    })

  const renderConfiguration = () =>
    Object.values(configuration).map((targetConfiguration: TargetConfiguration) => (
      <div key={`config-${targetConfiguration.id}`} className='row mb-3'>
        <div className='col-md-3'>
          <select
            className='form-control'
            id={`factor-${targetConfiguration.factorId}`}
            defaultValue={targetConfiguration.factorId}
            onChange={(e) => handlerFactorSelected(e, targetConfiguration.id, 'factorId')}
          >
            <option value=''>Selecciona</option>
            {familyFactoresData
              .filter((factor) => {
                if (factor.factorId === targetConfiguration?.factorId) {
                  return factor
                }

                return !factorSelectedId.includes(factor.factorId)
              })
              .map((factor) => (
                <option key={`factor-${factor.factorId}`} value={factor.factorId}>
                  {factor.factorName}
                </option>
              ))}
          </select>
        </div>
        <div className='col-md-2'>
          <select className='form-control' name='level' id='level' onChange={(e) => handlerFactorSelected(e, targetConfiguration.id, 'level')}>
            {targetConfiguration.targetLevel.value ? (
              <option value={targetConfiguration.targetLevel.id}>{targetConfiguration.targetLevel.value}</option>
            ) : (
              showTargetOptions(targetConfiguration)
            )}
          </select>
        </div>
        <div className='col-md-2'>
          {targetConfiguration.isSaved ? (
            <select className='form-control' name='level' id='level' onChange={(e) => handlerFactorSelected(e, targetConfiguration.id, 'level')}>
              {targetConfiguration?.extraLevels?.length && (
                <option value=''>{targetConfiguration?.extraLevels?.map((extraLevel) => extraLevel.value)?.join(',') ?? 0}</option>
              )}
            </select>
          ) : (
            <Select
              onChange={(e) => handlerFactorSelected(e, targetConfiguration.id, 'extraLevels')}
              defaultValue={null}
              isMulti
              name='extraLevels'
              options={generateOptions(targetConfiguration)}
              className='basic-multi-select'
              classNamePrefix='select'
            />
          )}
        </div>
        <div className='col-md-1'>
          <Switch
            checked={targetConfiguration.isCriticalValue}
            onChange={(e) => handlerFactorSelected(e, targetConfiguration.id, 'isCriticalValue')}
          />
        </div>
        <div className='col-md-2'>
          <input
            className='form-control rounded-0'
            type='number'
            name='weight'
            defaultValue={targetConfiguration.weightedValue}
            onChange={(e) => handlerFactorSelected(e, targetConfiguration.id, 'weightedValue')}
          />
        </div>
        <div className='col-md-2'>
          <button type='button' onClick={() => addExtraConfiguration(targetConfiguration.id)} className='btn btn-sm btn-success mr-2'>
            +
          </button>
          {targetConfiguration.factorId && (
            <button
              type='button'
              title='borrar registro'
              onClick={() => removeConfiguration(targetConfiguration.id)}
              className='btn btn-sm btn-danger'
            >
              -
            </button>
          )}
        </div>
      </div>
    ))

  if ([familyFactoresStatus, familyTargetStatus].includes('loading')) return <CircularProgress />

  return (
    <>
      <Typography id='modal-modal-title' variant='h6' component='h2'>
        {roleConfigure}
      </Typography>
      <Typography id='modal-modal-description' sx={{ mt: 2 }}>
        Aqui se pueden configurar las competencias
      </Typography>
      <hr />
      <div className='row'>
        <div className='col-md-3'>Factor</div>
        <div className='col-md-2'>Target</div>
        <div className='col-md-2'>Extra Niveles</div>
        <div className='col-md-1'>Critico</div>
        <div className='col-md-2'>Ponderacion</div>
        <div className='col-md-2'>Acciones</div>
      </div>
      {renderConfiguration()}
      <div className='row mt-4'>
        <div className='col-md-6'>
          <button type='button' className='btn btn-md btn-danger' onClick={onClose}>
            Cancel
          </button>
        </div>
        <div className='col-md-6 d-flex justify-content-end'>
          <button type='button' className='btn btn-md btn-primary' onClick={() => onSave(configuration)}>
            Guardar
          </button>
        </div>
      </div>
    </>
  )
}
