import { useTranslation } from 'react-i18next'
import { useEffect, useMemo, useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { SubmitHandler, useForm } from 'react-hook-form'
import { UserAdminObject } from 'redux/interfaces'
import { EditUserAdminForm, SelectOptionI } from 'ts/interfaces/app.interfaces'
import { validationEditUserAdminSchema } from 'pages/users/editUser.validation'

import Input from 'shared/components/input/input.component'
import MultiSelect from 'shared/components/selectInput/multiSelect.component'
import { Button, Box, Modal } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { useEditUser } from 'api/user/user.api'
import { pushNotification } from 'shared/helpers/notifications/notifications'
import { useGetTenantActiveList } from 'api/tenant/tenant.api'
import SelectInput from 'shared/components/selectInput/selectInput.component'

interface EditUserModalProp {
  showModal: boolean
  setShowModal: (showModal: boolean) => void
  updateUser: UserAdminObject
  onUserUpdated?: () => void
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  border: '1px solid #555',
  borderRadius: '10px',
  boxShadow: 24,
  p: 1,
}

const EditUserModal = ({ showModal, setShowModal, updateUser, onUserUpdated }: EditUserModalProp) => {
  const [t, i18n] = useTranslation('users')

  // Create selection roles
  const roleOptions: SelectOptionI[] = [
    { value: 'Admin', label: t('roles.admin') },
    { value: 'User', label: t('roles.user') },
    { value: 'PremiumUser', label: t('roles.premium-user') },
  ]

  // Edit User
  const { mutateAsync: editUserAsync, status: editionStatus } = useEditUser()
  // Get Active Tenants
  const { data: activeTenants, status: activeTenantsStatus } = useGetTenantActiveList()

  // Yup:
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<EditUserAdminForm>({
    resolver: yupResolver(validationEditUserAdminSchema),
    defaultValues: useMemo(() => {
      return {
        email: updateUser.email,
        firstName: updateUser.firstName,
        lastName: updateUser.lastName,
        roles: updateUser.roles,
      }
    }, [updateUser]),
  })

  // States
  const [tenantOptions, setTenantOptions] = useState<SelectOptionI[]>([])

  // Effects
  useEffect(() => {
    if (activeTenants) {
      setTenantOptions(
        activeTenants.map((tenant) => {
          return { value: tenant.id, label: tenant.name }
        }),
      )
    }
  }, [activeTenants, activeTenantsStatus])

  useEffect(() => {
    reset({
      email: updateUser.email,
      firstName: updateUser.firstName,
      lastName: updateUser.lastName,
      roles: updateUser.roles,
    })
  }, [updateUser])

  // Handlers:
  const onCloseModalHandler = () => setShowModal(false)

  const onSubmit: SubmitHandler<EditUserAdminForm> = async data => {
    try {
      console.log(data)
      // Edit User:
      await editUserAsync({
        id: updateUser.userId,
        email: data.email,
        language: i18n.language,
        firstName: data.firstName,
        lastName: data.lastName,
        tenantId: data.tenantId,
        roles: data.roles,
      })
      if (['idle', 'success'].includes(editionStatus)) {
        if (onUserUpdated) {
          onUserUpdated()
        }
        pushNotification(t('messages.user-updated'), 'success')
        onCloseModalHandler()
      }
    } catch (error) {
      pushNotification((error?.response?.data?.error?.code as string) ?? t('messages.error'), 'error')
    }
  }

  return (
    <Modal open={showModal} onClose={onCloseModalHandler} aria-labelledby='modal-edit-user' aria-describedby='modal-edit-selected-user'>
      <Box sx={style}>
        <div className='card-header'>
          <div className='row'>
            <div className='col-10 text-left'>
              <h3 className='my-0'>
                {t('edition.header')} <strong>{updateUser.userName}</strong>
              </h3>
            </div>

            <div className='col-2 text-right'>
              <button className='border-0 bg-transparent' type='button' onClick={onCloseModalHandler}>
                <CloseIcon />
              </button>
            </div>
          </div>
        </div>

        <div className='card-body'>
          <form onSubmit={handleSubmit(onSubmit)}>
            {/* First Name */}
            <div className='input-group mb-3'>
              <Input
                type='text'
                name='firstName'
                id='firstName'
                reg={register('firstName')}
                errors={errors}
                icon='fa fa-user'
                className='form-control'
                placeholder={t('form.username')}
              />
            </div>

            {/* Last Name */}
            <div className='input-group mb-3'>
              <Input
                type='text'
                name='lastName'
                id='lastName'
                reg={register('lastName')}
                errors={errors}
                icon='fa fa-user'
                className='form-control'
                placeholder={t('form.last-name')}
              />
            </div>

            {/* Email */}
            <div className='input-group mb-3'>
              <Input
                type='email'
                name='email'
                id='email'
                reg={register('email')}
                errors={errors}
                icon='fa fa-envelope'
                className='form-control'
                placeholder={t('form.email')}
              />
            </div>

            {/* Select Roles */}
            <div className='input-group mb-3'>
              <MultiSelect
                id='modal-select-roles'
                name='roles'
                elements={roleOptions}
                reg={register('roles')}
                errors={errors}
                placeholder={t('form.select-user-roles')}
                defaultSelectedElements={updateUser.roles}
              />
            </div>

            {/* Select Tenant */}
            <div className='input-group mb-3'>
              <SelectInput
                id='modal-select-tenant'
                name='tenantId'
                reg={register('tenantId')}
                elements={tenantOptions}
                errors={errors}
                placeholder={t('users:form.select-tenant')}
                defaultSelectedValue={updateUser.tenantId}
              />
            </div>

            {/* Register/Update Button */}
            <div className='row'>
              {/* Submit */}
              <div className='d-flex justify-content-center ml-auto mr-4'>
                <Button onClick={onCloseModalHandler} style={{ textTransform: 'none' }}>
                  {t('form.cancel-button')}
                </Button>
                <Button type='submit' variant='contained' className='ml-2' style={{ textTransform: 'none' }}>
                  {t('edition.update-button')}
                </Button>
              </div>
            </div>
          </form>
        </div>
      </Box>
    </Modal>
  )
}

EditUserModal.propTypes = {}

export default EditUserModal
