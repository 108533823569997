import { memo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Logout from 'shared/components/logout/logout.component'
import { TenantDropdown } from 'shared/components/tenantDropdown/tenantDropdown'

export const Navbar = () => {
  const [t, i18n] = useTranslation('global')
  const [openLanguage, setOpenLanguage] = useState(false)
  // const [language, setLanguage] = useState()

  const handleLanguage = (language: string) => {
    i18n.changeLanguage(language)
    setOpenLanguage(false)
  }

  return (
    <nav className='main-header navbar navbar-expand navbar-white navbar-light'>
      {/* <!-- Left navbar links --> */}
      <ul className='navbar-nav'>
        <li className='nav-item'>
          <a className='nav-link' data-widget='pushmenu' href='#a' role='button'>
            <i className='fas fa-bars'></i>
          </a>
        </li>
      </ul>

      {/* <!-- Right navbar links --> */}
      <ul className='navbar-nav ml-auto'>
        {/* <li className='nav-item dropdown'>
          <TenantDropdown />
        </li> */}
        <li className='nav-item dropdown'>
          <a
            className='nav-link dropdown-toggle'
            onClick={() => setOpenLanguage(!openLanguage)}
            id='navbarDropdown'
            role='button'
            data-bs-toggle='dropdown'
            aria-expanded='false'
          >
            {i18n.language.toUpperCase()}
          </a>
          <ul className={`dropdown-menu ${openLanguage ? 'show' : ''}`} aria-labelledby='navbarDropdown'>
            <li>
              <a className='dropdown-item' onClick={() => handleLanguage('es')}>
                Español
              </a>
            </li>
            <li>
              <a className='dropdown-item' onClick={() => handleLanguage('en')}>
                English
              </a>
            </li>
          </ul>
        </li>
        <li className='nav-item'>
          <Logout />
        </li>
        <li className='nav-item'>
          <a className='nav-link' data-widget='fullscreen' href='#a' role='button'>
            <i className='fas fa-expand-arrows-alt'></i>
          </a>
        </li>
      </ul>
    </nav>
  )
}

export default memo(Navbar)
