import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useEditLetter, useGetLetterById } from 'api/letter/letter.api'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'
import { validationEditLetterSchema } from 'pages/letter/editLetter.validation'
import { EditLetterForm } from 'ts/interfaces/app.interfaces'

import TextEditor from 'shared/components/textEditor/textEditor.component'
import { ContentHeader } from 'shared/components/contentHeader/contentHeader.component'
import Input from 'shared/components/input/input.component'
import CircularProgress from '@mui/material/CircularProgress'
import { pushNotification } from 'shared/helpers/notifications/notifications'

const EditLetter = () => {
  const [t] = useTranslation(['letters', 'global'])

  const navigate = useNavigate()
  const navigateToLetter = () => navigate('/letter')
  const { letterId } = useParams()

  if (!letterId) navigateToLetter()

  const { isLoading, data: selectedLetter } = useGetLetterById(letterId)
  const { mutateAsync, status } = useEditLetter()

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<EditLetterForm>({
    resolver: yupResolver(validationEditLetterSchema),
  })

  // States:
  const [htmlContent, setHtmlContent] = useState('</br></br></br></br></br></br>')

  // Effects
  useEffect(() => {
    if (selectedLetter) {
      reset({
        id: letterId,
        letterName: selectedLetter.name,
        subject: selectedLetter.subject,
      })
      setHtmlContent(selectedLetter.htmlContent)
    }
  }, [selectedLetter, reset])

  // Handlers:
  const onSubmit: SubmitHandler<EditLetterForm> = async data => {
    try {
      // Edit Letter
      await mutateAsync({
        letterId: data?.id,
        name: data?.letterName,
        subject: data?.subject,
        htmlContent,
        isActive: selectedLetter?.isActive,
      })

      if (['success', 'idle'].includes(status)) {
        reset({})
        navigateToLetter()
      }
    } catch (error) {
      pushNotification((error?.response?.data?.error?.code as string) ?? t('letters:messages.error'))
    }
  }

  if (isLoading || status === 'loading') return <CircularProgress />

  return (
    <>
      <ContentHeader
        title={t('letters:letter.edition-header')}
        breadCrumb={{ home: t('global:sidebar.home'), page: t('sidebar.letters') }}
      />

      <section className='content rounded-0'>
        <div className='container-fluid'>
          <div className='callout callout-primary'>
          <h5>{t('letters:letter.edition-description-title')}</h5>
            <p>{t('letters:letter.edition-description-body')}</p>
          </div>
        </div>
      </section>

      <section className='content rounded-0'>
        <div className='container-fluid'>
          <div className='card card-primary card-outline'>

            <div className='card-header border-0'>
              <div className='d-flex justify-content-between'>
                <h3 className='card-title'>
                  <strong>{selectedLetter?.name}</strong>
                </h3>
                <Link to='/letter'>{t('global:cruds.back-button')}</Link>
              </div>
            </div>

            <div className='card-body'>
              <form
                id='edit-letter-form'
                className='row'
                onSubmit={handleSubmit(onSubmit)}
              >
                <div className='col-md-12'>

                  <div className='row'>
                    <div className='col'>
                      <div className='form-group'>
                        <label htmlFor='subject'>{t('letters:letter.table-headers.subject')}</label>
                        <Input
                          type='text'
                          name='subject'
                          reg={register('subject')}
                          errors={errors}
                          placeholder={t('letters:letter.table-headers.subject')}
                        />
                      </div>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col'>
                      <div className='form-group'>
                        <label htmlFor='letterDesign'>{t('letters:letter.table-headers.design-your-letter')}</label>
                        <TextEditor
                          content={htmlContent}
                          setContent={setHtmlContent}
                        />
                      </div>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='offset-md-9 col-md-3'>
                      <div className='form-group'>
                        {/* <div className="col-3"> */}
                        <button type="submit" className="btn btn-success btn-block">
                          {t('global:cruds.save-button')}
                        </button>
                        {/* </div> */}
                      </div>
                    </div>
                  </div>

                </div>
              </form>
            </div>

          </div>
        </div>
      </section>
    </>
  )
}

EditLetter.propTypes = {}

export default EditLetter
