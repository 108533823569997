import * as React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Slide from '@mui/material/Slide'
import { TransitionProps } from '@mui/material/transitions'
import { DialogI } from 'ts/interfaces/app.interfaces'

const Transition = React.forwardRef(
  (
    props: TransitionProps & {
      children: React.ReactElement<any, any>
    },
    ref: React.Ref<unknown>,
  ) => {
    return <Slide direction='up' ref={ref} {...props} />
  },
)

const AlertDialogSlide: React.FC<DialogI> = ({ title, variant = 'text', isOpenDialog, onOk, onCancel }) => (
  <div>
    <Button variant={variant} onClick={onOk}>
      {title}
    </Button>
    <Dialog open={isOpenDialog} TransitionComponent={Transition} keepMounted onClose={onCancel} aria-describedby='alert-dialog-slide-description'>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id='alert-dialog-slide-description'>Aun no se guarda si quieres salir se perderan los cambios</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>Cancel</Button>
        <Button onClick={onOk}>Ok</Button>
      </DialogActions>
    </Dialog>
  </div>
)

export default AlertDialogSlide
