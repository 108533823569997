import React, { useState, useRef } from 'react'
import JoditEditor from 'jodit-react'
import { TextEditorI } from 'ts/interfaces/app.interfaces'

const TextEditor: React.FC<TextEditorI> = ({ content, setContent }) => {
  const editor = useRef(null)
  // const [content, setContent] = useState('')

  const config = {
    readonly: false, // all options from https://xdsoft.net/jodit/doc/
  }
  return (
    <JoditEditor
      ref={editor}
      value={content}
      config={config}
      // tabIndex={5} // tabIndex of textarea
      onBlur={(newContent) => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
      onChange={(newContent) => {
        // console.log('newContent', newContent)
        // setContent(newContent)
      }}
    />
  )
}

TextEditor.propTypes = {}

export default TextEditor
