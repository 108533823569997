export const ApiURL = {
  URL_API: process.env.REACT_APP_URL_API, // 'http://gdlsoft.ddns.net:83/hrxpertApi/api/v1',
}

export const services = {
  PROFILE: 'Profile',
  ACCOUNT: 'Account',
  LETTER: 'Letter',
  PAYMENT: 'Payment',
  THREE_SIXTY: 'ThreeSixty',
  TENANT: 'Tenant',
  GUESTS: 'guests',
}

export const subServices = {
  GET_USERS: 'GetUsers',
  GET_USERS_ASSIGNED_ROLE_FAMILY_LIST: 'GetUsersAssignedToRolFamilyList',
  GET_USER_DATA: 'GetUserData',
  CREATE_GUEST_USER: 'CreateGuessUser',
  GET_GUEST_USER: 'GetGuestUser',
  REGISTER_GUEST_USER_AS_COMMON: 'RegisterGuestUserAsCommon',
  REGISTER: 'Register',
  LOGIN: 'Login',
  LINKEDIN_LOGIN: 'LinkedinLogin',
  FORGOT_PASSWORD: 'ForgotPassword',
  ADD_USER_ROLE: 'AddUserToRol',
  RECOVERY_PASSWORD: 'RecoveryPassword',
  CREATE_USER: 'CreateUser',
  EDIT_USER: 'EditUserAdmin',
  ENABLE_USER: 'EnableUser',
  // Mail Distribution:
  CREATE_MAIL_DISTRIBUTION: 'CreateMailDistribution',
  EDIT_MAIL_DISTRIBUTION: 'EditMailDistribution',
  GET_MAIL_DISTRIBUTION: 'GetMailDistribution',
  GET_MAIL_DISTRIBUTION_LIST: 'GetMailDistributionList',
  ENABLE_MAIL_DISTRIBUTION: 'EnableMailDistribution',
  // Schedule:
  CREATE_SCHEDULE: 'CreateSchedule',
  EDIT_SCHEDULE: 'EditSchedule',
  GET_SCHEDULE: 'GetSchedule',
  GET_SCHEDULE_LIST: 'GetScheduleList',
  ENABLE_SCHEDULE: 'EnableSchedule',
  // Letter:
  CREATE_LETTER: 'CreateLetter',
  EDIT_LETTER: 'EditLetter',
  GET_LETTER: 'GetLetter',
  GET_LETTER_LIST: 'GetLetterList',
  ENABLE_LETTER: 'EnableLetter',
  // Letter Schedule:
  CREATE_LETTER_SCHEDULE: 'CreateLetterSchedule',
  EDIT_LETTER_SCHEDULE: 'EditLetterSchedule',
  GET_LETTER_SCHEDULE: 'GetLetterSchedule',
  GET_LETTER_SCHEDULE_LIST: 'GetLetterScheduleList',
  ENABLE_LETTER_SCHEDULE: 'EnableLetterSchedule',
  DELETE_LETTER_SCHEDULE: 'DeleteLetterSchedule',
  SEND_LETTER: 'SendLetter',
  // Letter Schedule Active Lists:
  GET_LETTER_ACTIVE_LIST: 'GetLetterActiveList',
  GET_SCHEDULE_ACTIVE_LIST: 'GetScheduleActiveList',
  GET_DISTRIBUTION_ACTIVE_LIST: 'GetDistributionActiveList',
  // Payment Subscription List
  GET_SUBSCRIPTION_LIST: 'GetSubscriptionsList',
  // Payment Product List
  GET_PRODUCTS_LIST: 'GetProductsList',
  // Payment Plans List
  GET_PLANS_LIST: 'GetPlansList',
  // Three Sixty Family
  GET_FAMILY: 'GetFamily',
  GET_FAMILY_LIST: 'GetFamilyList',
  CREATE_FAMILY: 'CreateFamily',
  EDIT_FAMILY: 'EditFamily',
  ENABLE_FAMILY: 'EnableFamily',
  GET_ROL_FAMILY_ACTIVE: 'GetRolFamilyActiveList',
  ADD_ROL_FAMILY: 'AddRolFamily',
  GET_ROL_FAMILY_USER: 'GetRolFamilyUser',
  DELETE_ROL_FAMILY: 'DeleteRolFamily',
  ADD_ROL_FAMILY_USER: 'AddRolFamilyToUser',
  RESET_ROL_FAMILY_USER: 'ResetRolFamilyUser',
  GET_FACTOR_FAMILY_ACTIVE: 'GetfactorFamilyActiveList',
  ADD_FACTOR_FAMILY: 'AddFactorFamily',
  DELETE_FACTOR_FAMILY: 'DeleteFactorFamily',
  GET_FAMILY_ACTIVE_LIST: 'GetFamilyActiveList',
  DEACTIVATE_FAMILY_FACTOR: 'DisableFactorFamily',
  // Three Sixty Cluster
  GET_CLUSTER: 'GetCluster',
  GET_CLUSTER_LIST: 'GetClusterList',
  CREATE_CLUSTER: 'CreateCluster',
  EDIT_CLUSTER: 'EditCluster',
  ENABLE_CLUSTER: 'EnableCluster',
  GET_CLUSTER_TYPE_ACTIVE_LIST: 'GetClusterTypeActiveList',
  // Three Sixty Cluster Type
  GET_CLUSTER_TYPE: 'GetClusterType',
  GET_CLUSTER_TYPE_LIST: 'GetClusterTypeList',
  GET_CLUSTER_ACTIVE_LIST: 'GetClusterActiveList',
  CREATE_CLUSTER_TYPE: 'CreateClusterType',
  EDIT_CLUSTER_TYPE: 'EditClusterType',
  ENABLE_CLUSTER_TYPE: 'EnableClusterType',
  // Three Sixty Role
  GET_ROLE: 'GetRol',
  GET_ROLE_LIST: 'GetRolList',
  CREATE_ROLE: 'CreateRol',
  EDIT_ROLE: 'EditRol',
  ENABLE_ROLE: 'EnableRol',
  DEACTIVATED_ROLE_FAMILY: 'DisableRolFamily',
  GET_ROLE_ACTIVE_LIST: 'GetRolActiveList',
  GET_ROLE_SCOPE_ACTIVE_LIST: 'GetRolScopeActiveList',
  // Three Sixty Factor
  GET_FACTOR: 'GetFactor',
  GET_FACTOR_LIST: 'GetFactorList',
  CREATE_FACTOR: 'CreateFactor',
  EDIT_FACTOR: 'EditFactor',
  ENABLE_FACTOR: 'EnableFactor',
  GET_FACTOR_ACTIVE_LIST: 'GetFactorActiveList',
  // Level
  GET_LEVEL: 'GetLevel',
  GET_LEVEL_LIST: 'GetLevelsList',
  GET_LEVEL_ACTIVE_LIST: 'GetLevelActiveList',
  CREATE_LEVEL: 'CreateLevel',
  EDIT_LEVEL: 'EditLevel',
  ENABLE_LEVEL: 'EnableLevel',
  LEVEL_TARGET: 'LevelTarget',
  GET_TARGET_FAMILY: 'getLevelsThreeSixtyQuery',
  GET_TARGETS_ROLE: 'getTargetsByRole',
  CREATE_QUESTION: 'CreateQuestion',
  GET_QUESTIONS_LEVEL: 'getQuestionsByLevel',
  CREATE_ANSWER: 'CreateAnswer',
  EDIT_ANSWER: 'EditAnswer',
  EDIT_QUESTION: 'EditQuestion',
  CREATE_QUESTIONNAIRE: 'CreateQuestionnaire',
  GET_QUESTIONNAIRE_LIST: 'GetQuestionnaireList',
  GET_QUESTIONNAIRE_QUESTIONS: 'GetQuestionnaireQuestions',
  POST_QUESTIONNAIRE_ANSWERS: 'AddUserQuestionnaireAnswers',
  CREATE_USER_QUESTIONNAIRE: 'CreateUserQuestionnaire',
  ENABLE_QUESTION: 'EnableQuestion',
  GET_USER_QUESTIONNAIRE_LIST: 'GetUserQuestionnaireActiveList',
  GET_USER_QUESTIONNAIRE_QUESTION_ANSWERS: 'GetUserQuestionnaireQuestionAnswers',
  CREATE_USER_ASSIGNMENT_QUESTIONNAIRE: 'CreateUserAssignmentQuestionnaire',
  // Season
  CREATE_SEASON: 'CreateSeason',
  GET_SEASON_LIST: 'GetSeasonList',
  GET_SEASON_ACTIVE_LIST: 'GetSeasonActiveList',
  GET_SEASON: 'GetSeason',
  // Evaluator Category
  CREATE_EVALUATOR_CATEGORY: 'CreateEvaluatorCategory',
  GET_EVALUATOR_CATEGORY_LIST: 'GetEvaluatorCategoryList',
  GET_EVALUATOR_CATEGORY: 'GetEvaluatorCategory',
  GET_EVALUATOR_CATEGORY_ACTIVE_LIST: 'GetEvaluatorCategoryActiveList',
  ENABLE_EVALUATOR_CATEGORY: 'EnableEvaluatorCategory',
  EDIT_EVALUATOR_CATEGORY: 'EditEvaluatorCategory',
  // Tenant
  CREATE_TENANT: 'CreateTenant',
  EDIT_TENANT: 'EditTenant',
  ENABLE_TENANT: 'EnableTenant',
  GET_TENANT: 'GetTenant',
  GET_TENANT_LIST: 'GetTenantList',
  GET_TENANT_ACTIVE_LIST: 'GetTenantActiveList',
  SET_AS_DEFAULT_TENANT: 'SetAsDefaultTenant',
}

export const paginationParams = {
  PAGE_NUMBER: 'pageNumber',
  PAGE_SIZE: 'pageSize',
  SEARCH_FILTER: 'searchFilter',
  SORT_BY: 'sortBy',
  SORT_ORDER: 'sortOrder',
}
