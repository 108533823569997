import { Link, useNavigate } from 'react-router-dom'
import { SubmitHandler, useForm } from 'react-hook-form'
import { NewScheduleForm } from 'ts/interfaces/app.interfaces'
import { yupResolver } from '@hookform/resolvers/yup'
import { validationNewScheduleSchema } from 'pages/schedule/newSchedule.validation'
import { validateStatusSuccess } from 'shared/helpers/api'
import { ContentHeader } from 'shared/components/contentHeader/contentHeader.component'
import Input from 'shared/components/input/input.component'
import { useTranslation } from 'react-i18next'
import { useCreateSchedule } from 'api/schedule/schedule.api'
import { pushNotification } from 'shared/helpers/notifications/notifications'

const NewSchedule = () => {
  const [t] = useTranslation(['letters', 'global'])

  const { mutateAsync, status } = useCreateSchedule()
  // Navigation:
  const navigate = useNavigate()

  // Yup
  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm<NewScheduleForm>({
    defaultValues: {
      name: '',
      sends: 0,
      frequency: 0,
    },
    resolver: yupResolver(validationNewScheduleSchema),
  })
  const scheduleName = watch('name', t('letters:schedule.creation-default-name') as string)

  // Handlers:
  const onSubmit: SubmitHandler<NewScheduleForm> = async data => {
    try {
      await mutateAsync(data)

      if (validateStatusSuccess(status)) {
        reset({})
        navigate('/schedule')
      }
    } catch (error) {
      pushNotification((error?.response?.data?.error?.code as string) ?? t('letters:messages.error'))
    }
  }

  return (
    <>
      <ContentHeader
        title={t('letters:schedule.creation-header')}
        breadCrumb={{ home: t('global:sidebar.home'), page: t('global:sidebar.schedulers') }}
      />

      <section className='content rounded-0'>
        <div className='container-fluid'>
          <div className='callout callout-primary'>
            <h5>{t('letters:schedule.creation-description-title')}</h5>
            <p>{t('letters:schedule.creation-description-body')}</p>
          </div>
        </div>
      </section>

      <section className='content rounded-0'>
        <div className='container-fluid'>
          <div className='card card-primary card-outline'>
            <div className='card-header border-0'>
              <div className='d-flex justify-content-between'>
                <h3 className='card-title'>
                  <strong>{scheduleName}</strong>
                </h3>
                <Link to='/schedule'>{t('global:cruds.back-button')}</Link>
              </div>
            </div>
            <div className='card-body'>

              <form
                id='new-schedule-form'
                className='row'
                onSubmit={handleSubmit(onSubmit)}
              >
                <div className='col-md-12'>

                  <div className='row'>
                    <div className='col'>
                      <div className='form-group'>
                        <label htmlFor='letterName'>{t('letters:schedule.table-headers.schedule')}</label>
                        <Input
                          type='text'
                          name='scheduleName'
                          reg={register('name')}
                          errors={errors}
                          placeholder={t('letters:schedule.table-headers.schedule')}
                        />
                      </div>
                    </div>
                  </div>

                  <div className='row'>

                    <div className='col-md-6'>
                      <div className='form-group'>
                        <label htmlFor='times'>{t('letters:schedule.table-headers.times-to-send')}</label>
                        <Input
                          type='number'
                          name='timesToSend'
                          reg={register('sends')}
                          errors={errors}
                          placeholder={t('letters:schedule.table-headers.times-to-send')}
                          min={0}
                        />
                      </div>
                    </div>

                    <div className='col-md-6'>
                      <div className='form-group'>
                        <label htmlFor='frequency'>{t('letters:schedule.table-headers.frequency')}</label>
                        <Input
                          type='number'
                          name='frequencyDays'
                          reg={register('frequency')}
                          errors={errors}
                          placeholder={t('letters:schedule.table-headers.frequency')}
                          min={0}
                        />
                      </div>
                    </div>

                  </div>

                  <div className='row'>
                    <div className='offset-md-9 col-md-3'>
                      <div className='form-group'>
                        {/* <div className="col-3"> */}
                        <button type="submit" className="btn btn-success btn-block">
                          {t('global:cruds.save-button')}
                        </button>
                        {/* </div> */}
                      </div>
                    </div>
                  </div>

                </div>
              </form>

            </div>
          </div>
        </div>
      </section>
    </>
  )
}

NewSchedule.propTypes = {}

export default NewSchedule
