import { useForm, SubmitHandler } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { postForgotPassword } from 'api/auth/auth.api'
import { Link } from 'react-router-dom'
import { ForgotPasswordI } from 'ts/interfaces/app.interfaces'
import LoginWrapper from 'shared/components/loginWrapper/loginWrapper.component'
import Input from 'shared/components/input/input.component'
import AlertComponent from 'shared/components/alert/alert.component'
import { yupResolver } from '@hookform/resolvers/yup'
import { validationForgorPasswordSchema } from 'pages/login/login.validation'

import LoginTypes from 'redux/login/login.types'
import useNotifications from 'shared/hooks/notifications.hook'
import CircularProgress from '@mui/material/CircularProgress'

const ForgotPassword = () => {
  const [t] = useTranslation('login')

  const { isLoading, notification } = useNotifications(LoginTypes.POST_FORGOT_PASSWORD)

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<ForgotPasswordI>({
    resolver: yupResolver(validationForgorPasswordSchema),
  })

  const onSubmit: SubmitHandler<ForgotPasswordI> = async (data) => {
    try {
      await postForgotPassword(data)
      notification.message = t('messages.email-sent')
    } catch (error) {
      notification.message = t('messages.error')
      console.log(error)
    }
    notification.show = true
    reset({ email: '' })
  }

  return (
    <div className='hold-transition login-page'>
      {isLoading && <CircularProgress />}
      {notification.show && <AlertComponent {...notification} />}

      <LoginWrapper title={t('forgot.title')}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className='input-group mb-3'>
            <Input
              type='email'
              name='email'
              reg={register('email')}
              errors={errors}
              icon='fa fa-envelope'
              className='form-control'
              placeholder={t('forgot.email-placeholder')}
            />
          </div>
          <div className='row'>
            <div className='col-12'>
              <button type='submit' className='btn btn-primary btn-block'>
                {t('forgot.request-new-password-button')}
              </button>
            </div>
            {/* <!-- /.col --> */}
          </div>
        </form>

        <p className='mt-3 mb-1'>
          <Link to='/'>{t('forgot.return')}</Link>
        </p>
      </LoginWrapper>
    </div>
  )
}

ForgotPassword.propTypes = {}

export default ForgotPassword
