/* eslint-disable prefer-destructuring */
// import jwtDecode from 'jwt-decode'
import { isExpired, decodeToken } from 'react-jwt'
import { createAction } from 'redux-api-middleware'
import { ApiConfig } from 'ts/interfaces/global.interfaces'
// import { isTokenExpired } from 'shared/helper/function.helper'
// import { updateToken } from 'redux/user/user.actions'

export const removeAllHttpQueue = () => {
  const fnAbort = (abortCtrl) => abortCtrl.abort()
  window.activeHttpRequests.forEach(fnAbort)
}

const clearQueueHttpAbort = (httpAbortCtrl) => {
  const fnReqCtrl = (reqCtrl) => reqCtrl !== httpAbortCtrl
  // window.activeHttpRequests = window.activeHttpRequests.filter(fnReqCtrl)
}

const createNewInstanceHttpAbort = () => {
  const httpAbortCtrl = new AbortController()
  return httpAbortCtrl
}

const getCookie = (name) => {
  const value = `; ${document.cookie}`
  const parts = value.split(`; ${name}=`)
  if (parts.length === 2) {
    return parts.pop().split(';')
      .shift()
  }
}

const fetchApi = (params) => async (dispatch, getState) => {
  const { types, endpoint, method, body, customHeaders } = params
  const { START, SUCCESS, FAILURE } = types
  const cookies = getCookie('token')

  const { login } = getState()
  let token = ''
  if (login.data.token) {
    token = login.data.token
  } else if (cookies !== 'null') {
    token = cookies
  }

  const decodedJWT = token && decodeToken(token)

  if (token && !isExpired(decodedJWT.exp)) {
    // const msalInstance = new PublicClientApplication(msalConfig)
    // const [account] = msalInstance.getAllAccounts()
    // const accessTokenRequest = { scopes: apiConfig.b2cScopes, account }
    // const jsonTokenSilent = await msalInstance.acquireTokenSilent(accessTokenRequest)
    // const { accessToken } = jsonTokenSilent

    // token = accessToken
    // // Update token user store
    // dispatch(updateToken(token))
    console.log('Needs to update the token')
  }
  const httpAbortCtrl = createNewInstanceHttpAbort()
  // window.activeHttpRequests.push(httpAbortCtrl)

  const config: ApiConfig = {
    endpoint,
    method,
    body,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    options: {
      signal: httpAbortCtrl.signal,
    },
    types: [START, SUCCESS, FAILURE],
  }

  if (method === 'POST' || method === 'PUT' || method === 'PATCH') {
    config.body = body
  }
  if (customHeaders) {
    config.headers = customHeaders
  }

  const actionResponse = await dispatch(createAction(config))

  clearQueueHttpAbort(httpAbortCtrl)

  if (actionResponse.error) {
    // console.log('Middleware error', actionResponse.payload)
    throw new Error('Promise flow received action error', actionResponse)
  }

  return actionResponse.payload
}

export default fetchApi
