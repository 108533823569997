import { useState } from 'react'
import { ContentHeader } from 'shared/components/contentHeader/contentHeader.component'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { GridColDef } from '@mui/x-data-grid'
import DataTable from 'shared/components/dataTable/dataTable.component'
import { useGetQuestionnaireList } from 'api/questionnaire/questionnaire.api'
import { PaginationRequest } from 'ts/interfaces/request.interfaces'
import { CircularProgress } from '@mui/material'

const Questionnaire = () => {
  const [t] = useTranslation(['threeSixty', 'global'])

  const [pagination, setPagination] = useState<PaginationRequest>({
    pageNumber: 1,
    pageSize: 100,
  })

  const { data, status } = useGetQuestionnaireList(pagination)

  const onPaginationChangeHandler = (pageSize: number, pageNumber: number, sortBy: string, sortOrder: 'asc' | 'desc') => {
    setPagination({ pageSize, pageNumber, sortBy, sortOrder })
  }

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: t('threeSixty:questionnaire.table-headers.questionnaire'),
      flex: 1
    }, {
      field: 'family',
      headerName: t('threeSixty:questionnaire.table-headers.family-name'),
      flex: 1,
      renderCell: (params) => params.row.family.name
    }, {
      field: 'rol',
      headerName: t('threeSixty:questionnaire.table-headers.role-name'),
      flex: 1,
      renderCell: (params) => params.row.rol.name
    },
  ]

  if (status === 'loading') return <CircularProgress />

  return (
    <>
      <ContentHeader
        title={t('global:sidebar.questionnaires')}
        breadCrumb={{ home: t('global:sidebar.home'), page: t('global:sidebar.questionnaires') }}
      />
      <section className='content'>
        <div className='container-fluid'>
          <div className='card card-primary card-outline'>
            <div className='card-header border-0'>
              <div className='d-flex justify-content-between'>
                <h3 className='card-title'>{t('threeSixty:questionnaire.header')}</h3>
                <Link to='/questionnaire/new'>{t('threeSixty:questionnaire.new-questionnaire-button')}</Link>
              </div>
            </div>
            <div className='card-body'>
              <DataTable
                data={data.data}
                columns={columns}
                getRowId={(row) => row.id}
                pageSize={pagination.pageSize}
                pageNumber={pagination.pageNumber}
                totalRows={data.totalEntitiesCount}
                sortBy={pagination.sortBy}
                sortOrder={pagination.sortOrder}
                serverMode
                onPaginationChangeHandler={onPaginationChangeHandler}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

Questionnaire.propTypes = {}

export default Questionnaire
