import * as Yup from 'yup'
import { t } from 'i18next'

export const validationEditTenantSchema = Yup.object().shape({
  name: Yup.string()
    .required(t('tenant:yup.name.required'))
    .min(1, t('tenant:yup.name.min'))
    .max(100, t('tenant:yup.name.max')),
  description: Yup.string()
    .required(t('tenant:yup.description.required'))
    .min(1, t('tenant:yup.description.min'))
    .max(5000, t('tenant:yup.description.max')),
  expirationDate: Yup.date()
    .required(t('tenant:yup.expiration-date.required')),
  companyName: Yup.string()
    .required(t('tenant:yup.company-name.required'))
    .min(1, t('tenant:yup.company-name.min'))
    .max(100, t('tenant:yup.company-name.max')),
  country: Yup.string()
    .max(100, t('tenant:yup.country.max')),
  registrationCode: Yup.string()
    .max(100, t('tenant:yup.registration-code.max')),
})

export const validationNewTenantSchema = Yup.object().shape({
  name: Yup.string()
    .required(t('tenant:yup.name.required'))
    .min(1, t('tenant:yup.name.min'))
    .max(100, t('tenant:yup.name.max')),
  description: Yup.string()
    .required(t('tenant:yup.description.required'))
    .min(1, t('tenant:yup.description.min'))
    .max(5000, t('tenant:yup.description.max')),
    expirationDate: Yup.date()
    .required(t('tenant:yup.expiration-date.required')),
  companyName: Yup.string()
    .required(t('tenant:yup.company-name.required'))
    .min(1, t('tenant:yup.company-name.min'))
    .max(100, t('tenant:yup.company-name.max')),
  country: Yup.string()
    .max(100, t('tenant:yup.country.max')),
  registrationCode: Yup.string()
    .max(100, t('tenant:yup.registration-code.max')),
})