import i18next from 'i18next'
import globalEs from './assets/translation/es/global.json'
import globalEn from './assets/translation/en/global.json'
import loginEs from './assets/translation/es/login.json'
import loginEn from './assets/translation/en/login.json'
import usersEs from './assets/translation/es/users.json'
import usersEn from './assets/translation/en/users.json'
import lettersEs from './assets/translation/es/letters.json'
import lettersEn from './assets/translation/en/letters.json'
import threeSixtyEs from './assets/translation/es/three-sixty.json'
import threeSixtyEn from './assets/translation/en/three-sixty.json'
import tenantEs from './assets/translation/es/tenant.json'
import tenantEn from './assets/translation/en/tenant.json'

i18next.init({
  interpolation: { escapeValue: false },
  lng: 'es',
  resources: {
    es: {
      global: globalEs,
      login: loginEs,
      users: usersEs,
      letters: lettersEs,
      threeSixty: threeSixtyEs,
      tenant: tenantEs,
    },
    en: {
      global: globalEn,
      login: loginEn,
      users: usersEn,
      letters: lettersEn,
      threeSixty: threeSixtyEn,
      tenant: tenantEn,
    },
  },
})

export default i18next
