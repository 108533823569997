import React from 'react'
import { useCookies } from 'react-cookie'
import { useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'
import LoginWrapper from 'shared/components/loginWrapper/loginWrapper.component'
import Input from 'shared/components/input/input.component'
import AlertComponent from 'shared/components/alert/alert.component'
import { recoveryPasswordForm, recoveryPasswordI, VerifyTokenI } from 'ts/interfaces/app.interfaces'
import { yupResolver } from '@hookform/resolvers/yup'
import CircularProgress from '@mui/material/CircularProgress'

import LoginTypes from 'redux/login/login.types'
import useNotifications from 'shared/hooks/notifications.hook'
import { recoveryPasswordSchema } from './login.validation'
import { useTranslation } from 'react-i18next'
import { postRecoveryPassword } from 'api/auth/auth.api'
import { sleep } from 'shared/helpers'

const RecoveryPassword: React.FC<VerifyTokenI> = ({ userId, token }) => {
  const [t] = useTranslation('login')

  const [cookies, setCookie] = useCookies(['token', 'tenant'])

  const { isLoading, notification } = useNotifications(LoginTypes.POST_RECOVERY_PASSWORD)

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<recoveryPasswordForm>({
    resolver: yupResolver(recoveryPasswordSchema),
  })

  const onSubmit = async (data: recoveryPasswordForm) => {
    const dataToken: recoveryPasswordI = {
      newPassword: data.password,
      id: userId,
      token: decodeURIComponent(token!),
    }
    try {
      const response = await postRecoveryPassword(dataToken)
      if (!response.data.success) {
        notification.message = response.data.message
        notification.type = 'error'
        notification.show = true
      } else {
        notification.message = t('messages.password-changed')
        notification.type = 'success'
        notification.show = true
        setCookie('token', response.data.data.token, { path: '/', })
        setCookie('tenant', response.data.data.tenantId, { path: '/', })
        await sleep(1000)
        window.location.href = '/'
      }
    } catch (error) {
      notification.message = t('messages.error')
      notification.type = 'error'
      notification.show = true
      console.log(error)
    }
  }

  return (
    <div className='hold-transition login-page'>
      {isLoading && <CircularProgress />}
      {notification.show && <AlertComponent {...notification} />}

      <LoginWrapper title={t('recovery.title')}>

        <form onSubmit={handleSubmit(onSubmit)}>
          <div className='input-group mb-3'>
            <Input
              type={'password'}
              name={'password'}
              reg={ register('password')}
              errors={errors}
              icon={'fa fa-lock'}
              placeholder={t('recovery.form.password-placeholder')}
            />
          </div>

          <div className='input-group mb-3'>
            <Input
              type={'password'}
              name={'confirmPassword'}
              reg={ register('confirmPassword')}
              errors={errors}
              icon={'fa fa-lock'}
              placeholder={t('recovery.form.confirm-password-placeholder')}
            />
          </div>
          <div className="row">
            <div className="col-12">
              <button type="submit" className="btn btn-primary btn-block">
                {t('recovery.form.change-password-button')}
              </button>
            </div>
            {/* <!-- /.col --> */}
          </div>

        </form>

        <p className="mt-3 mb-1">
          <Link to={'/'}>
            {t('recovery.return')}
          </Link>
        </p>
      </LoginWrapper>

    </div>
  )
}

RecoveryPassword.propTypes = {}

export default RecoveryPassword
