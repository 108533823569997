import { useTranslation } from 'react-i18next'
import { SubmitHandler, useForm } from 'react-hook-form'
import { Link, useNavigate } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'
import { ContentHeader } from 'shared/components/contentHeader/contentHeader.component'
import Input from 'shared/components/input/input.component'
import { ThreeSixtyNewForm } from 'ts/interfaces/app.interfaces'
import { useCreateFamily } from 'api/family/family.api'
import { validationNewFamilySchema } from './family.validation'
import { pushNotification } from 'shared/helpers/notifications/notifications'

const NewFamily = () => {
  const [t] = useTranslation(['threeSixty', 'global'])
  const { mutateAsync, status } = useCreateFamily()

  const navigate = useNavigate()

  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm<ThreeSixtyNewForm>({
    resolver: yupResolver(validationNewFamilySchema),
  })
  const familyName = watch('name', t('family.creation-default-name') as string)

  const onSubmit: SubmitHandler<ThreeSixtyNewForm> = async (data) => {
    try {
      await mutateAsync(data)

      if (status === 'success' || status === 'idle') {
        pushNotification(t('messages.created'), 'success')
        reset({
          name: '',
          description: '',
        })
        navigate('/family')
      } else {
        pushNotification(t('messages.error'), 'error')
      }
    } catch (error) {
      pushNotification((error?.response?.data?.error?.code as string) ?? t('messages.error'), 'error')
    }
  }

  return (
    <>
      <ContentHeader
        title={t('family.creation-header')}
        breadCrumb={{ home: t('global:sidebar.home'), page: t('global:sidebar.families') }}
      />

      <section className='content rounded-0'>
        <div className='container-fluid'>
          <div className='callout callout-primary'>
            <h5>{t('family.creation-description-title')}</h5>
            <p>{t('family.creation-description-body')}</p>
          </div>
        </div>
      </section>

      <section className='content rounded-0'>
        <div className='container-fluid'>
          <div className='card card-primary card-outline'>
            <div className='card-header border-0'>
              <div className='d-flex justify-content-between'>
                <h3 className='card-title'>
                  <strong>{familyName}</strong>
                </h3>
                <Link to='/family'>{t('global:cruds.back-button')}</Link>
              </div>
            </div>
            <div className='card-body'>
              <form id='new-cluster-form' className='row' onSubmit={handleSubmit(onSubmit)}>
                <div className='col-md-12'>
                  <div className='row'>
                    <div className='col-md-6'>
                      <div className='form-group'>
                        <label htmlFor='name'>{t('family.table-headers.family')}</label>
                        <Input type='text' name='name' reg={register('name')} errors={errors} placeholder={t('family.table-headers.family')} />
                      </div>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-md-12'>
                      <div className='form-group'>
                        <label htmlFor='description'>{t('family.table-headers.description')}</label>
                        {/* <Input
                          type='text'
                          name='description'
                          reg={register('description')}
                          errors={errors}
                          placeholder={t('family.table-headers.description')}
                        /> */}
                        <textarea
                          placeholder={t('family.table-headers.description')}
                          id='decription'
                          name='description'
                          className='form-control rounded-0 '
                          {...register('description')}
                        />
                      </div>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='offset-md-9 col-md-3'>
                      <div className='form-group'>
                        {/* <div className="col-3"> */}
                        <button type='submit' className='btn btn-success btn-block'>
                          {t('global:cruds.save-button')}
                        </button>
                        {/* </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

NewFamily.propTypes = {}

export default NewFamily
