import * as Yup from 'yup'
import { t } from 'i18next'

export const validationNewFactorSchema = Yup.object().shape({
  name: Yup.string()
    .required(t('threeSixty:yup.factor.name.required'))
    .min(1, t('threeSixty:yup.factor.name.min'))
    .max(100, t('threeSixty:yup.factor.name.max')),
  description: Yup.string()
    .required(t('threeSixty:yup.factor.description.required'))
    .min(1, t('threeSixty:yup.factor.description.min'))
    .max(500, t('threeSixty:yup.factor.description.max')),
  clusterId: Yup.string().required(t('threeSixty:yup.factor.cluster.required')),
})
