import * as Yup from 'yup'
import { t } from 'i18next'

export const validationEditUserAdminSchema = Yup.object().shape({
  firstName: Yup.string()
    .required(t('users:yup.first-name.required'))
    .min(1, t('users:yup.first-name.min'))
    .max(100, t('users:yup.first-name.max')),
  lastName: Yup.string()
    .required(t('users:yup.last-name.required'))
    .min(1, t('users:yup.last-name.min'))
    .max(100, t('users:yup.last-name.max')),
  email: Yup.string()
    .required(t('users:yup.email.required'))
    .email(t('users:yup.email.email')),
  roles: Yup.array().nullable()
    .required(t('users:yup.roles.required'))
    .min(1, t('users:yup.roles.min')),
  tenantId: Yup.string().required(t('users:yup.tenant.required')),
})

export default {}
