import { QuestionSettingsForm } from 'redux/interfaces'
import { SchemaOf, string, object, boolean, mixed } from 'yup'

export const questionSchema: SchemaOf<QuestionSettingsForm> = object({
  id: string().optional(),
  description: string().optional(),
  designQuestion: string().optional(),
  questionType: string().optional(),
  answerType: string().required('Answer Type is required'),
  isRequired: boolean().optional(),
  questionConfig: string().optional(),
  answerConfig: string().optional(),
})

export default {}
