// import { useGetQuestionByLevel } from 'api/question/question.api'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ContentHeader } from 'shared/components/contentHeader/contentHeader.component'
// import Accordion from '@mui/material/Accordion'
// import AccordionSummary from '@mui/material/AccordionSummary'
// import AccordionDetails from '@mui/material/AccordionDetails'
// import Typography from '@mui/material/Typography'
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Link, useNavigate } from 'react-router-dom'
import DeleteIcon from '@mui/icons-material/Delete'
import { useGetFamilyActiveList } from 'api/family/family.api'
import { useGetRolesActiveList } from 'api/role/role.api'
import { usePostQuestionnaire } from 'api/questionnaire/questionnaire.api'
import { Questionnaire } from 'api/questionnaire/questionnaire.interface'
import { pushNotification } from 'shared/helpers/notifications/notifications'

const initData: Questionnaire = {
  name: '',
  familyId: '',
  rolId: '',
  additionalQuestions: [],
}

export const NewQuestionnaire = () => {
  const [t] = useTranslation(['threeSixty', 'global'])

  const navigate = useNavigate()
  // const [showQuestions, setShowQuestions] = useState(false)
  const [extraQuestions, setExtraQuestions] = useState([])
  // const { data, status } = useGetQuestionByLevel('7931d367-1235-4a44-2ffb-08da886e5dd6')
  const { data: families, status: familiesStatus } = useGetFamilyActiveList()
  const { data: roles, status: rolesStatus } = useGetRolesActiveList()
  const { mutateAsync, status: statusQuestionnaire } = usePostQuestionnaire()
  const [questionnaireData, setQuestionnaireData] = useState<Questionnaire>(initData)

  // const showQuestionsByFactor = () => {
  //   setShowQuestions(true)
  // }

  // const showQuestionsByFactorDiv = () => {
  //   return (
  //     <div className='row'>
  //       <div className='col'>
  //         {data?.map((question) => (
  //           <Accordion key={question.question}>
  //             <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls='panel1a-content' id='panel1a-header'>
  //               <Typography>{question.question}</Typography>
  //             </AccordionSummary>
  //             <AccordionDetails>
  //               <div className='row'>
  //                 <div className='col-md-1'>
  //                   <button type='button' className='btn btn-outline-primary'>
  //                     <Link to={`/level/questions/edit`}>Edit</Link>
  //                   </button>
  //                 </div>
  //                 <div className='col-md-1'>
  //                   <button type='button' className='btn btn-outline-danger'>
  //                     Delete
  //                   </button>
  //                 </div>
  //               </div>
  //             </AccordionDetails>
  //           </Accordion>
  //         ))}
  //       </div>
  //     </div>
  //   )
  // }

  const addExtraQuestions = () => {
    setExtraQuestions((prevState) => [...prevState, ''])
  }

  const removeExtraQuestion = (position) => {
    setExtraQuestions((prevState) => [...prevState.slice(0, position), ...prevState.slice(position + 1)])
  }

  const addQuestionText = (e, position) => {
    setExtraQuestions((prevState) => {
      prevState[position] = e.target.value

      return prevState
    })
  }

  const updateValue = (key, e) => {
    setQuestionnaireData((prevState) => ({ ...prevState, [key]: e.target.value }))
  }

  const showExtraQuestions = () => {
    return extraQuestions.map((question, position) => {
      return (
        <div className='form-group' key={`extra-question-${position}`}>
          <div className='row'>
            <div className='col-md-10'>
              <input
                type='text'
                name='questionnaireName'
                className='form-control'
                defaultValue={question}
                onChange={(e) => addQuestionText(e, position)}
                placeholder={t('threeSixty:questionnaire.aditional-questions.write-your-question')}
              />
            </div>
            <div className='col-md-2'>
              <button type='button' className='btn btn-danger' onClick={() => removeExtraQuestion(position)}>
                <DeleteIcon />
              </button>
            </div>
          </div>
        </div>
      )
    })
  }

  const saveNewQuiz = async () => {
    try {
      await mutateAsync({ ...questionnaireData, additionalQuestions: extraQuestions })

      if (statusQuestionnaire === 'success' || statusQuestionnaire === 'idle') {
        setQuestionnaireData(initData)
        navigate('/questionnaires')
      }
    } catch (error) {
      pushNotification('Error al guardar cuestionario', 'error')
    }
  }

  return (
    <>
      <ContentHeader
        title={t('threeSixty:questionnaire.creation-header')}
        breadCrumb={{ home: t('global:sidebar.home'), page: t('global:sidebar.questionnaires') }}
      />

      <section className='content rounded-0'>
        <div className='container-fluid'>
          <div className='card card-primary card-outline'>
            <div className='card-header border-0'>
              <div className='d-flex justify-content-between'>
                <h3 className='card-title'>
                  <strong>{t('threeSixty:questionnaire.creation-description-title')}</strong>
                </h3>
                <Link to='/questionnaire'>{t('global:cruds.back-button')}</Link>
              </div>
            </div>

            <div className='card-body'>
              <div id='new-quiz-form' className='row'>
                <div className='col-md-12'>
                  <div className='row'>
                    <div className='col-md-6'>
                      <div className='form-group'>
                        <label htmlFor='familyId'>{t('threeSixty:questionnaire.fields.select.family')}</label>
                        <select className='form-control rounded-0' name='actions' id='familyId' onChange={(e) => updateValue('familyId', e)}>
                          <option value=''>{t('threeSixty:questionnaire.fields.select.no-selected')}</option>
                          {families?.map((family) => (
                            <option key={`family-${family.id}`} value={family.id}>
                              {family.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className='col-md-6'>
                      <div className='form-group'>
                        <label htmlFor='familyId'>{t('threeSixty:questionnaire.fields.select.role')}</label>
                        <select className='form-control rounded-0' name='actions' id='rolId' onChange={(e) => updateValue('rolId', e)}>
                          <option value=''>{t('threeSixty:questionnaire.fields.select.no-selected')}</option>
                          {roles?.map((role) => (
                            <option key={`family-${role.id}`} value={role.id}>
                              {role.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-md-12'>
                      <div className='form-group'>
                        <label htmlFor='letterName'>{t('threeSixty:questionnaire.fields.questionnaire-name')}</label>
                        <input
                          type='text'
                          name='questionnaireName'
                          onChange={(e) => updateValue('name', e)}
                          className='form-control'
                          // reg={methods.register('letterName')}
                          // errors={methods?.formState?.errors}
                          placeholder={t('threeSixty:questionnaire.fields.questionnaire-name-placeholder')}
                        />
                      </div>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-md-12'>
                      <div className='form-group'>
                        <label className='d-block'>{t('threeSixty:questionnaire.aditional-questions.title')}</label>
                        <button type='button' className='btn btn-outline-primary' onClick={addExtraQuestions}>
                          {t('threeSixty:questionnaire.aditional-questions.add-button')}
                        </button>
                      </div>
                    </div>
                  </div>

                  {extraQuestions.length > 0 && (
                    <div className='row'>
                      <div className='col-md-12'>
                        <div className='form-group'>
                          {showExtraQuestions()}
                        </div>
                      </div>
                    </div>
                  )}

                  <div className='row'>
                    <div className='offset-md-9 col-md-3'>
                      <div className='form-group'>
                        <button type='button' className='btn btn-success btn-block' onClick={() => saveNewQuiz()}>
                          {t('global:cruds.save-button')}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <div className='card-body'>
              <div className='row'>
                <div className='col'>
                  <h3 className='card-title'>
                    <strong>Preguntas relacionadas al factor:</strong>
                  </h3>
                </div>
              </div>
              {showQuestions && showQuestionsByFactorDiv()}
            </div> */}
          </div>
        </div>
      </section>
    </>
  )
}
