import fetchApi from 'redux/api-middleware'
import UserTypes from './user.types'
import { generateMiddlewareActionTypes } from 'shared/helpers/redux'
import { ApiURL, services, subServices } from 'services/api.service'
import { RegisterForm } from 'ts/interfaces/app.interfaces'
import { loginRegister } from 'redux/login/login.actions'
import { createAsyncThunk } from '@reduxjs/toolkit'

const { URL_API } = ApiURL
const { PROFILE, ACCOUNT } = services
const { GET_USER_DATA, REGISTER } = subServices

export const setTest = () => {
  return {
    type: UserTypes.TEST,
    payload: {
      name: 'Julio',
      age: 31,
    },
  }
}

export const getUserProfile = createAsyncThunk<any[]>('getUserProfile', async (dispatch: any) => {
  // const endpoint = `${URL_API}/${PROFILE}/${GET_USER_DATA}`
  // const types = generateMiddlewareActionTypes(UserTypes.GET_PROFILE)
  // try {
  //   const config = {
  //     types,
  //     endpoint,
  //     method: 'GET',
  //   }

  //   const response = await dispatch(fetchApi(config))
  //   return response
  // } catch (error) {
  //   console.error('error:', error)
  // }

  const config = {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization:
      // eslint-disable-next-line max-len
        'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJkamp1bGlvIiwianRpIjoiNGQ2MTRlOWMtNmEzYS00YzkyLWE5NjktYWFjMTkzMTcwYjM1IiwiaWF0IjoxNjYwOTQ3NzA0LCJlbWFpbCI6Imp1bGlvLm9ycXVpekBnbWFpbC5jb20iLCJuYmYiOjE2NjA5NDc3MDQsImV4cCI6MTY2MTAzNDEwNH0.kR2PdRAoriFRHoJ0UT6VaG59xXJcChxpmVNLjC9E3EE',
    },
    options: {
      // signal: httpAbortCtrl.signal,
    },
  }

  const response = await fetch(`${URL_API}/${PROFILE}/${GET_USER_DATA}`, config)
  const json = await response.json()
  return json
})

export const fetchTodos = createAsyncThunk<any[]>('todos/fetchTodos', async () => {
  const response = await fetch('https://jsonplaceholder.typicode.com/todos')
  const json = await response.json()
  return json
})

export const postUser = (body: RegisterForm) => async (dispatch: any) => {
  const types = generateMiddlewareActionTypes(UserTypes.POST_USER)
  const endpoint = `${URL_API}/${ACCOUNT}/${REGISTER}`
  try {
    const config = {
      types,
      endpoint,
      method: 'POST',
      body: JSON.stringify(body),
    }
    const response = await dispatch(fetchApi(config))
    dispatch(loginRegister(response))
    return response
  } catch (error) {
    console.error('error:', error)
  }
}

export default setTest
