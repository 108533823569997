import * as Yup from 'yup'
import { t } from 'i18next'

const validationCreateSeason = Yup.object().shape({
  name: Yup.string().required('Nombre de Programacion es obligatorio'),
  startDate: Yup.string().required('Inicio de Programacion es obligatorio'),
  endDate: Yup.string().required('Fin de Programacion es obligatorio'),
  invitationLetterId: Yup.string().required('Carta es obligatoria'),
  scheduleId: Yup.string().required('Programacion es obligatorio'),
})

export { validationCreateSeason }
