import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { SubmitHandler, useForm } from 'react-hook-form'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'

import { ContentHeader } from 'shared/components/contentHeader/contentHeader.component'
import CircularProgress from '@mui/material/CircularProgress'
import Input from 'shared/components/input/input.component'
import { ThreeSixtyNewForm } from 'ts/interfaces/app.interfaces'
import { useGetClusterTypeById, useUpdateClusterType } from 'api/clusterType/clusterType.api'
import { validationEditClusterTypeSchema } from './clusterType.validation'

const EditClusterType = () => {
  // Translation
  const [t] = useTranslation(['threeSixty', 'global'])

  const navigate = useNavigate()
  const navigateToClusterType = () => navigate('/clusterType')
  const { clusterTypeId } = useParams()

  if (!clusterTypeId) navigateToClusterType()

  const { isLoading, data } = useGetClusterTypeById(clusterTypeId)
  const { mutateAsync, status } = useUpdateClusterType()

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<ThreeSixtyNewForm>({
    defaultValues: useMemo(() => {
      return {
        description: data?.description,
      }
    }, [data]),
    resolver: yupResolver(validationEditClusterTypeSchema),
  })

  useEffect(() => {
    if (data) {
      reset({
        id: data?.id,
        name: data?.name,
        description: data?.description,
      })
    }
  }, [data, reset])

  const onSubmit: SubmitHandler<ThreeSixtyNewForm> = async (clusterTypeData) => {
    await mutateAsync(clusterTypeData)

    if (status === 'success' || status === 'idle') {
      reset({})
      navigate('/clusterType')
    }
  }

  if (isLoading || status === 'loading') return <CircularProgress />

  return (
    <>
      <ContentHeader
        title={t('cluster-type.edition-header')}
        breadCrumb={{ home: t('global:sidebar.home'), page: t('global:sidebar.clusterTypes') }}
      />

      <section className='content rounded-0'>
        <div className='container-fluid'>
          <div className='callout callout-primary'>
            <h5>{t('cluster-type.edition-description-title')}</h5>
            <p>{t('cluster-type.edition-description-body')}</p>
          </div>
        </div>
      </section>

      <section className='content rounded-0'>
        <div className='container-fluid'>
          <div className='card card-primary card-outline'>
            <div className='card-header border-0'>
              <div className='d-flex justify-content-between'>
                <h3 className='card-title'>
                  <strong>{data?.name}</strong>
                </h3>
                <Link to='/clusterType'>{t('global:cruds.back-button')}</Link>
              </div>
            </div>
            <div className='card-body'>
              <form id='new-cluster-form' className='row' onSubmit={handleSubmit(onSubmit)}>
                <div className='col-md-12'>
                  <div className='row'>
                    <div className='col-md-6'>
                      <div className='form-group'>
                        <label htmlFor='name'>{t('cluster-type.table-headers.cluster-type')}</label>
                        <Input
                          type='text'
                          name='name'
                          reg={register('name')}
                          errors={errors}
                          placeholder={t('cluster-type.table-headers.cluster-type')}
                        />
                      </div>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-md-12'>
                      <div className='form-group'>
                        <label htmlFor='description'>{t('cluster-type.table-headers.description')}</label>
                        {/* <Input
                          type='text'
                          name='description'
                          reg={register('description')}
                          errors={errors}
                          placeholder={t('cluster-type.table-headers.description')}
                        /> */}
                        <textarea
                          placeholder={t('cluster-type.table-headers.description')}
                          id='decription'
                          name='description'
                          className='form-control rounded-0 '
                          {...register('description')}
                        />
                      </div>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='offset-md-9 col-md-3'>
                      <div className='form-group'>
                        {/* <div className="col-3"> */}
                        <button type='submit' className='btn btn-success btn-block'>
                          {t('global:cruds.save-button')}
                        </button>
                        {/* </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default EditClusterType
