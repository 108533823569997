import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { SubmitHandler, useForm } from 'react-hook-form'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'

import { ContentHeader } from 'shared/components/contentHeader/contentHeader.component'
import CircularProgress from '@mui/material/CircularProgress'
import Input from 'shared/components/input/input.component'
import { ThreeSixtyEditForm } from 'ts/interfaces/app.interfaces'
import { useGetFamilyById, useUpdateFamily } from 'api/family/family.api'
import { pushNotification } from 'shared/helpers/notifications/notifications'
import { validationNewFamilySchema } from './family.validation'

const EditFamily = () => {
  const [t] = useTranslation(['threeSixty', 'global'])

  const navigate = useNavigate()
  const navigateToFamily = () => navigate('/family')
  const { familyId } = useParams()

  if (!familyId) navigateToFamily()

  const { isLoading, data } = useGetFamilyById(familyId)
  const { mutateAsync, status } = useUpdateFamily()

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<ThreeSixtyEditForm>({
    defaultValues: useMemo(() => {
      return {
        description: data?.description,
      }
    }, [data]),
    resolver: yupResolver(validationNewFamilySchema),
  })

  useEffect(() => {
    if (data) {
      reset({
        id: data?.id,
        name: data?.name,
        description: data?.description,
      })
    }
  }, [data, reset])

  const onSubmit: SubmitHandler<ThreeSixtyEditForm> = async (familyData) => {
    try {
      await mutateAsync(familyData)

      if (['success', 'idle'].includes(status)) {
        pushNotification(t('messages.created'), 'success')
        reset({})
        navigateToFamily()
      } else {
        pushNotification(t('messages.error'), 'error')
      }
    } catch (error) {
      pushNotification((error?.response?.data?.error?.code as string) ?? t('messages.error'), 'error')
    }
  }

  if (isLoading || status === 'loading') return <CircularProgress />

  return (
    <>
      <ContentHeader title={t('family.edition-header')} breadCrumb={{ home: t('global:sidebar.home'), page: t('global:sidebar.families') }} />

      <section className='content rounded-0'>
        <div className='container-fluid'>
          <div className='callout callout-primary'>
            <h5>{t('family.edition-description-title')}</h5>
            <p>{t('family.edition-description-body')}</p>
          </div>
        </div>
      </section>

      <section className='content rounded-0'>
        <div className='container-fluid'>
          <div className='card card-primary card-outline'>
            <div className='card-header border-0'>
              <div className='d-flex justify-content-between'>
                <h3 className='card-title'>
                  <strong>{data?.name}</strong>
                </h3>
                <Link to='/family'>{t('global:cruds.back-button')}</Link>
              </div>
            </div>
            <div className='card-body'>
              <form id='new-cluster-form' className='row' onSubmit={handleSubmit(onSubmit)}>
                <div className='col-md-12'>
                  <div className='row'>
                    <div className='col-md-12'>
                      <div className='form-group'>
                        <label htmlFor='name'>{t('family.table-headers.family')}</label>
                        <Input type='text' name='name' reg={register('name')} errors={errors} placeholder={t('family.table-headers.family')} />
                      </div>
                    </div>
                    <div className='col-md-12'>
                      <div className='form-group'>
                        <label htmlFor='description'>{t('family.table-headers.description')}</label>
                        {/* <Input
                          type='text'
                          name='description'
                          reg={register('description')}
                          errors={errors}
                          placeholder={t('family.table-headers.description')}
                        /> */}
                        <textarea
                          placeholder={t('family.table-headers.description')}
                          id='decription'
                          name='description'
                          className='form-control rounded-0 '
                          {...register('description')}
                        />
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='offset-md-9 col-md-3'>
                      <div className='form-group'>
                        {/* <div className="col-3"> */}
                        <button type='submit' className='btn btn-success btn-block'>
                          {t('global:cruds.save-button')}
                        </button>
                        {/* </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default EditFamily
