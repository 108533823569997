import * as Yup from 'yup'
import { t } from 'i18next'

export const validationNewClusterSchema = Yup.object().shape({
  name: Yup.string()
    .required(t('threeSixty:yup.cluster.name.required'))
    .min(1, t('threeSixty:yup.cluster.name.min'))
    .max(100, t('threeSixty:yup.cluster.name.max')),
  clusterTypeId: Yup.string()
    .required(t('threeSixty:yup.cluster.cluster-type.required')),
  description: Yup.string()
    .required(t('threeSixty:yup.cluster.description.required'))
    .min(1, t('threeSixty:yup.cluster.description.min'))
    .max(500, t('threeSixty:yup.cluster.description.max')),
})

export const validationEditClusterSchema = Yup.object().shape({
  clusterTypeId: Yup.string()
    .required(t('threeSixty:yup.cluster.cluster-type.required')),
  description: Yup.string()
    .required(t('threeSixty:yup.cluster.description.required'))
    .min(1, t('threeSixty:yup.cluster.description.min'))
    .max(500, t('threeSixty:yup.cluster.description.max')),
})