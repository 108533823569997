import { useEffect, useState } from 'react'
import { ContentHeader } from 'shared/components/contentHeader/contentHeader.component'
import CircularProgress from '@mui/material/CircularProgress'
import Input from 'shared/components/input/input.component'
import { SubmitHandler, useForm } from 'react-hook-form'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useGetFactorActiveList, useGetFactorList } from 'api/factor/factor.api'
import { useCreateLevel, useGetLevelById, useUpdateLevel } from 'api/level/level.api'
import { Level } from 'api/level/level.interface'
import { pushNotification } from 'shared/helpers/notifications/notifications'
import { yupResolver } from '@hookform/resolvers/yup'
import { validationLevelSchema } from 'pages/threeSixty/level/level.validation'
import { useTranslation } from 'react-i18next'
import { PaginationRequest } from 'ts/interfaces/request.interfaces'

const successStatus = ['success', 'idle']

const NewLevel = () => {
  const [t] = useTranslation(['threeSixty', 'global'])

  const [pagination, setPagination] = useState<PaginationRequest>({
    pageNumber: 1,
    pageSize: 100,
  })

  const { mutateAsync: createLevelAsync, status: creationStatus } = useCreateLevel()
  const { mutateAsync: editLevelAsync, status: editionStatus } = useUpdateLevel()
  const { data: factorData, status: factorDataStatus } = useGetFactorActiveList()
  const [factors, setFactors] = useState([])

  console.log('factorData0', factorData)
  console.log('factors', factors)

  const navigate = useNavigate()

  const { levelId } = useParams()

  let levelData: Level = null
  let loading = false

  const getFactors = () => {
    let factorExtracted = []

    factorData.forEach((factorsData) => {
      factorsData.factors.forEach((factor) => factorExtracted.push({ id: factor.id, name: factor.name }))
    })

    return factorExtracted
  }

  useEffect(() => {
    if (factorData) {
      setFactors(getFactors())
    }
  }, [factorData])

  if (levelId) {
    const { isLoading, data: levelGetData } = useGetLevelById(levelId)

    levelData = levelGetData

    loading = isLoading
  }

  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm<Level>({
    resolver: yupResolver(validationLevelSchema),
  })

  const levelNumber = watch('levelNumber', 0)

  const onSubmit: SubmitHandler<Level> = async (levelDataSubmit) => {
    try {
      levelId ? await editLevelAsync(levelDataSubmit) : await createLevelAsync(levelDataSubmit)

      if (creationStatus === 'success' || creationStatus === 'idle' || editionStatus === 'success' || editionStatus === 'idle') {
        pushNotification(levelId ? t('messages.modified') : t('messages.created'), 'success')
        reset({
          name: '',
          factorId: '',
          levelNumber: null,
          description: '',
        })
        navigate('/level')
      } else {
        pushNotification(`${t('messages.error')} to ${levelId ? 'update' : 'create'}: ${levelDataSubmit.name}!`, 'error')
      }
    } catch (error) {
      pushNotification((error?.response?.data?.error?.code as string) ?? t('messages.error'), 'error')
    }
  }

  useEffect(() => {
    if (levelData) {
      reset({
        id: levelData?.id,
        name: levelData?.name,
        factorId: levelData?.factorId,
        levelNumber: levelData?.levelNumber,
        description: levelData?.description,
      })
    }
  }, [levelData, reset])

  if (factorDataStatus === 'loading' || loading) return <CircularProgress />

  const actionI18N = levelId ? 'edition' : 'creation'

  return (
    <>
      <ContentHeader title={t(`level.${actionI18N}-header`)} breadCrumb={{ home: t('global:sidebar.home'), page: t('global:sidebar.levels') }} />

      <section className='content rounded-0'>
        <div className='container-fluid'>
          <div className='callout callout-primary'>
            <h5>{t(`level.${actionI18N}-description-title`)}</h5>
            <p>{t(`level.${actionI18N}-description-body`)}</p>
          </div>
        </div>
      </section>

      <section className='content rounded-0'>
        <div className='container-fluid'>
          <div className='card card-primary card-outline'>
            <div className='card-header border-0'>
              <div className='d-flex justify-content-between'>
                <h3 className='card-title'>
                  <strong>{levelId ? levelData?.name : levelNumber}</strong>
                </h3>
                <Link to='/level'>{t('global:cruds.back-button')}</Link>
              </div>
            </div>

            <div className='card-body'>
              <form id='new-level-form' className='row' onSubmit={handleSubmit(onSubmit)}>
                <div className='col-md-12'>
                  <div className='row'>
                    <div className='col-md-6'>
                      <div className='form-group'>
                        <label htmlFor='factorId'>{t('level.table-headers.factor')}</label>
                        <select className='form-control rounded-0' {...register('factorId')}>
                          <option value='0'>{t('level.table-headers.factor-placeholder')}</option>
                          {factors.map((factor) => (
                            <option key={factor.id} value={factor.id}>
                              {factor.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className='col-md-6'>
                      <div className='form-group'>
                        <label htmlFor='levelNumber'>{t('level.table-headers.level')}</label>
                        <Input
                          type='number'
                          name='levelNumber'
                          reg={register('levelNumber')}
                          errors={errors}
                          placeholder={t('level.table-headers.level-placeholder')}
                        />
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-md-12'>
                      <div className='form-group'>
                        <label htmlFor='description'>{t('level.table-headers.description')}</label>
                        <textarea
                          placeholder={t('level.table-headers.description')}
                          id='decription'
                          name='description'
                          className='form-control rounded-0 '
                          {...register('description')}
                        >
                          {levelData?.description}
                        </textarea>
                      </div>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='offset-md-9 col-md-3'>
                      <div className='form-group'>
                        {/* <div className="col-3"> */}
                        <button type='submit' className='btn btn-success btn-block'>
                          {t('global:cruds.save-button')}
                        </button>
                        {/* </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default NewLevel
