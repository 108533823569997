import { ContentHeader } from 'shared/components/contentHeader/contentHeader.component'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import { getUserQuestionnaireQuestionsAnswers, usePostQuestionnaireAnswers } from 'api/questionnaire/questionnaire.api'
import { CircularProgress, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import type { UserQuestionnaireAnswers } from 'api/questionnaire/questionnaire.interface'

import { pushNotification } from 'shared/helpers/notifications/notifications'
import { useNavigate, useParams } from 'react-router-dom'
import { getRoleActiveList, useGetRolesActiveList, useUpdateUserFamilyRole } from 'api/role/role.api'
import useCheckToken from 'shared/hooks/useCheckToken.hook'
import { GetUserQuestionnaireQuestionsAnswers } from 'api/questionnaire/userQuestionnaireAnswer'
import './styles.scss'
import { getFamilyList } from 'api/family/family.api'
import { getScopeList } from 'api/scope/scope.api'
import AlertDialogSlide from 'shared/components/dialog/dialog.component'

const objUserRole: GetUserQuestionnaireQuestionsAnswers = {
  // familyId: 'aaae2f5b-0e09-4dbf-51a0-08dabd2f5529',
  // familyName: 'Produccion',
  factors: [
    {
      clusterId: '5051af99-aa63-4971-aa5d-08daa1a93fe9',
      clusterName: '',
      factorId: 'useRoleAssignation',
      factorName: 'Asignación de Role y Familia',
      levelQuestions: [
        {
          id: 'familyId',
          levelId: '708600a0-6b89-42b8-c333-08dabd3042a1',
          question: '¿Cuál es su función general en la organización?',
          questionAnswers: [],
        },
        {
          id: 'scopeId',
          levelId: '708600a0-6b89-42b8-c333-08dabd3042f5',
          question: '¿Cuál es el alcance de su organización?',
          questionAnswers: [],
        },
        {
          id: 'rolId',
          levelId: '708600a0-6b89-42b8-c333-08dabd3042f4',
          question: 'Cuáles su responsabilidad en la organización?',
          questionAnswers: [],
        },
      ],
    },
  ],
}

const generateResponseObject = (obj: GetUserQuestionnaireQuestionsAnswers) => {
  let factorIds
  let totalQuestions = 0
  let answers = 0

  Object.keys(obj).map((factor) => {
    if (factor === 'factors') {
      factorIds = obj[factor].reduce((acc, curr) => {
        const questionsObj = curr.levelQuestions.reduce((acc2, curr2) => {
          totalQuestions += 1
          answers += curr2?.selectedAnswer?.id ? 1 : 0

          return { ...acc2, [curr2.id]: curr2?.selectedAnswer?.id ?? null }
        }, {})

        return { ...acc, [curr.factorId]: questionsObj }
      }, {})
    }

    return factor
  })

  return { factorIds, totalQuestions, answers }
}

interface TotalValues {
  totalQuestions: number
  totalAnswers: number
  percentage: number
}

interface AnswerObject {
  [key: string]: {
    [key: string]: string
  }
}

interface AdditionalQuestions {
  [questionnaireAdditionalId: string]: string
}

export const QuestionView = ({ userRole = false }) => {
  const navigate = useNavigate()
  const { mutateAsync, status: statusPostQuestionnaire } = usePostQuestionnaireAnswers()
  const { mutateAsync: mutateAsyncRole, status: statusRole } = useUpdateUserFamilyRole()
  const { data: roleData, isLoading: roleIsLoading } = useGetRolesActiveList()
  const { id, userName } = useCheckToken()
  const { questionnaireId, assignationId } = useParams()

  const [isOpenDialog, setOpenDialog] = useState(false)
  const [questions, setQuestions] = useState<GetUserQuestionnaireQuestionsAnswers>()
  const [answerObj, setAnswerObj] = useState<AnswerObject>()
  const [totalValues, setTotalValues] = useState<TotalValues>()
  const [additionalQuestions, setAdditionalQuestions] = useState<AdditionalQuestions>()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const createRoleQuestionnaire = () => {
      return new Promise(async (resolve, reject) => {
        try {
          const families = await getFamilyList()
          const scopes = await getScopeList()

          const familyQuestions = families.data.map((family) => ({
            id: family.id,
            answer: family.name,
            levelQuestionId: family.id,
          }))

          const rolesQuestions = roleData
            .filter((role) => role.scope.name === 'Local')
            .map((role) => ({
              id: role.id,
              answer: role.name,
              levelQuestionId: role.id,
            }))

          const scopesQuestions = scopes.data.map((scope) => ({
            id: scope.id,
            answer: scope.name,
            levelQuestionId: scope.id,
          }))

          objUserRole.factors[0].levelQuestions[0].questionAnswers = familyQuestions
          objUserRole.factors[0].levelQuestions[1].questionAnswers = scopesQuestions
          objUserRole.factors[0].levelQuestions[2].questionAnswers = rolesQuestions

          resolve(objUserRole)
        } catch (error) {
          reject(error)
        }
      })
    }

    if (userRole && roleData) {
      createRoleQuestionnaire()
        .then((resp: GetUserQuestionnaireQuestionsAnswers) => {
          setQuestions(resp)
        })
        .catch((err) => console.log('err', err))
    }
  }, [roleData, userRole])

  useEffect(() => {
    if (assignationId) {
      setLoading(true)
      getUserQuestionnaireQuestionsAnswers(assignationId)
        .then((response) => {
          setQuestions(response.data)
        })
        .catch((error) => pushNotification(error))
        .finally(() => {
          setLoading(false)
        })
    }
  }, [assignationId])

  const getAnswers = (answerObjPrev: AnswerObject) => {
    let totalAnswers = 0

    if (!answerObj) return totalAnswers

    totalAnswers = Object.values(answerObjPrev).reduce((totalA, answers) => {
      return (
        totalA +
        Object.values(answers).reduce((total, item) => {
          if (item) {
            return (total += 1)
          }

          return (total += 0)
        }, 0)
      )
    }, 0)

    return totalAnswers
  }

  useEffect(() => {
    if (!!questions && !answerObj) {
      const { totalQuestions, factorIds, answers } = generateResponseObject(questions)

      setAnswerObj(factorIds)
      setTotalValues({
        totalQuestions,
        totalAnswers: getAnswers(answerObj),
        percentage: Math.round((answers / totalQuestions) * 100),
      })

      const additionalQuestionsObj = questions?.additionalQuestions?.reduce((acc, curr) => ({ ...acc, [curr.id]: curr.answer }), {})

      setAdditionalQuestions(additionalQuestionsObj)
    }
  }, [answerObj, questions])

  const handlerOnChange = (e, answerId, questionId, factorId) => {
    setAnswerObj((prevState) => {
      const updatedAwnsers = { ...prevState, [factorId]: { ...prevState[factorId], [questionId]: answerId } }

      const totalAnswers = getAnswers(updatedAwnsers)

      setTotalValues((prevState) => ({ ...prevState, totalAnswers, percentage: Math.round((totalAnswers / prevState.totalQuestions) * 100) }))

      return updatedAwnsers
    })
  }

  const handleDialog = () => {
    console.log('here')
    setOpenDialog(true)
  }

  const handleOk = () => {
    setOpenDialog(false)
    navigate('/dashboard')
  }

  const handleCancel = () => {
    setOpenDialog(false)
  }

  const transformToSaveData = (answers: AnswerObject) => {
    const response: UserQuestionnaireAnswers = {
      userQuestionnaireId: assignationId,
      percentage: 0,
      totalQuestions: 0,
      totalAnswers: 0,
      questionAnswersByFactor: [],
      additionalQuestionAnswers: Object.keys(additionalQuestions).map((additional) => ({
        questionnaireAdditionalId: additional,
        answer: additionalQuestions[additional],
      })),
    }

    response.questionAnswersByFactor = Object.keys(answers).map((factor) => ({
      factorId: factor,
      answers: Object.keys(answers[factor]).map((question) => ({
        levelQuestionId: question,
        levelQuestionAnswerId: answers[factor][question],
      })),
    }))

    return response
  }

  const saveQuestionnaire = async () => {
    try {
      if (questionnaireId) {
        await mutateAsync(transformToSaveData(answerObj))

        if (statusPostQuestionnaire === 'success' || statusPostQuestionnaire === 'idle') {
          // pushNotification('Cuestionario guardado correctamente', 'success')

          if (totalValues.percentage < 100) {
            pushNotification('El progreso ha sido guardado', 'success')
          }

          if (totalValues.percentage >= 100) {
            pushNotification('El cuestionario ha sido completado', 'success')
            navigate('/dashboard')
          }
        } else {
          pushNotification('Error al guardar', 'error')
        }
      }

      if (userRole) {
        const { useRoleAssignation } = answerObj
        const roleSelectedId = useRoleAssignation.rolId
        const getRoleSelected = roleData.find((role) => role.id === roleSelectedId)
        const getCorrectRole = roleData.find((role) => role.name === getRoleSelected.name && role.scope.id === useRoleAssignation.scopeId)

        await mutateAsyncRole({
          userId: id,
          familyId: useRoleAssignation.familyId, // Tecnologia de la info
          rolId: getCorrectRole.id, // Gerente Director Tactico
        })

        if (statusRole === 'success' || statusRole === 'idle') {
          pushNotification('Role y Familia asignados correctamente', 'success')
          navigate('/users/role')
        }
      }
    } catch (error) {
      pushNotification(error, 'error')
    }
  }

  const handleAdditionalAnswer = (e, additionaQuestionId) => {
    setAdditionalQuestions((prevState) => ({
      ...prevState,
      [additionaQuestionId]: e.target.value,
    }))
  }

  const renderAdditionalQuestions = (additionalQuestions) => (
    <>
      <div className='row' key='additinalQuestion'>
        <div className='col-md-12 factor-title'>Preguntas Adicionales</div>
      </div>
      {additionalQuestions.map((additionalQuestion) => (
        <div className='row form-group-lg question-section' key={additionalQuestion.id}>
          <div className='col-md-12 question-title'>
            <label htmlFor=''>{additionalQuestion.description}</label>
          </div>
          <div className='col-md-12 mt-4'>
            <FormControl style={{ width: '100%' }} key={`answer-${additionalQuestion.id}`}>
              <input
                className='form-control rounded-0'
                name={additionalQuestion.id}
                type='text'
                placeholder='Escriba su respuesta...'
                onChange={(e) => handleAdditionalAnswer(e, additionalQuestion.id)}
                defaultValue={additionalQuestion.answer}
              />
            </FormControl>
            <hr />
          </div>
        </div>
      ))}
    </>
  )

  if (loading || roleIsLoading) return <CircularProgress />

  return (
    <>
      {!userRole && <ContentHeader title={`Bienvenido: ${userName}`} />}
      <AlertDialogSlide title='Seguro que quieres salir de cuestionarios?' isOpenDialog={isOpenDialog} onOk={handleOk} onCancel={handleCancel} />
      <section className='content'>
        <div className='container-fluid'>
          <div className='card card-primary card-outline'>
            <div className='card-header border-0'>
              <div className='d-flex justify-content-between'>
                <img style={{ width: '13rem', margin: 'auto' }} src={require('assets/img/logoHRXpert.png')} alt='HRXpert Logo' />
              </div>
            </div>

            <div className='card-body'>
              {/* <div className='row form-group-lg'> */}
              {questions &&
                questions.factors.map((factor) => (
                  <>
                    <div className='row' key={factor.factorId}>
                      <div className='col-md-12 factor-title'>{factor.factorName}</div>
                    </div>
                    {factor.levelQuestions.map((question) => (
                      <div className='row form-group-lg question-section' key={`${factor.factorId}-${question.id}`}>
                        <div className='col-md-12 question-title'>
                          <label htmlFor=''>{question.question}</label>
                        </div>
                        <div className='col-md-12' key={`question-${factor.factorId}-${question.id}`}>
                          <FormControl style={{ width: '100%' }} key={`${factor.factorId}--${question.id}`}>
                            <RadioGroup row aria-labelledby='demo-form-control-label-placement' name='position' defaultValue='top'>
                              {question.questionAnswers.map((answer) => (
                                <FormControlLabel
                                  key={`answer-${question.id}-${answer.id}`}
                                  value='bottom'
                                  className='col'
                                  control={
                                    <Radio
                                      value={answer.answer}
                                      name='radio-buttons'
                                      inputProps={{ 'aria-label': 'A' }}
                                      onChange={(e) => handlerOnChange(e, answer.id, question.id, factor.factorId)}
                                      checked={answerObj?.[factor.factorId]?.[question.id] === answer.id}
                                    />
                                  }
                                  label={<Typography className='answer-text'>{answer.answer}</Typography>}
                                  labelPlacement='bottom'
                                />
                              ))}
                            </RadioGroup>
                          </FormControl>
                          <hr />
                        </div>
                      </div>
                    ))}
                  </>
                ))}
              {questions && questions.additionalQuestions && renderAdditionalQuestions(questions.additionalQuestions)}
            </div>
          </div>
        </div>
      </section>
      <section className='content button-area'>
        <div className='container-fluid'>
          <div className='card card-outline content-button-area'>
            <div className='card-body'>
              <div className='row'>
                <div className='col-md-4 text-center'>
                  <span className='text-success text-bold advance-text mr-3'>Avance Total: {totalValues?.percentage ?? 0}%</span>
                  <span className='not-saved-text'>No guardado aun (click guardar o Ctrl + g)</span>
                </div>
                <div className='col-md-4 text-center'>
                  {totalValues?.totalQuestions >= 15 && (
                    <>
                      <button type='button' className='btn btn-outline-info mr-3'>
                        Atras
                      </button>
                      <button type='button' className='btn btn-outline-info'>
                        Siguiente
                      </button>
                    </>
                  )}
                </div>
                <div className='col-md-3 text-right'>
                  <button type='button' className='btn btn-outline-danger mr-3' onClick={handleDialog}>
                    Cancel
                  </button>
                  <button type='button' className='btn btn-outline-success' onClick={saveQuestionnaire}>
                    {totalValues?.percentage >= 100 ? 'Enviar' : 'Guardar'}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

/* TODO
revisar si se puede hacer uno hacia atras - si se puede
a nivel tenant decidir el numero de preguntas/ imagen de vista

revisar si por cada pagina se hara un request para no mostrar todas las preguntas en el request / si enviar los datos por pagina y tener el porcentaje
en la familia se podria agregar una imagen de fondo listo
los usuarios podran responderlo aun sin tener acceso / pre registro con el envio
con el link sera capaz de entrar el cuestionario, el link sera por tiempo definido o hasta que este contestado

Evaluador -> solo responde cuestionarios
Participante -> acceso y reponde cuestionarios
Cosultor/Empresa -> puede crear cuestionarios 360

puden usar hasta 8 radio button en linea

Nuevas duas:

revisar Contestar a que tenga su categoria
- jefe
- subordinado
- colaborados
- cliente

fases de questionario
- iniciado
- porcentaje avanzado
- terminado

Agregar Cluster en cuestionario

Agregar la parametrizacion a nivel questionario pero con un deafult de nivel empresa en caso de no

Ver la forma de agregar visualizcion como Matriz - opcional

Datos demograficos - questionarios independientes


Cuestionarios Independientes - Tipos de respuestas diferentes /

*/
