import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDeleteLetterSchedule, useEnableLetterSchedule, useGetLetterScheduleList, useSendLetter } from 'api/letterSchedule/letterSchedule.api'
import { pushNotification } from 'shared/helpers/notifications/notifications'
import { PaginationRequest } from 'ts/interfaces/request.interfaces'
import { Link } from 'react-router-dom'

import DataTable from 'shared/components/dataTable/dataTable.component'
import { GridColDef } from '@mui/x-data-grid'
import { Switch, Button, CircularProgress } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import PlayIcon from '@mui/icons-material/PlayArrowRounded'
import { ContentHeader } from 'shared/components/contentHeader/contentHeader.component'

const successStatus = ['success', 'idle']

const LetterComposition = () => {
  const [t] = useTranslation(['letters', 'global'])

  // Pagination
  const [pagination, setPagination] = useState<PaginationRequest>({
    pageNumber: 1,
    pageSize: 10,
  })

  // States
  const { data: compositions, status, refetch } = useGetLetterScheduleList(pagination)
  const { mutateAsync: enableAsync, status: enableStatus } = useEnableLetterSchedule()
  const { mutateAsync: sendLetterAsync, status: sendLetterStatus } = useSendLetter()

  // Handlers:
  const onLetterScheduleRun = async (letterScheduleId: string) => {
    try {
      await sendLetterAsync({ letterScheduleId })

      if (successStatus.includes(sendLetterStatus)) {
        pushNotification(t('letters:messages.sent-letter'), 'success')
        await refetch()
      }
    } catch (error) {
      pushNotification(
        (error?.response?.data?.error?.code as string) ?? t('letters:messages.error'),
        'error',
      )
    }
  }

  const onLetterScheduleDelete = async (letterScheduleId: string) => {
    try {
      await useDeleteLetterSchedule(letterScheduleId)

      pushNotification(t('letters:messages.deleted'), 'success')
      await refetch()
    } catch (error) {
      pushNotification(
        (error?.response?.data?.error?.code as string) ?? t('letters:messages.error'),
        'error',
      )
    }
  }

  const onLetterScheduleActiveChangeHandler = async (value: boolean, letterScheduleId: string) => {
    try {
      await enableAsync({ id: letterScheduleId, isActive: value })

      if (successStatus.includes(enableStatus)) {
        pushNotification(value ? t('letters:messages.activated') : t('letters:messages.deactivated'), 'success')
        await refetch()
      }
    } catch (error) {
      pushNotification((error?.response?.data?.error?.code as string) ?? t('letters:messages.error'))
    }
  }

  const onPaginationChangeHandler = (pageSize: number, pageNumber: number, sortBy: string, sortOrder: 'asc' | 'desc') => {
    setPagination({ pageSize, pageNumber, sortBy, sortOrder })
  }

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: t('letters:letter-composition.table-headers.letter-composition'),
      type: 'string',
      width: 300,
    }, {
      field: 'letter',
      headerName: t('letters:letter-composition.table-headers.letter-name'),
      type: 'string',
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
      valueFormatter: valueFormatter => valueFormatter.value.name,
    }, {
      field: 'schedule',
      headerName: t('letters:letter-composition.table-headers.schedule-name'),
      type: 'string',
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
      valueFormatter: valueFormatter => valueFormatter.value.name,
    }, {
      field: 'mailDistribution',
      headerName: t('letters:letter-composition.table-headers.mail-distribution-name'),
      type: 'string',
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
      valueFormatter: valueFormatter => valueFormatter.value.name,
    }, {
      field: 'runComposition',
      headerName: t('global:datatable.run-col'),
      width: 100,
      sortable: false,
      disableColumnMenu: true,
      headerAlign: 'center',
      align: 'center',
      renderCell: params => {
        const letterScheduleId: string = params.row.id
        return (
          <Button
            onClick={() => onLetterScheduleRun(letterScheduleId)}
          >
            <PlayIcon />
          </Button>
        )
      },
    }, {
      field: 'editDistribution',
      headerName: t('global:datatable.edit-col'),
      width: 100,
      sortable: false,
      disableColumnMenu: true,
      headerAlign: 'center',
      align: 'center',
      renderCell: params => {
        const letterScheduleId: string = params.row.id
        return (
          <Link to={`/letterComposition/edit/${letterScheduleId}`}>
            <EditIcon />
          </Link>
        )
      },
    }, {
      field: 'deleteComposition',
      headerName: t('global:datatable.remove-col'),
      width: 100,
      sortable: false,
      disableColumnMenu: true,
      headerAlign: 'center',
      align: 'center',
      renderCell: params => {
        const letterScheduleId: string = params.row.id
        return (
          <Button
            onClick={() => onLetterScheduleDelete(letterScheduleId)}
          >
            <DeleteIcon />
          </Button>
        )
      },
    }, {
      field: 'activeComposition',
      headerName: t('global:datatable.active-col'),
      width: 100,
      sortable: false,
      disableColumnMenu: true,
      headerAlign: 'center',
      align: 'center',
      renderCell: params => {
        const letterScheduleId: string = params.row.id
        const isActive: boolean = params.row.isActive
        return (
          <Switch
            checked={isActive}
            onChange={(_, value) => onLetterScheduleActiveChangeHandler(value, letterScheduleId)}
          />
        )
      },
    },
  ]

  if (status === 'loading') return <CircularProgress />

  return (
    <>
      <ContentHeader
        title={t('global:sidebar.letter-compositions')}
        breadCrumb={{ home: t('global:sidebar.home'), page: t('global:sidebar.letter-compositions') }}
      />

      <section className='content'>
        <div className='container-fluid'>
          <div className='card card-primary card-outline'>

            <div className='card-header border-0'>
              <div className='d-flex justify-content-between'>
                <h3 className='card-title'>{t('letters:letter-composition.header')}</h3>
                <Link to='/LetterComposition/new'>
                  {t('letters:letter-composition.new-composition-button')}
                </Link>
              </div>
            </div>

            <div className='card-body'>
              <DataTable
                data={compositions?.data ?? []}
                columns={columns}
                getRowId={row => row.id}
                pageSize={pagination.pageSize}
                pageNumber={pagination.pageNumber}
                totalRows={compositions.totalEntitiesCount}
                sortBy={pagination.sortBy}
                sortOrder={pagination.sortOrder}
                serverMode
                onPaginationChangeHandler={onPaginationChangeHandler}
              />
            </div>

          </div>
        </div>
      </section>
    </>
  )
}

LetterComposition.propTypes = {}

export default LetterComposition
