import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { ContentHeader } from 'shared/components/contentHeader/contentHeader.component'
import { Link } from 'react-router-dom'
import { GridColDef } from '@mui/x-data-grid'
import EditIcon from '@mui/icons-material/Edit'
import QuestionMarkIcon from '@mui/icons-material/QuestionMark'
import Switch from '@mui/material/Switch'
import CircularProgress from '@mui/material/CircularProgress'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import Typography from '@mui/material/Typography'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import DataTable from 'shared/components/dataTable/dataTable.component'
import { useGetLevelsList, usePatchLevel } from 'api/level/level.api'
import { PaginationRequest } from 'ts/interfaces/request.interfaces'
import { pushNotification } from 'shared/helpers/notifications/notifications'

const Level = () => {
  const [t] = useTranslation(['threeSixty', 'global'])

  const [pagination, setPagination] = useState<PaginationRequest>({
    pageNumber: 1,
    pageSize: 100,
  })

  const { data, status, refetch } = useGetLevelsList(pagination)
  const { mutateAsync, status: statusActive } = usePatchLevel()

  const onLevelActiveChangeHandler = async (value: boolean, id: string) => {
    try {
      await mutateAsync({ id, isActive: value })

      if (['success', 'idle'].includes(statusActive)) {
        pushNotification(value ? t('message.activated') : t('message.deactivated'), 'success')
        await refetch()
      }
    } catch (error) {
      pushNotification((error?.response?.data?.error?.code as string) ?? t('messages.error'))
    }
  }

  const onPaginationChangeHandler = (pageSize: number, pageNumber: number, sortBy: string, sortOrder: 'asc' | 'desc') => {
    setPagination({ pageSize, pageNumber, sortBy, sortOrder })
  }

  useEffect(() => {
    if (data) {
      console.log(data)
    }
  }, [data])

  const columns: GridColDef[] = [
    {
      field: 'levelNumber',
      headerName: t('level.table-headers.level-number'),
      width: 200,
    },
    {
      field: 'description',
      headerName: t('level.table-headers.description'),
      flex: 1,
    },
    {
      field: 'questions',
      headerName: 'Questions',
      width: 100,
      sortable: false,
      disableColumnMenu: true,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        const LevelId: string = params.row.levelId

        return (
          <Link to={`/level/questions/${LevelId}`}>
            <QuestionMarkIcon />
          </Link>
        )
      },
    },
    {
      field: 'editLevel',
      headerName: t('global:datatable.edit-col'),
      width: 100,
      sortable: false,
      disableColumnMenu: true,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        const LevelId: string = params.row.levelId

        return (
          <Link to={`/level/edit/${LevelId}`}>
            <EditIcon />
          </Link>
        )
      },
    },
    // {
    //   field: 'activeLevel',
    //   headerName: t('global:datatable.active-col'),
    //   width: 100,
    //   sortable: false,
    //   disableColumnMenu: true,
    //   headerAlign: 'center',
    //   align: 'center',
    //   renderCell: (params) => {
    //     const levelId: string = params.row.levelId
    //     const isActive: boolean = params.row.isActive

    //     return <Switch checked={isActive} onChange={(e, value) => onLevelActiveChangeHandler(value, levelId)} />
    //   },
    // },
  ]

  if (status === 'loading') return <CircularProgress />

  return (
    <>
      <ContentHeader title={t('global:sidebar.levels')} breadCrumb={{ home: t('global:sidebar.home'), page: t('global:sidebar.levels') }} />

      <section className='content'>
        <div className='container-fluid'>
          <div className='card card-primary card-outline'>
            <div className='card-header border-0'>
              <div className='d-flex justify-content-between'>
                <h3 className='card-title'>{t('level.header')}</h3>
                <Link to='/level/new'>{t('level.new-level-button')}</Link>
              </div>
            </div>

            <div className='card-body'>
              {data?.data?.map((factor) => (
                <Accordion key={factor.factorId}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls='panel1a-content' id='panel1a-header'>
                    <Typography>{factor.factor}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <DataTable
                      // data={factor?.levels ?? []}
                      data={
                        factor?.levels?.map((level) => {
                          return {
                            id: `${factor.factorId}-${level.level}`,
                            levelNumber: level.level,
                            description: level.description,
                            levelId: level.levelId,
                          }
                        }) ?? []
                      }
                      columns={columns}
                      getRowId={(row) => row.id}
                      pageSize={pagination.pageSize}
                      pageNumber={pagination.pageNumber}
                      totalRows={factor?.levels?.length}
                      sortBy={pagination.sortBy}
                      sortOrder={pagination.sortOrder}
                      serverMode
                      onPaginationChangeHandler={onPaginationChangeHandler}
                    />
                  </AccordionDetails>
                </Accordion>
              ))}
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Level
