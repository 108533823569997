import React from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { store } from 'redux/store'
// import reportWebVitals from './reportWebVitals'
import { CookiesProvider } from 'react-cookie'
import { I18nextProvider } from 'react-i18next'
import i18next from './i18n'
import App from './App'
import './index.scss'

const root = createRoot(document.getElementById('root') as Element)

root.render(
  // <React.StrictMode>
  <CookiesProvider>
    <Provider store={store}>
      <I18nextProvider i18n={i18next}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </I18nextProvider>
    </Provider>
  </CookiesProvider>,
  // </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals()
