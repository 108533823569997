import { ApiURL, services, subServices } from 'services/api.service'
import { PaginationRequest, ResponsePagination } from 'ts/interfaces/request.interfaces'
import { setPaginationToEndpoint } from 'shared/helpers/pagination'
import { useApply, useFetch, usePost, useUpdate } from 'api/reactQuery'
import { ThreeSixtyEditForm, ThreeSixtyNewForm } from 'ts/interfaces/app.interfaces'
import { FamilyPatchResponse, FamilyResponse, FamilyRole, FamilyRolePost, FamilyFactorPost, FamilyFactor } from './family.interface'
import { api } from '../api'

const { URL_API } = ApiURL
const { THREE_SIXTY } = services
const {
  GET_FAMILY,
  GET_FAMILY_LIST,
  CREATE_FAMILY,
  EDIT_FAMILY,
  ENABLE_FAMILY,
  GET_ROL_FAMILY_ACTIVE,
  ADD_ROL_FAMILY,
  GET_FACTOR_FAMILY_ACTIVE,
  ADD_FACTOR_FAMILY,
  GET_FAMILY_ACTIVE_LIST,
  DEACTIVATE_FAMILY_FACTOR,
  DEACTIVATED_ROLE_FAMILY,
} = subServices

export const useGetFamilyList = (pagination?: PaginationRequest) =>
  useFetch<ResponsePagination>(setPaginationToEndpoint(`${URL_API}/${THREE_SIXTY}/${GET_FAMILY_LIST}`, pagination), pagination)

export const useGetFamilyActiveList = () =>
  useFetch<FamilyResponse[]>(setPaginationToEndpoint(`${URL_API}/${THREE_SIXTY}/${GET_FAMILY_ACTIVE_LIST}`), { id: 1 })

export const useGetFamilyById = (id: string) => useFetch<FamilyResponse>(`${URL_API}/${THREE_SIXTY}/${GET_FAMILY}?id=${id}`, { id })

export const useCreateFamily = () => usePost<ThreeSixtyNewForm, ThreeSixtyNewForm>(`${URL_API}/${THREE_SIXTY}/${CREATE_FAMILY}`, { id: 1 })

export const useUpdateFamily = () => useUpdate<ThreeSixtyEditForm, ThreeSixtyEditForm>(`${URL_API}/${THREE_SIXTY}/${EDIT_FAMILY}`, { id: 1 })

export const usePatchFamily = () => useApply<FamilyPatchResponse, FamilyPatchResponse>(`${URL_API}/${THREE_SIXTY}/${ENABLE_FAMILY}`, { id: 1 })

export const useGetFamilyRoles = (familyId?: string) => useFetch<FamilyRole[]>(`${URL_API}/${THREE_SIXTY}/${GET_ROL_FAMILY_ACTIVE}`, { familyId })

export const usePostFamilyRoles = () => usePost<FamilyRolePost, FamilyRolePost>(`${URL_API}/${THREE_SIXTY}/${ADD_ROL_FAMILY}`, { id: 1 })

export const useGetFamilyFactores = (familyId?: string) =>
  useFetch<FamilyFactor[]>(`${URL_API}/${THREE_SIXTY}/${GET_FACTOR_FAMILY_ACTIVE}`, { familyId })

export const usePostFamilyFactores = () => usePost<FamilyFactorPost, FamilyFactorPost>(`${URL_API}/${THREE_SIXTY}/${ADD_FACTOR_FAMILY}`, { id: 1 })

export const usePostDeactivateFamilyFactores = () =>
  useApply<FamilyFactorPost, FamilyFactorPost>(`${URL_API}/${THREE_SIXTY}/${DEACTIVATE_FAMILY_FACTOR}`, { id: 1 })

export const usePostDeactivateFamilyRoles = () =>
  useApply<FamilyRolePost, FamilyRolePost>(`${URL_API}/${THREE_SIXTY}/${DEACTIVATED_ROLE_FAMILY}`, { id: 1 })

export const getFamilyList = () => api.get<FamilyResponse[]>(`${URL_API}/${THREE_SIXTY}/${GET_FAMILY_ACTIVE_LIST}`, { id: 1 })
