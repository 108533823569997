import { ApiURL, services, subServices } from 'services/api.service'
import { PaginationRequest, ResponsePagination } from 'ts/interfaces/request.interfaces'
import { setPaginationToEndpoint } from 'shared/helpers/pagination'
import { useApply, useFetch, usePost, useUpdate } from 'api/reactQuery'
import { ThreeSixtyEditForm, ThreeSixtyNewForm } from 'ts/interfaces/app.interfaces'
import { Cluster, ClusterResponse } from 'api/cluster/cluster.interface'

const { URL_API } = ApiURL
const { THREE_SIXTY } = services
const { GET_CLUSTER_LIST, GET_CLUSTER, CREATE_CLUSTER, EDIT_CLUSTER, ENABLE_CLUSTER } = subServices

export const useCreateCluster = () => usePost<ClusterResponse, ClusterResponse>(`${URL_API}/${THREE_SIXTY}/${CREATE_CLUSTER}`, { id: 1 })

export const useGetClusterById = (id: string) => useFetch<Cluster>(`${URL_API}/${THREE_SIXTY}/${GET_CLUSTER}?id=${id}`, { id })

export const useGetClusterList = (pagination?: PaginationRequest) =>
  useFetch<ResponsePagination>(setPaginationToEndpoint(`${URL_API}/${THREE_SIXTY}/${GET_CLUSTER_LIST}`, pagination), pagination)

export const useUpdateCluster = () => useUpdate<ThreeSixtyEditForm, ThreeSixtyEditForm>(`${URL_API}/${THREE_SIXTY}/${EDIT_CLUSTER}`, { id: 1 })

export const usePatchCluster = () => useApply<Cluster, Cluster>(`${URL_API}/${THREE_SIXTY}/${ENABLE_CLUSTER}`, { id: 1 })